import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    *{
        margin:0px;
        padding:0px;
        box-sizing: border-box;
    }

    body{
        background-color:#FCF7F2;
    }

    a{
        text-decoration: none;
        font-family: 'Montserrat', sans-serif;
        color:#000;
    }

    a {
        text-decoration: none;
        outline: none !important;
        &:hover, &:focus, &:active {
          color: #C3CBC2;
        }
      }
    

    img {
        object-fit: cover;
    }

    .styled_input > input, .styled_input > textarea {
        padding:1rem 1.1rem;
        appearance:none;
        background: #F6F6F6;
        border-radius: 0.5rem !important;
        font-size:0.9rem;
        font-family:'Didact Gothic';
        font-size: 0.95rem;
        color:#000;

        &::placeholder{
            color:#000;
            opacity:0.4;
        }
        
    }

    .styled_input input, .styled_input  textarea{
        background-color: #F6F6F6;

        &:hover{
            background-color: #F1F1F1;
        }
        &:focus{
            border: 2px solid #C3CBC2;
            background-colors: #fff;
            box-shadow:none;
        }
        &:disabled{
            background: #F1F1F1;
        }
    }


    .styled_input > select {
        padding:1rem 1.1rem;
        appearance:none;
        border: none;
        background: #F6F6F6;
        border-radius: 0.5rem !important;
        font-size:0.95rem;
        font-family:'Didact Gothic';
        color:#000;

        svg {
            fill: red;
            stroke: red;
        }

        &::placeholder{
            color:#000;
            opacity:0.4;
        }
    }

    .styled_input label{
        font-family:'Didact Gothic';
        font-size:0.9rem;
        color:#000;
    }

    .completeProfileModal .modal-content{
        border-radius: 1rem;
    }
    .completeProfileModal .btn-close{
        background:url(../assets/Images/x.svg);
        position:relative;
        top:-100px;
    }
    .completeProfileModal .close_btn{
        position:absolute;
        right: 0;
        top: -2rem;
    }

    .completeProfileModal .modal-body {
        padding: 3rem;
    }

    .completeProfileModal h3{
        font-family:"Brandon Grotesque";
        font-weight:300;
        font-size:1.5rem;
        text-transform:uppercase;
        color:#000000;
    }
    .completeProfileModal p{
        font-family:"Montserrat";
        font-weight:300;
        font-size:0.8rem;
        line-height:1.4rem;
        color:#404040;
        margin-top:0.8rem
    }

    .completeProfileModal .backbtn button:nth-child(1) {
        background-color:#F3F3F3;
    }
    
    .completeProfileModal.blurred{
        backdrop-filter:blur(4px)
    }

    .sessionDetail{
        h4{
            font-family: 'Brandon Grotesque';
            font-style: normal;
            font-weight: 300;
            font-size: 1.5rem;
            line-height: 2.1rem;
            text-transform: uppercase;
            color: #000000;
        }
        
        h5{
            font-family: 'Brandon Grotesque';
            font-style: normal;
            font-weight: 300;
            font-size: 1.2rem;
            line-height: 1.8rem;
            text-transform: uppercase;
            color: #000000;
            margin-top:1.5rem;
        }

        .modal-body>div >div {
            display:flex;
            grid-gap:1.5rem;
            
            >div{
                display:flex;
                flex-direction: column;

                span{
                    font-family: 'Didact Gothic';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 0.9rem;
                    line-height: 1.1rem;
                    color: #404040;
                    opacity: 0.5;
                    margin-bottom:0.5rem;
                }

                p{
                    margin:0;
                    font-family: 'Didact Gothic';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.3rem;
                    color: #000000;
                    opacity: 0.7;
                    margin-bottom:0.5rem;
                }
            }
        }
    }

    .endSession{
        h4{
            font-family: 'Brandon Grotesque';
            font-style: normal;
            font-weight: 300;
            font-size: 1.4rem;
            line-height: 2.1rem;
            text-transform: uppercase;
            color: #000000;
        }
        p{
            font-family: 'Didact Gothic';
            font-style: normal;
            font-weight: 400;
            font-size: 0.9rem;
            line-height: 1.2rem;
            color: #000000;
            opacity: 0.7;
            margin-top:0;
        }
        p:first-child{
            margin-top:0.5rem;
        }

        .expense-note {
            font-family: 'Didact Gothic';
            font-style: normal;
            font-weight: 400;
            font-size: 0.8rem;
            line-height: 1.1rem;
            color: #000000;
            opacity: 0.7;

        }

        #dropdown-basic-button{
            width:100%;
            background-color:transparent;
            color:#000;
            font-family: 'Didact Gothic';
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.2rem;
            padding:0.8rem 1rem;
            border:1px solid transparent;
            background: #F6F6F6;

            &:active, &:hover{
                color:#000;
                border: 1px solid #CBC8C9;
            }

            &::after{
                content:none;
            }
        }
    }

    .btn-primary {
        background: #C3CBC2;
        border-radius: 99px;
        padding: 1.125rem 2.5rem;
        color: black;
        border: 1px solid #C3CBC2;
        font-family: 'Didact Gothic';
        font-size: 1rem;
        border: 0px !important;

        &:hover, &:active, &:focus, &:disabled {
            background-color: #D5DBD4 !important;
            color: ${({ theme }) => theme.colors.black};
        }
    }

    .btn-alt {
        background: #F3F3F3;
        border-radius: 99px;
        padding: 1.125rem 2.5rem;
        color: black;
        border: 1px solid #F3F3F3;
        font-family: 'Didact Gothic';
        font-size: 1rem;

        &:hover, &:active, &:focus, &:disabled {
            background: #5C5C5C;
            border: 1px solid #5C5C5C;
            color: white;
        }
    }

    .btn-white {
        background: white;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
        border-radius: 99px;
        padding: 1.125rem 2.5rem;
        color: black;
        border: 1px solid #F3F3F3;
        font-family: 'Didact Gothic';
        font-size: 1rem;

        &:hover, &:active, &:focus, &:disabled {
            background: #F3F3F3;
            border: 1px solid #5C5C5C;
            color: white;
        }
    }

    .modal {
        backdrop-filter: blur(18px);
        /* background: rgba(0, 0, 0, 0.02); */
    }

    .fullscreen-modal {
        overflow: hidden;
        .modal-content {
            border-radius: 40px;
            height: calc(100% - 6rem);
        }
        
        .modal-body {
            background: #FCF7F2;
        }

        svg {
            right: 1rem;
            top: -3rem;
            height: 32px;
            width: 32px;
            cursor: pointer;
        }
        .modal-content {
            margin-top: 6rem;
        }
    }
    .white-shadow-button {
      background: #fff !important;
      color: #000 !important;
      border: none !important;
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.05);
    }

    .scale-animation {
        transition: all 0.2s ease;
        &:active,
        &:focus,
        &:hover,
        &:active:focus {
            transform: scale(1.05);
        }
    }
    @keyframes shake {
        0% { transform: rotate(0); }
        15% { transform: rotate(15deg); }
        30% { transform: rotate(-15deg); }
        45% { transform: rotate(12deg); }
        60% { transform: rotate(-12deg); }
        75% { transform: rotate(6deg); }
        85% { transform: rotate(-6deg); }
        92% { transform: rotate(3deg); }
        100% { transform: rotate(0); }
    }

    input, button, select, textarea {
        &:focus, &:active {
            box-shadow: 0 0 0 0.125rem #C3CBC2 !important;
        }
    }
    .registration-form {
        .form-control:valid {
            border-color: transparent !important;
        }
        input, textarea{
            background-image: none !important;
        }
    }
    .word-break-word {
        word-break: break-word;
    }
`;

export default GlobalStyles;
