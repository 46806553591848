import React, { useEffect, useState } from 'react';
import { CompleteForm } from './CompleteProfile.styled';
import fakebg from '../../assets/Images/fakebg.png';
// import modalClose from '../../assets/Images/modalClose.svg';
import { Col, Container, Row, Modal, Spinner } from 'react-bootstrap';
import { CardUI } from '../../components/UI/UI.styled';
import stepper, { defaultStep } from 'modules/complete-profile/stepper';
import { completeProfile } from 'helpers/http';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { getToken, removeToken, setToken } from 'helpers/utils/auth-provider';
import { profile } from 'helpers/http';
// import { useQuery } from 'react-query';
// import { useAuth } from 'helpers/contexts/AuthContext';

const INITIAL_FORM = {
  firstName: '',
  lastName: '',
  preferredName: '',
  email: '',
  phone: '',
  address: '',
  neighborhood: '',
  dob: '',
  allergies: [],
  additionalNotes: '',
  bio: '',
  isProfileCompleted: 'n',
  availability: {
    sunday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: []
  },
  password: '',
  tempAvail: []
};

function CompleteSitterProfileForm() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const storageToken = getToken();
  const [loading, setLoading] = useState(false);
  // const [data, setData] = useState();

  // const { data, loading } = useQuery('sitterProfile', profile.getProfile, {
  //   refetchOnWindowFocus: true,
  //   refetchInterval: 100
  // });
  const [formData, setFormData] = useState(INITIAL_FORM);
  const [activeStep, setActiveStep] = useState(defaultStep);
  const navigate = useNavigate();

  const next = () => {
    const nextStep = stepper[activeStep].next;
    if (nextStep) setActiveStep(nextStep);
  };
  const back = () => {
    const prevStep = stepper[activeStep].back;
    if (prevStep) setActiveStep(prevStep);
  };
  const updateFormData = (data) => {
    setFormData((prevState) => ({ ...prevState, ...data }));
  };
  const submit = (data) => {
    const promise = completeProfile.completeProfileApi({ ...formData, ...data });

    toast.promise(promise, {
      loading: 'Submitting...',
      success: () => {
        navigate('/profile');
        return 'Profile submitted successfully!';
      },
      error: 'Error submitting profile!'
    });
  };

  const stepProps = {
    next,
    back,
    formData,
    updateFormData,
    submit
  };

  // const handleModalShowHide = () => {
  //   setShowHide(!showHide);
  // };

  const StepComponent = React.useMemo(() => {
    const step = stepper[activeStep];
    if (!step) {
      return null;
    }
    return step.component;
  }, [activeStep]);

  useEffect(() => {
    if (token) {
      console.log('ccc', token);
      window.localStorage.setItem('__auth_provider_token__', token);
      setToken(token);
    }
  }, [token]);

  useEffect(() => {
    setLoading(false);
    if (storageToken && !formData.id) {
      profile.getProfile().then((res) => {
        if (!res?.data || res.data?.isProfileCompleted === 'y') {
          removeToken();
          navigate('/login');
        } else {
          updateFormData({ ...res?.data });
        }
      });
    }
  }, [storageToken, formData]);

  // if no token then don't show modal
  if (!token) {
    return <h1>Access denied</h1>;
  }

  return (
    <CompleteForm style={{ background: `url(${fakebg})` }}>
      <Container>
        <Row>
          <Col>
            <Modal show={true} centered className="completeProfileModal">
              {/* <CloseButton
                onClick={() => handleModalShowHide()}
                className="close_btn"
                style={{ background: `url(${modalClose})` }}
              /> */}
              <Modal.Body className="completeProfileForm">
                <CardUI>
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <StepComponent {...stepProps} />
                  )}
                </CardUI>
              </Modal.Body>
            </Modal>
            {/* <Button show={modalShow} onHide={() => setModalShow(false)}>clickme</Button> */}
          </Col>
        </Row>
      </Container>
    </CompleteForm>
  );
}

export default CompleteSitterProfileForm;
