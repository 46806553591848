import React from 'react';
import { removeToken, getToken, setToken } from 'helpers/utils/auth-provider';
import { useNavigate } from 'react-router-dom';
import { auth } from 'helpers/http';
import toast from 'react-hot-toast';
import { useCookies } from 'react-cookie';
import { profile } from 'helpers/http';
import { useQuery } from 'react-query';

const AuthContext = React.createContext();
AuthContext.displayName = 'AuthContext';

// TODO: improve this

function AuthProvider(props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [user, setUser] = React.useState(getToken());
  const [userInfo, setUserInfo] = React.useState({});
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const navigate = useNavigate();
  // const [isLoggedIn, setIsLoggedIn] = React.useState(!!cookies);
  const { data, isLoading: userInfoLoading } = useQuery('sitterProfile', profile.getProfile, {
    refetchOnWindowFocus: false,
    enabled: !!user
  });

  /**
   *  Login API
   *
   * @param {string} form.username
   * @param {string} form.password
   */

  const login = React.useCallback((data) => {
    setIsLoading(true);
    auth
      .login(data)
      .then((res) => {
        setToken(res.data.token);
        setUser(res.data);
        setCookie('user', res.data, {
          path: '/'
        });
        navigate('/dashboard');
        setIsLoading(false);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          setToken(err.response.data.data.token);
          setUser(err.response.data.data.token);
          window.localStorage.setItem('firstName', err.response?.data?.data?.firstName);
          window.localStorage.setItem('lastName', err.response?.data?.data?.lastName);
          window.localStorage.setItem('email', err.response?.data?.data?.email);
          navigate(`/sitter-complete-profile?token=${err.response.data.data.token}`);
          // toast.error(err.response.data.message);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toast.error("We couldn't find an account with that email and password.");
        }
      });
  }, []);

  React.useEffect(() => {
    if (data?.data) {
      setUserInfo(data?.data);
    }
  }, [data]);

  React.useEffect(() => {
    if (Object.keys(cookies).length === 0 && user) {
      toast.error('Session expired please login again');
      logout();
    }
  }, []);

  const logout = React.useCallback(() => {
    removeToken();
    setUser();
    removeCookie('user');
  }, []);

  const value = React.useMemo(
    () => ({
      user: user,
      userInfo,
      setUserInfo,
      isLoading,
      login,
      logout,
      userInfoLoading
    }),
    [user, isLoading, login, logout, userInfo, userInfoLoading]
  );

  return <AuthContext.Provider value={value} {...props} />;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
