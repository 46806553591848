/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import { Button, CloseButton, Col, Container, Form, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import SmallContainer from '../../components/layout/CustomContainer';
import { BiArrowBack } from 'react-icons/bi';
import { SaveEdit, StyledSitterProfile } from '../profile/SitterProfile.styled';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { SignatureContainer, TableWrapper } from './signature.styled';
import { useDropzone } from 'react-dropzone';
import modalClose from 'assets/Images/modalClose.svg';
import { profile } from 'helpers/http';

const Agreement = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [key, setKey] = React.useState('draw');

  const [show, setShow] = React.useState(false);
  const [sigPad, setSigPad] = React.useState({});
  const [uploadedSignDataUrl, setUploadedSignDataUrl] = React.useState(null);
  const [uploadError, setUploadError] = React.useState('');
  const [errors, setErrors] = React.useState({});
  const [form, setForm] = React.useState({
    employeeName: '',
    signature: '',
    date: ''
  });
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();

    const isValidated = validateForm();
    if (!isValidated) {
      return;
    }

    setIsLoading(true);
    const body = {
      employeeName: form.employeeName,
      signature: form.signature,
      date: form.date
    };
    const promise = profile.signDocument(body);
    toast.promise(promise, {
      loading: 'Saving...',
      success: (res) => {
        setIsLoading(false);
        navigate('/profile');
        // window.location.reload();
        return 'Policy Signed';
      },
      error: (err) => {
        setIsLoading(false);
        return err.response?.data?.message || 'Error!';
      }
    });
  };

  const validateForm = () => {
    const error = {};
    if (!form.employeeName) {
      error.employeeName = 'Employee name is required';
    }

    if (!form.signature) {
      error.signature = 'Employee signature is required';
    }

    if (!form.date) {
      error.date = 'Date is required';
    }

    if (Object.keys(error).length > 0) {
      setErrors(error);
      return false;
    } else {
      setErrors({});
      return true;
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setUploadedSignDataUrl('');
    setUploadError('');
    setKey('draw');
    setShow(true);
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onabort = () => setUploadError('File reading was aborted');
      reader.onerror = () => setUploadError('File reading has failed');
      reader.onload = () => {
        setUploadedSignDataUrl(reader.result);
      };
    });
  }, []);

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true
  });

  const handleAcceptAndSignDraw = () => {
    handleFormChange('signature', sigPad.getTrimmedCanvas().toDataURL('image/png'));
    handleClose();
  };

  const handleAcceptAndSignUpload = () => {
    handleFormChange('signature', uploadedSignDataUrl);
    handleClose();
  };

  const handleFormChange = (key, value) => {
    setForm((formData) => ({ ...formData, [key]: value }));
    // validateForm();
  };

  return (
    <>
      <Helmet>
        <title>Sitter policy</title>
      </Helmet>
      <StyledSitterProfile>
        <Container className="bg-white py-4">
          <SmallContainer className="smContainer">
            <Link to="/profile" className="my-4 d-inline-block scale-animation">
              <BiArrowBack /> Back
            </Link>
            <Form onSubmit={onSubmit}>
              <SaveEdit className="text-center">
                <p className="text-center">
                  MDE | 2024 Anytime Sitter Policy (Sitters) + Employee Confidentiality and
                  Non-Disclosure Agreement
                </p>
              </SaveEdit>

              <Row className="mt-1 mde-policy">
                <Col lg={12} className="p-0 px-md-2 mt-0">
                  <div className="mt-2">
                    <h3>
                      <b>I) Compensation</b>
                    </h3>
                    <p>
                      All ATS hourly session rates will be provided and agreed upon prior to each
                      session start. MDE’s base rate is $25/hour. The hourly rate can vary from the
                      base rate depending on the number of children, special circumstances, etc.
                      Please see below for a breakdown of typical rates:
                    </p>
                    <h3 className="mt-2">
                      <b>Hourly Pay Rates:</b>
                    </h3>
                    <div>
                      <TableWrapper className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">Number of Childern</th>
                              <th scope="col">Hourly Pay Rate</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Infant (0-5 months)</td>
                              <td>$25/hour</td>
                            </tr>
                            <tr>
                              <td>1 Child (6 months+)</td>
                              <td>$25/hour</td>
                            </tr>
                            <tr>
                              <td>2 Children</td>
                              <td>$30/hour</td>
                            </tr>
                            <tr>
                              <td>3 Childern</td>
                              <td>$32/hour</td>
                            </tr>
                            <tr>
                              <td>4 Children or Birthday Party</td>
                              <td>$35/hour</td>
                            </tr>
                            <tr>
                              <td>Postpartum Doula / RN (1-2 Children)</td>
                              <td>$35/hour</td>
                            </tr>
                          </tbody>
                        </table>
                      </TableWrapper>

                      <p>
                        <b>Overnight Rate: </b>$100/night paid for any session ending at or after 2
                        AM.
                      </p>
                      <div className="mt-2">
                        <p>
                          In accordance with New York State Labor Law, any hours logged after 40
                          hours per week will be counted as ‘overtime’ and paid at 1.5x the normal
                          hourly rate. In addition, any sitting session conducted on a national
                          holiday will be charged at 1.5x the normal hourly rate.
                        </p>
                        <p>
                          MDE Anytime Sitters are paid via TriNet with payroll processing every two
                          weeks.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>II) Booking Sessions</b>
                    </h3>
                    <p>
                      All sessions are booked through our MDE Portal. As an MDE sitter you will
                      receive a welcome email with a link inviting you to finish your portal profile
                      and explore your sitter dashboard. It is via the dashboard that you will be
                      able to accept/deny session requests from our families. It is imperative that
                      as soon as you are able to, you accept/deny a session request. Even if you are
                      unavailable and cannot work that session, you must take action and deny it.
                      Leaving a session neither accepted nor denied will cause problems for our MDE
                      Admin Team.
                    </p>
                    <p>
                      Please note that sometimes new families who are unfamiliar with our process
                      may attempt to book sessions directly with you. If this occurs please let them
                      know you cannot book any sessions and they need to book via their Family
                      Portal.
                    </p>
                    <p>
                      <b>Monthly Booking Requirement:</b> All sitters are required to book 4
                      sessions per month during the school year (September 1 - May 31) and 2
                      sessions per month during the summer (June 1- August 31). As we cannot control
                      what our families do, if a family cancels one of your booked sessions, this
                      will not count against you. Failure to meet the monthly booking requirement is
                      reason for termination.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>III) Cancelations</b>
                    </h3>
                    <p>
                      One week’s notice is required for the cancelation of any sitting session. If
                      you are unexpectedly sick, please provide as much notice as you can. If a
                      family cancels or reduces the length of your session within 24 hours of the
                      booking, you will still receive full pay for the session, at the base rate of
                      $25 per hour, regardless of the agreed rate for your session. Even if the
                      session is canceled, you must end the session via your Sitter Dashboard in
                      order to be paid.
                    </p>
                    <p>
                      As with bookings, families cannot cancel sessions directly with you. If a
                      family contacts you to cancel a session, please advise them to contact MDE
                      Admin at ATS@maisondenfantsny.com directly. The session is not canceled until
                      we hear from the family.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>IV) Session Expenses:</b>
                    </h3>

                    <h4>Transportation Before 7:00 AM or After 9:00 PM</h4>
                    <p>
                      Whenever a sitting session begins before 7:00 AM or ends past 9:00 PM please
                      take a taxi/rideshare of your choice to/from the session and submit the
                      receipt for reimbursement (up to $50) via the portal as you end the session,
                      inputting all session details. Reimbursement will be included in your regular
                      paycheck.
                    </p>
                    <h4>Meals</h4>
                    <p>
                      For sessions that fall within the following meal time boundaries, you can
                      expense up to $25 for each applicable meal. A receipt must be submitted via
                      the portal as you end your session, inputting all details. Reimbursement will
                      be included in your regular paycheck.
                    </p>
                    <p>
                      <b>Dinner Meal:</b> If your session starts before 6 PM, lasts longer than 3
                      hours and extends past 8 PM, families are required to provide you dinner.
                      Please purchase the meal of your choice through your chosen meal delivery app
                      (up to $25).
                    </p>
                    <p>
                      <b>Overnight Sessions:</b> If you are working for a family for 24 or more
                      consecutive hours the family is required to provide you breakfast, lunch and
                      dinner (up to $25/meal).
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>V) Ending A Session</b>
                    </h3>
                    <p>
                      After a session has ended you have 24 hours to end the session via your sitter
                      dashboard, inputting all session details (including expenses) and submitting
                      the session to MDE Admin for review. If you do not end the session within 24
                      hours of the session end time and/or submit session expenses within 24 hours,
                      you will not be paid or reimbursed for the session. It is imperative that you
                      end your session within 24 hours of the session end time.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>VI) Social Media</b>
                    </h3>
                    <p>
                      MDE prides itself on protecting the privacy of our families and the children
                      in our care. You must never share photos of the children in your care on any
                      social media platform. You must never engage in online public debate or make
                      discriminatory remarks while representing MDE.
                    </p>
                    <p>
                      If you do wish to express strong opinions on public online forums, you must
                      make it clear that they are your personal views and do not reflect MDE’s
                      position. Be sure to follow the law, regulations and the terms and conditions
                      of any site where you do participate in public discussion.
                    </p>
                    <p>
                      To protect MDE’s brand, the use of logos or trademarks without permission is
                      prohibited. In addition, we strongly suggest that all sitters have private
                      social media accounts as it should be assumed that families will do social
                      media checks.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>VII) Photos</b>
                    </h3>
                    <p>
                      Parents may request that you send photo updates of their child(ren). Once you
                      send the photo you must delete it from your camera roll and ‘deleted items’
                      folder immediately to ensure it is permanently removed from your phone/device.
                      In no other circumstances should you take, share, or distribute a photo of a
                      child in your care.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>VIII) Phone Use</b>
                    </h3>
                    <p>
                      It is important to stay engaged and present with the children who are in your
                      care at all times. Phone use while children are awake should be limited to
                      sending updates to parents and emergency calls/texts.
                    </p>
                    <p>
                      When a child is sleeping/napping you may use your phone as you wish as long as
                      it does not disturb them or distract you from monitoring/checking on them.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>IX) Monitoring + Camera Usage</b>
                    </h3>
                    <p>
                      Your privacy and comfort in your working environment is essential. We ask all
                      families to disclose in advance if they have any in-home video monitor(s). If
                      you ever feel uncomfortable or that a family has been monitoring you without
                      your knowledge or consent, please notify MDE immediately.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>X) Hygiene</b>
                    </h3>
                    <p>
                      Sitters are required to wash their hands thoroughly when you arrive at your
                      sitting job, before picking up a baby or child, before and after
                      eating/handling/preparing food, after going to the bathroom, and after being
                      outdoors. Our families may also have other requirements to help maintain the
                      hygiene and peace of their home (for example, a ‘no shoe’ policy) - we ask
                      them to let us know in advance but it’s always a good idea for you to ask as
                      well when starting with a new family.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>XI) Safety</b>
                    </h3>
                    <h4>Training + Certification</h4>
                    <p>
                      All MDE Anytime Sitters are required to have an up-to-date CPR/first aid
                      certification. If you do not have a valid CPR/first aid certification, MDE
                      will provide this training for you before you attend your first sitter session
                      with a family.
                    </p>
                    <h4>General Safety</h4>
                    <p>
                      If a child is on the couch, changing table, bed or any other surface that they
                      are able to roll off of (basically if they are not on the floor, strapped into
                      a seat or stroller, or in a cot) you should have a hand on them or be watching
                      them at all times.
                    </p>
                    <p>
                      Remember your CPR/first aid training and ensure the parents have provided
                      emergency contact information including their cell/work numbers,
                      doctor/nearest hospital, any other emergency contacts and a list of
                      medications and allergies.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>XII) Pets</b>
                    </h3>
                    <p>
                      Please let us know if you have any animal allergies or preferences to sitting
                      in a pet-free home, so that we can place you with the right families for you.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>XIII) Immunizations + COVID-19</b>
                    </h3>
                    <p>
                      All sitters must provide a record of all required up-to-date immunizations in
                      order to begin work with our families. It is essential that you have serology
                      completed for Measles immunity on the next visit to the PCP. We also require
                      an up to date Pertussis (TDap) immunization for all staff. Please note that
                      while the flu shot is optional, but recommended, the COVID-19 vaccination is
                      not optional. Proof of the COVID-19 vaccine must be included in your
                      records.You can send a copy of your immunizations to ats@maisondenfantsny.com.
                    </p>
                    <p>
                      We ask both our staff and the families we work with to be extra vigilant about
                      monitoring their health status. For our staff, we ask that if you feel any
                      COVID-19 symptoms, you take a test immediately and let us know so we can
                      cancel/reassign any upcoming sessions. We also ask our families to keep us
                      informed if they test positive for COVID-19 or find out they have come into
                      contact with anyone who has tested positive for COVID-19. If illness does
                      occur, prior to starting work again, we require that you provide a negative
                      test result before resuming work.
                    </p>
                    <p>
                      At MDE, we are committed to protecting public health, while still protecting
                      our community’s privacy. We do not require that our families are tested for
                      COVID-19 but do require them to share positive test results with us. We ask
                      for transparency and full disclosure because when we are armed with
                      information, we can ensure the health, wellbeing and safety of everyone that
                      we work with. In return, we are committed to treating your sensitive personal
                      information with the utmost discretion and respect.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>XIV) Dress Code</b>
                    </h3>
                    <p>
                      All sitters are required to wear appropriate dress when attending sitting
                      sessions for MDE. We want all sitters to feel comfortable in the clothes they
                      wear when watching children and know that you will most likely be sitting on
                      the ground, playing with toddlers, painting, getting messy, etc. Please do not
                      wear short shorts or spaghetti strap tank tops.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>XV) Immediate Termination Clause</b>
                    </h3>
                    <p>
                      The following is grounds for immediate termination, including, but not limited
                      to:
                    </p>
                    <ul>
                      <li>Allowing the safety of the child(ren) to be compromised</li>
                      <li>Convicted of a crime</li>
                      <li>
                        Inconsistent or non-performance of agreed upon job responsibilities as
                        stated within the ATS Policy
                      </li>
                      <li>Dishonesty</li>
                      <li>Stealing</li>
                      <li>
                        Breach of MDE confidentiality clause or social media policy as stated within
                        the ATS
                      </li>
                      Policy
                      <li>Persistent absenteeism or tardiness</li>
                      <li>Missing a day of work without notice</li>
                      <li>Unapproved guests in the Family home</li>
                      <li>Smoking of any kind or consumption of alcohol while on duty</li>
                      <li>Use of an illegal drug and/or marijuana while on duty</li>
                      <li>Violation of your ATS contract</li>
                    </ul>
                  </div>
                  <div className="mt-2">
                    <h4>MDE Employee Confidentiality and Non-Disclosure Agreement</h4>
                    <h3 className="mt-2">
                      <b>MAISON D’ENFANTS, LLC CONFIDENTIALITY STATEMENT</b>
                    </h3>
                    <p>
                      To Maison D’Enfants, LLC (“MDE”), confidentiality and privacy are of the
                      utmost importance. All MDE clients (“Client Families” or “Client Family”)
                      deserve to receive the highest level of privacy and discretion as you, the MDE
                      employee (“Employee”), enter into their homes and join their family life to
                      provide them with support and care. As an MDE Employee, you are representing
                      our company and our values, and therefore expected to adhere to the highest
                      standards of privacy and confidentiality at all times as outlined in the below
                      agreement.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>ACKNOWLEDGEMENT OF PRIVATE FAMILY INFORMATION</b>
                    </h3>
                    <p>
                      The Employee acknowledges that during their engagement with the Client Family,
                      the Employee will be exposed to the following private and confidential items
                      that constitute the Client Family’s private family information ("Private
                      Family Information"): (1) medical histories and medical conditions, (2)
                      financial information and confidential work matters, (3) family relationships
                      and dynamics, (4) family passwords, and all other matters related to the
                      Client Family. The Employee acknowledges that if any Private Family
                      Information becomes known by any individuals outside the Client Family, such
                      knowledge could result in substantial hardship, loss, damage and injury to the
                      Client Family.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>NON-DISCLOSURE OF PRIVATE FAMILY INFORMATION AND CONFIDENTIALITY</b>
                    </h3>
                    <p>
                      The Employee agrees that they will not, during the term of the Employee’s
                      engagement with the Client Family or any time thereafter, directly or
                      indirectly disclose the Client Family’s Private Family Information to any
                      entity or any person who is not a member of the Client Family or an employee
                      of MDE. The Employee hereby agrees that they: (1) will not, directly or
                      indirectly, disclose any Private Family Information in any way to a third
                      party, (2) will limit access to Private Family Information solely to those
                      persons or entities to whom such disclosure is expressly permitted by this
                      Agreement, and (3) shall use such information only in performing duties for
                      MDE. Nothing herein is intended, nor shall it, grant the Employee any
                      ownership rights to the Private Family Information. To the extent that the
                      Employee actually owns any right, title and/or interest in Private Family
                      Information, then the Employee hereby irrevocably assigns and conveys such
                      rights to the Client Family without the need for further remuneration or
                      documentation from the Client Family to the Employee. Under no circumstances
                      shall the Employee permit any disclosure of such Private Family Information to
                      any person or entity to which disclosure is not permitted by this Agreement.
                      The Employee agrees to maintain the Private Family Information in the
                      strictest trust and confidence. The Employee further agrees not to retain any
                      written or electronic Private Family Information.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>CLIENT FAMILY’S REMEDIES</b>
                    </h3>
                    <p>
                      The Employee agrees that all obligations imposed upon the Employee by the
                      agreement shall survive the termination of the Employee’s engagement with the
                      Client Family. If the Employee breaches or threatens to breach any term or
                      provision contained in this Agreement, the Employee agrees that the Client
                      Family will be entitled to seek and obtain temporary and/or permanent
                      injunctive relief to enjoin the Employee from violating the terms and
                      provisions of this Agreement. The right of the Client Family to seek and
                      obtain such relief will not be construed to prevent the Client Family from
                      pursuing, either conjunctively or concurrently, any other legal or equitable
                      remedies.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>APPLICABLE LAW</b>
                    </h3>
                    <p>
                      This Agreement shall be construed in accordance with the laws of the State of
                      New York without regard to the principles of conflicts of law.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>WAIVER</b>
                    </h3>
                    <p>
                      Any waiver by any party, expressed or implied, of a default or breach of any
                      term of this Agreement shall not be deemed to be a waiver of any other default
                      or breach. Failure of a party to declare or act upon any default will not
                      constitute a waiver of such default.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>ATTORNEY FEES</b>
                    </h3>
                    <p>
                      If the Employee breaches this Agreement and the Client Family employs an
                      attorney as a result of such breach, the Employee agrees to pay to the Client
                      Family all such reasonable attorney's fees, court costs, and expenses that the
                      Client Family may incur.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>ASSIGNMENT</b>
                    </h3>
                    <p>This Agreement is non-assignable and non-delegable.</p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>INDEMNIFICATION</b>
                    </h3>
                    <p>
                      The Employee shall indemnify, defend (with counsel acceptable to the Client
                      Family) and hold the Client Family harmless from and against any and all
                      losses, costs, damages, claims, liabilities, or expenses, including reasonable
                      attorneys’ fees and costs, in any way arising from or related to the failure
                      of the Employee to observe or perform any obligation under this Agreement.
                    </p>
                  </div>
                  <div className="mt-2">
                    <h3>
                      <b>MULTIPLE CLIENT FAMILIES CLAUSE</b>
                    </h3>
                    <p>
                      As an MDE Employee, I acknowledge that I may work with/for multiple MDE Client
                      Families. I understand that by signing this Agreement, I am agreeing to adhere
                      to the Agreement for every Client Family I work for, for the entire duration
                      of my employment at MDE.
                    </p>
                    <p>
                      My electronic signature confirms I have read, understand and agree to all of
                      the above terms and conditions of the MDE Anytime Sitter Policy, as well as,
                      the MDE Employee Confidentiality and Non-Disclosure Agreement.
                    </p>
                  </div>

                  <div className="mt-4">
                    <label htmlFor="">Employee Name | Print Name:</label>
                    <input
                      type="text"
                      name="employeeName"
                      onChange={(e) => handleFormChange('employeeName', e.target.value)}
                    />
                    <Form.Text className="validation-error">{errors.employeeName}</Form.Text>
                  </div>
                  <div className="mt-4">
                    <label htmlFor="">Employee Name | Signature:</label>
                    {form.signature ? (
                      <img alt="signature" className="sign-img" src={form.signature} />
                    ) : null}
                    <button
                      type="button"
                      className="primary-button mx-2 p-0 text-decoration-underline"
                      style={{ background: 'none', border: 0 }}
                      onClick={handleShow}
                      disabled={isLoading}>
                      Create E-Signature
                    </button>
                    <Form.Text className="validation-error">{errors.signature}</Form.Text>
                  </div>
                  <div className="mt-4">
                    <label htmlFor="">Date:</label>
                    <input
                      type="date"
                      name="date"
                      onChange={(e) => handleFormChange('date', e.target.value)}
                    />
                    <Form.Text className="validation-error">{errors.date}</Form.Text>
                  </div>
                </Col>

                <Col lg={12} className="p-0 px-md-2 mb-4 mt-5">
                  <Button variant="alt" className="less-padding" type="submit" disabled={isLoading}>
                    Save Signed Policy
                  </Button>
                </Col>
              </Row>
            </Form>

            <SignatureContainer
              show={show}
              onHide={handleClose}
              centered
              className="completeProfileModal">
              <CloseButton
                onClick={handleClose}
                className="close_btn"
                style={{ background: `url(${modalClose})` }}
              />
              <Modal.Body className="mde-policy-modal">
                <h3 className="mb-4">Signature</h3>
                <Tabs
                  defaultActiveKey="draw"
                  className="mb-3"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}>
                  <Tab eventKey="draw" title="Draw">
                    <SignatureCanvas
                      penColor="black"
                      canvasProps={{ className: 'sigCanvas' }}
                      ref={(ref) => setSigPad(ref)}
                    />
                  </Tab>
                  <Tab eventKey="upload" title="Upload">
                    {uploadError && (
                      <Form.Text className="validation-error mb-1">{uploadError}</Form.Text>
                    )}
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />

                      {uploadedSignDataUrl ? (
                        <>
                          <img alt="signature" className="sign-img" src={uploadedSignDataUrl} />
                          <button
                            type="button"
                            className="less-padding btn btn-alt modal-ft-btn float-left m-0"
                            onClick={() => setUploadedSignDataUrl(null)}>
                            Remove Image
                          </button>
                        </>
                      ) : (
                        <>
                          <p className="m-0">Drag & drop a signature image</p>
                          <p className="m-0">or</p>
                          <button
                            type="button"
                            className="less-padding btn btn-alt modal-ft-btn float-left m-0"
                            onClick={open}>
                            Select Signature Image
                          </button>
                          <em>(Only *.jpeg and *.png images will be accepted)</em>
                        </>
                      )}
                    </div>
                  </Tab>
                </Tabs>

                <p style={{ fontSize: '0.8rem' }} className="mb-3">
                  By signing this Anytime Sitter Policy via electronic signature, I understand that
                  my electronic signature is given the same legal force and effect as a handwritten
                  signature.
                </p>
                <div>
                  {key === 'draw' && (
                    <button
                      type="button"
                      className="less-padding btn btn-alt modal-ft-btn float-left"
                      onClick={() => sigPad.clear()}>
                      Clear
                    </button>
                  )}
                  <button
                    type="button"
                    className="less-padding btn btn-alt modal-ft-btn float-right"
                    style={{ float: 'right' }}
                    onClick={() => {
                      key === 'draw' ? handleAcceptAndSignDraw() : handleAcceptAndSignUpload();
                    }}
                    disabled={key === 'upload' && !uploadedSignDataUrl}>
                    Accept and Sign
                  </button>
                </div>
              </Modal.Body>
            </SignatureContainer>
          </SmallContainer>
        </Container>
      </StyledSitterProfile>
    </>
  );
};

export default Agreement;
