import React from 'react';
import { CardUI } from 'components/UI/UI.styled';
import { Form, Spinner } from 'react-bootstrap';
import { profile } from 'helpers/http';
import { useQuery } from 'react-query';

export const EditBio = ({ updateFormData }) => {
  const { data, isLoading } = useQuery('sitterProfile', profile.getProfile);
  const [bioLength, setBioLength] = React.useState(0);

  const handleChange = (e) => {
    setBioLength(e.target.value.length);
    updateFormData({ bio: e.target.value });
  };

  if (isLoading) {
    return (
      <CardUI className="text-center">
        <Spinner animation="border" size="sm" />
      </CardUI>
    );
  }

  return (
    <CardUI>
      <Form.Group className="mb-3 styled_input">
        <Form.Label>Bio</Form.Label>
        <Form.Control
          defaultValue={data?.data?.bio}
          name="bio"
          as="textarea"
          rows="3"
          maxLength={255}
          onChange={handleChange}
          placeholder="Enter Bio"
          disabled={true}
        />
      </Form.Group>
      <small className="opacity-07 float-end fs-7">{bioLength}/255</small>
    </CardUI>
  );
};
