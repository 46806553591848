import React from 'react';
import { Image, Spinner, Table } from 'react-bootstrap';
import Card from 'components/UI/Card';
import { StyledSession } from './SitterDash.Styled';
import { ReactComponent as Calendar } from 'assets/Images/Cal.svg';
import { ReactComponent as Clock } from 'assets/Images/clock.svg';
import default_family_icon from 'assets/icons/default-family.svg';
import userIcon from 'assets/Images/usericon.svg';
import EndSessionModal from './EndSessionModal';
import { Button } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { dashboard } from 'helpers/http/index';
import SessionDetails from './SessionDetails';
import moment from 'moment-timezone';
import NoDataUI from 'components/NoDataUI';

function SitterSession() {
  const [isOpen, setIsOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [modalData, setModalData] = React.useState({});
  const [modalDataEnd, setModalDataEnd] = React.useState({});

  const handleClose = () => setShow(false);

  const { data, isLoading, refetch } = useQuery(
    'activeSessions',
    () =>
      dashboard.sessions({
        oFilterBy: {
          status: 'active'
        }
      }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 10000
    }
  );

  const showModal = (session) => {
    setModalDataEnd(session);
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const handleShow = (id) => () => {
    dashboard
      .getSession(id)
      .then((res) => {
        setModalData(res);
        setShow(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (isLoading) {
    return (
      <StyledSession className="mb-3">
        <Card className="p-lg-4 text-center">
          <Spinner animation="border" size="sm" />
        </Card>
      </StyledSession>
    );
  }

  if (data?.data?.records?.length === 0) {
    return (
      <StyledSession className="mb-3">
        <Card className="borderBottomNone">
          <p className="m-0">Active session</p>
        </Card>
        <Card className="borderTopNone d-lg-flex sessionTop justify-content-center">
          <div className="text-center">
            {/* <h6 className="gray text-center m-3">You have no active sessions</h6> */}
            <NoDataUI boxCount={false} notFoundText="You have no active session" />
          </div>
        </Card>
      </StyledSession>
    );
  }

  return (
    <>
      {data?.data?.records?.map((session, index) => (
        <StyledSession key={index} className="mb-3 scale-animation">
          <Card className="borderBottomNone">
            <p className="m-0">Active session</p>
            <div>
              <Button
                className="scale-animation"
                variant="primary"
                onClick={handleShow(session?.id)}>
                View Details
              </Button>
              <Button
                className="scale-animation"
                onClick={() => showModal(session)}
                variant="primary">
                End Session
              </Button>
            </div>
          </Card>
          <Card className="borderTopNone d-lg-flex sessionTop">
            <div className="d-flex align-items-center ">
              <Image
                src={session?.familyPhoto || default_family_icon}
                alt=""
                height={71}
                width={71}
                className="rounded"
              />
              <p className="m-0 px-3 fs-5">{`${session?.firstName} ${session?.lastName}`}</p>
            </div>
            <Table borderless className="m-0 session-details-table">
              <thead align="left" valign="middle">
                <tr>
                  <th>DATE</th>
                  <th>Time</th>
                  <th>Duration</th>
                  <th>Children</th>
                </tr>
              </thead>
              <tbody>
                <tr className="sessionInfo">
                  <td>
                    <span className="d-md-none">DATE</span>
                    <div>
                      <Calendar />
                      <p className="m-0">
                        {moment(session?.sessionStart).tz('America/New_York').format('ll')}
                      </p>
                    </div>
                  </td>
                  <td>
                    <span className="d-md-none">Time</span>
                    <div>
                      <Clock />
                      <p className="m-0">
                        {moment(session?.sessionStart).tz('America/New_York').format('LT') +
                          ` - ` +
                          moment(session?.sessionEnd).tz('America/New_York').format('LT')}
                      </p>
                    </div>
                  </td>
                  <td>
                    <span className="d-md-none">Duration</span>
                    <div>
                      <p className="m-0">
                        {moment
                          .utc(
                            moment
                              .duration(
                                moment(session?.sessionEnd)
                                  .tz('America/New_York')
                                  ?.diff(moment(session?.sessionStart).tz('America/New_York'))
                              )
                              .as('milliseconds')
                          )
                          .format('HH:mm')}
                      </p>
                    </div>
                  </td>
                  <td>
                    <span className="d-md-none">Children</span>
                    <div>
                      {/* {session?.numberOfChildren} */}
                      {/* <img src={userIcon} alt="" /> */}
                      <div>
                        {Array.from(Array(session?.numberOfChildren)).map((v, index) => (
                          <img src={userIcon} alt="" key={index} />
                        ))}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </StyledSession>
      ))}
      <EndSessionModal
        showModal={showModal}
        isOpen={isOpen}
        hideModal={hideModal}
        session={modalDataEnd}
        refetch={refetch}
      />
      <SessionDetails show={show} handleClose={handleClose} data={modalData} />
    </>
  );
}

export default SitterSession;
