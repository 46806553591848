import React from 'react';
import { CardUI } from 'components/UI/UI.styled';
import { Form, Row, Col, Spinner, InputGroup, Image } from 'react-bootstrap';
import { common, profile } from 'helpers/http/index';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import axios from 'axios';
import toast from 'react-hot-toast';

export const ReactSelectWrapper = styled.div`
  .css-1s2u09g-control {
    border-radius: 8px;
    /* height: 50px; */
    background-color: #f6f6f6;
  }

  .css-1hb7zxy-IndicatorsContainer {
    display: none;
    height: 50px;
  }
  //todo: improve this
  input {
    height: 38px !important;
  }

  .css-g1d714-ValueContainer {
    background-color: #f6f6f6;
  }

  .css-1s2u09g-control {
    border: none;
  }

  input {
    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }
`;

export const DateWrapper = styled.div`
  height: 300px;
  overflow: auto;
  p {
    /* height: 12px; */
    margin: 0px;
  }
  h2 {
    font-size: 0.8rem;
    margin-bottom: 0rem;
  }
  .nyhan {
    background: #c3cbc2;
    border: 1px solid #cbc8c9;
    border-radius: 0.3rem;
  }
  .kWUNsE {
    background: #f7f7f7;
    border: 1px solid #cbc8c9;
    border-radius: 0.3rem;
  }
  div {
    background: #f7f7f7;
    padding: 0.2rem;
    border-radius: 16px;
    div {
      margin-bottom: 0.25rem;
      div {
        margin-top: 2px;
        height: 14px;
      }
    }
  }
`;

const StyledImage = styled(Image)`
  height: 100px;
  width: 100px;
  border-radius: 100%;
`;

const StyledEmptyImageContainer = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 100% !important;
  background: #efefef;
  display: grid;
  align-content: center;
  text-align: center;
  cursor: pointer;
`;

const HomeAddressInfo = styled.h6`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  color: #9c9c9c;
`;

export const EditGeneralInfo = ({ updateFormData, formData }) => {
  const { data, isLoading } = useQuery('sitterProfile', profile.getProfile);
  const [sitterPhoto, setSitterPhoto] = React.useState({ photo: null, name: '' });

  React.useEffect(() => {
    if (data) {
      updateFormData({
        firstName: data?.data?.firstName,
        lastName: data?.data?.lastName,
        preferredName: data?.data?.preferredName,
        email: data?.data?.email,
        phone: data?.data?.phone,
        photo: data?.data?.photo,
        address: data?.data?.address,
        neighborhood: data?.data?.neighborhood,
        allergies: data?.data?.allergies,
        additionalNotes: data?.data?.additionalNotes,
        bio: data?.data?.bio
      });
    }
    if (data?.data?.photo) {
      setSitterPhoto({ ...sitterPhoto, photo: data?.data?.photo });
    }
  }, [data]);

  if (isLoading) {
    return (
      <CardUI className="text-center">
        <Spinner animation="border" size="sm" />
      </CardUI>
    );
  }

  // Handle Family picture upload
  const handleFamilyPicture = (e) => {
    const { files } = e.target;
    const file = files[0];
    setSitterPhoto({ ...sitterPhoto, name: file.name });
    common.getUploadUrl({ filename: file.name }).then((res) => {
      const { data: uploadURL } = res;
      const contentType = file.type.startsWith('image/') ? '*/*' : '';

      if (contentType !== '') {
        const promise = axios
          .put(uploadURL, file, {
            headers: { 'Content-Type': contentType }
          })
          .then(() => {
            setSitterPhoto({ ...sitterPhoto, photo: uploadURL.split('?')[0] });
            updateFormData({ ...formData, photo: uploadURL.split('?')[0] });
          });

        toast.promise(promise, {
          loading: 'Loading',
          success: 'Uploaded',
          error: 'Error uploading'
        });
      }
    });
  };

  const handleChange = (e) => {
    if (e.target.name === 'phone' && isNaN(e.target.value)) {
      return;
    }
    updateFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <CardUI>
      <InputGroup className="mb-4">
        <input
          type="file"
          name="uploadfile"
          id="img"
          accept="image/*"
          onChange={handleFamilyPicture}
          disabled
          style={{
            width: 0,
            height: 0,
            visibility: 'hidden',
            backgroundImage: `url(${sitterPhoto.photo})`
          }}
        />
        <label htmlFor="img" className="image-container">
          {sitterPhoto.photo ? (
            <StyledImage src={sitterPhoto.photo} alt="familyPhoto" title="family-photo" />
          ) : (
            <StyledEmptyImageContainer>Add Sitter Photo</StyledEmptyImageContainer>
          )}
        </label>
      </InputGroup>

      <h3>General Information</h3>
      <Row>
        <Col md="6">
          <Form.Group className="mb-3 styled_input">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              defaultValue={data?.data?.firstName}
              name="firstName"
              disabled
              onChange={handleChange}
              type="text"
              placeholder="First Name"
            />
            <Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group className="mb-3 styled_input">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              disabled
              defaultValue={data?.data?.lastName}
              name="lastName"
              type="text"
              placeholder="Last Name"
            />
            <Form.Control.Feedback type="invalid">Please choose a username.</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="mb-3 styled_input">
        <Form.Label>Preferred Name</Form.Label>
        <Form.Control
          defaultValue={data?.data?.preferredName}
          onChange={handleChange}
          name="preferredName"
          type="text"
          placeholder="Enter Preferred Name"
        />
      </Form.Group>
      <Form.Group className="mb-3 styled_input">
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          defaultValue={data?.data?.email}
          onChange={handleChange}
          name="email"
          disabled
          type="text"
          placeholder="Enter Email Address"
        />
      </Form.Group>
      <Form.Group className="mb-3 styled_input">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          value={data?.data?.phone}
          onChange={handleChange}
          name="phone"
          type="text"
          placeholder="Enter Phone Number"
        />
      </Form.Group>
      <Form.Group className="mb-3 styled_input">
        <Form.Label>Home Address</Form.Label>
        <HomeAddressInfo>
          Please add your entire home address, including apartment number, city, state and zip code.
        </HomeAddressInfo>
        <Form.Control
          defaultValue={data?.data?.address}
          onChange={handleChange}
          name="address"
          type="text"
          placeholder="Enter Home Address"
        />
      </Form.Group>
      <Form.Group className="mb-3 styled_input">
        <Form.Label>Neighborhood</Form.Label>
        <Form.Control
          defaultValue={data?.data?.neighborhood}
          onChange={handleChange}
          name="neighborhood"
          type="text"
          placeholder="Enter Neighborhood Address"
        />
      </Form.Group>
    </CardUI>
  );
};
