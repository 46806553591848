/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, CloseButton, Form, Row, Col, Button, Image } from 'react-bootstrap';
import Card from 'components/UI/Card';
import modalClose from 'assets/Images/modalClose.svg';
import styled from 'styled-components';
import { ReactComponent as AttachIcon } from 'assets/icons/attach.svg';
import { ReactComponent as CloseIcon } from 'assets/Images/x.svg';
import moment from 'moment-timezone';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useCookies } from 'react-cookie';
import { common, dashboard } from 'helpers/http';
import axios from 'axios';
import toast from 'react-hot-toast';
import TimePicker from 'react-time-picker';
import Select from 'react-select';
import { MealExpenseCondition, TransportExpenseCondition } from 'helpers/utils/consts';
import { useAuth } from 'helpers/contexts/AuthContext';

/**
 * getYupErrors will format errors to simple object
 * from yup error inner object.
 */
export const getYupErrors = (error) => {
  const errors = error.inner.reduce((acc, error) => {
    return {
      ...acc,
      [error.path]: error.message
    };
  }, {});
  return errors;
};

const ModalWrapper = styled(Modal)`
  @media screen and (max-width: 767px) {
    .modal-body {
      padding: 2rem !important;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  .number-input::-webkit-outer-spin-button,
  .number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .number-input[type='number'] {
    -moz-appearance: textfield;
  }

  .react-datepicker__tab-loop {
    display: inline-flex;
  }

  .react-time-picker__clock-button,
  .react-time-picker__clock,
  .react-time-picker__clear-button {
    display: none !important;
  }

  .react-time-picker__inputGroup__leadingZero {
    display: flex;
    align-items: center;
  }

  .react-time-picker__inputGroup {
    display: contents;
  }

  .react-time-picker__inputGroup__divider {
    height: auto !important;
    margin-top: -3px;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .react-time-picker {
    width: 100%;
    input,
    select {
      all: unset;
    }
    select {
      text-align-last: right !important;
      border-radius: 0px;
    }
  }

  .react-time-picker__wrapper {
    width: 100% !important;
    background: #f6f6f6;
    border-radius: 8px !important;
    border: none;
    padding: 1rem 1.125rem;
    height: 50px;
    font-size: 0.875rem;

    input,
    select {
      border-radius: 0px !important;
    }
  }

  .cust-check {
    input {
      &:checked {
        background-color: #c3cbc2;
        border-color: transparent;
      }
    }
  }
  .cust-select {
    > div {
      border: 1px solid #ced4da;
      box-shadow: none;
      &:focus,
      &:hover {
        box-shadow: 0 0 0 0.125rem #c3cbc2 !important;
      }
    }
  }
  select {
    padding: 1rem 1.1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: #f6f6f6;
    border-radius: 0.5rem !important;
    font-size: 0.9rem;
    font-family: 'Didact Gothic';
    font-size: 0.95rem;
    color: #000;
  }
  .error {
    color: red;
  }
`;

const InputWrapper = styled.div`
  border: 1px solid #cbc8c9;
  border-radius: 8px;
  padding: 0.75rem;
  text-align: center;
  font-size: 0.95rem;
  vertical-align: middle;
  font-family: ${(props) => props.theme.fonts.didact};
  cursor: pointer;
`;

const ImageView = styled.div`
  position: relative;
  height: 78px;
  width: 78px;
  .close-view {
    position: absolute;
    top: -0.35rem;
    right: -0.25rem;
    border-radius: 100%;
    background: #ffffff;
    box-shadow: 0px -2px 10px rgb(0 0 0 / 25%);
    width: 17px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 9px;
      width: 9px;
      path {
        stroke: black;
      }
    }
  }
`;

const ImageWrapper = styled(Image)`
  height: 78px;
  width: 78px;
  border-radius: 4px;
  cursor: pointer;
`;

const options = [
  {
    value: '1',
    label: `$25/hour: Infant (0-5 months)`,
    rate: 33
  },
  {
    value: '2',
    label: `$25/hour: 1 Child (6 months+)`,
    rate: 30
  },
  {
    value: '3',
    label: `$30/hour: 2 Children`,
    rate: 35
  },
  {
    value: '4',
    label: `$32/hour: 3 Childern`,
    rate: 40
  },
  { value: '5', label: `$35/hour: 4 Children or Birthday Party`, rate: 45 },
  {
    value: '6',
    label: `$35/hour: Postpartum Doula / RN (1-2 Children)`,
    rate: 45
  }
];

function EndSessionModal({ isOpen, hideModal, session, refetch }) {
  // eslint-disable-next-line no-unused-vars
  const [sessionForm, setSessionForm] = useState(session);
  const [cookies, setCookie, removeCookie] = useCookies(['user']);
  const [foodProof, setFoodProof] = useState({ photo: null, name: null });
  const [transportProof, setTransportProof] = useState({ photo: null, name: null });
  const [shouldShowFoodExpense, setShouldShowFoodExpense] = useState(false);
  const [shouldShowTransportExpense, setShouldShowTransportExpense] = useState(false);
  const [errors, setErrors] = useState({});
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  // Handle picture upload
  const handleFoodProof = (e) => {
    const { files } = e.target;
    const file = files[0];
    setFoodProof({ ...foodProof, name: file.name });
    common.getUploadUrl({ filename: file.name }).then((res) => {
      const { data: uploadURL } = res;
      const contentType = '*/*';

      if (contentType !== '') {
        const promise = axios
          .put(uploadURL, file, {
            headers: { 'Content-Type': contentType }
          })
          .then(() => {
            setFoodProof({ ...foodProof, photo: uploadURL.split('?')[0] });
          });

        toast.promise(promise, {
          loading: 'Loading',
          success: 'Uploaded',
          error: 'Error uploading'
        });
      }
    });
  };
  const handleTransportProof = (e) => {
    const { files } = e.target;
    const file = files[0];
    setTransportProof({ ...transportProof, name: file.name });
    common.getUploadUrl({ filename: file.name }).then((res) => {
      const { data: uploadURL } = res;
      const contentType = '*/*';

      if (contentType !== '') {
        const promise = axios
          .put(uploadURL, file, {
            headers: { 'Content-Type': contentType }
          })
          .then(() => {
            setTransportProof({ ...transportProof, photo: uploadURL.split('?')[0] });
          });

        toast.promise(promise, {
          loading: 'Loading',
          success: 'Uploaded',
          error: 'Error uploading'
        });
      }
    });
  };

  const schema = yup
    .object({
      userId: yup.string(),
      sessionDate: yup.string(),
      sessionStart: yup.string().required('Start time is required'),
      sessionEnd: yup.string().required('End time is required'),
      numberOfChildren: yup.string().nullable().required('Number of children is required'),
      sitterRate: yup.string().required('Sitter rate is required'),
      isOverNightSession: yup.string(),
      sessionNote: yup.string().nullable(),
      eveningMealExpenseAmount: yup.string().nullable(),
      eveningMealExpenseNote: yup.string().nullable(),
      // eveningMealExpenseProofUrl: yup.string(),
      transportExpenseAmount: yup.string().nullable(),
      transportExpenseNote: yup.string().nullable()
      // transportExpenseProofUrl: yup.string()
    })
    .required();

  const handleChange = (e) => {
    if (
      e.target.name === 'numberOfChildren' &&
      e.target.value &&
      (isNaN(e.target.value) || Number(e.target.value) < 1)
    ) {
      return;
    }

    setSessionForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const handleCheck = (e) => {
    setSessionForm((form) => ({
      ...form,
      isOverNightSession: form.isOverNightSession === 'n' ? 'y' : 'n'
    }));
  };

  const checkExpenseEligibility = (data) => {
    const start = moment(data.startTime, 'HH:mm');
    const end = moment(data.endTime, 'HH:mm');
    const hourStart = start.format('HH');
    const hourEnd = end.format('HH');
    const duration = moment.duration(end.diff(start));
    const hours = duration.asHours();
    if ((Number(hourStart) || 12) < 18 && (Number(hourEnd) || 12) >= 20) {
      setShouldShowFoodExpense(true);
    } else {
      setShouldShowFoodExpense(false);
    }
    if ((Number(hourStart) || 12) < 7 || (Number(hourEnd) || 12) >= 21) {
      setShouldShowTransportExpense(true);
    } else {
      setShouldShowTransportExpense(false);
    }
  };

  useEffect(() => {
    checkExpenseEligibility(sessionForm);
  }, [sessionForm]);

  const handleOnSubmit = (e, data) => {
    e.preventDefault();
    setIsLoading(true);
    let isValid = true;
    //validate images for expenses
    schema.isValid({ ...data }).then((valid) => {
      isValid = valid;
      if (data.eveningMealExpenseAmount && Number(data.eveningMealExpenseAmount) > 0) {
        if (!foodProof.photo) {
          isValid = false;
          setFoodProof((proof) => ({ ...proof, error: 'Evening meal expense proof is required' }));
        }
      }
      if (data.transportExpenseAmount && Number(data.transportExpenseAmount) > 0) {
        if (!transportProof.photo) {
          isValid = false;
          setTransportProof((proof) => ({
            ...proof,
            error: 'Transport expense proof is required'
          }));
        }
      }
      if (!isValid) {
        schema.validate({ ...data }, { abortEarly: false }).catch((err) => {
          const errors = getYupErrors(err);
          setErrors({ ...errors });
        });
        setIsLoading(false);
        return;
      }
      const sitterRateOption = options.find((opt) => opt.value === data.sitterRate);
      const sitterRate = sitterRateOption.rate;
      const start = moment(data.sessionStart).tz('America/New_York', true).format('DD-MM-YYYY');
      const sessionStart = moment(`${start} ${data.startTime}`, 'DD-MM-YYYY HH:mm')
        .tz('America/New_York', true)
        .format();

      const sessionEnd = moment(`${start} ${data.endTime}`, 'DD-MM-YYYY HH:mm')
        .tz('America/New_York', true)
        .format();

      const startMoment = moment(sessionStart);
      const end = moment(sessionEnd);
      const duration = moment.duration(end.diff(startMoment));
      const hours = duration.asHours();

      if (end.isBefore(startMoment)) {
        setErrors((err) => ({
          ...err,
          endTime: 'Invalid time, End time should be after the start time.'
        }));
        setIsLoading(false);
        return;
      }
      setErrors({});

      const formData = {
        ...data,
        sessionId: session.id,
        userId: cookies?.user?.id || user?.id,
        sessionStart,
        sessionEnd,
        eveningMealExpenseProofUrl: foodProof.photo,
        transportExpenseProofUrl: transportProof.photo,
        totalHours: Number(hours.toFixed(2)),
        sitterRate
      };

      let promiseData = {
        ...formData
      };
      const promise = dashboard.endSession(promiseData);

      toast.promise(promise, {
        loading: 'Loading',
        success: (res) => {
          hideModal();
          refetch();
          setIsLoading(false);
          return res?.data?.message || 'Session Ended';
        },
        error: (err) => {
          setIsLoading(false);
          return err?.response?.data?.message || 'Error ending session';
        }
      });
    });
  };

  useEffect(() => {
    if (isOpen) {
      setFoodProof({ photo: null, name: null });
      setTransportProof({ photo: null, name: null });
      setShouldShowFoodExpense(false);
      setShouldShowTransportExpense(false);
      setSessionForm({
        ...session,
        startTime: moment(session?.sessionStart).tz('America/New_York').format('HH:mm'),
        endTime: moment(session?.sessionEnd).tz('America/New_York').format('HH:mm'),
        sessionStart: moment(session?.sessionStart).tz('America/New_York').format('YYYY-MM-DD'),
        sitterRate: options[0].value
      });
      checkExpenseEligibility({
        ...session,
        startTime: moment(session?.sessionStart).tz('America/New_York').format('HH:mm'),
        endTime: moment(session?.sessionEnd).tz('America/New_York').format('HH:mm'),
        sessionStart: moment(session?.sessionStart).tz('America/New_York').format('YYYY-MM-DD'),
        sitterRate: options[0].value
      });
    }
  }, [isOpen, session]);

  // Custom function to round the minutes to the nearest 15-minute increment
  const roundToNearest15 = (minutes) => Math.round(minutes / 15) * 15;

  // Event handler for time input changes
  const handleTimeChange = (name, event) => {
    const { value } = event.target;
    const [hours, minutes] = value.split(':').map((part) => parseInt(part, 10));
    const roundedMinutes = roundToNearest15(minutes);
    const formattedMinutes =
      roundedMinutes === 60 ? '00' : roundedMinutes.toString().padStart(2, '0');
    const formattedTime = `${hours.toString().padStart(2, '0')}:${formattedMinutes}`;
    // handleFormChange(name, formattedTime);
    setSessionForm((form) => ({ ...form, [name]: formattedTime }));
  };

  return (
    <>
      <ModalWrapper
        className={`${isOpen ? 'blurred' : ''} completeProfileModal endSession mt-4`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isOpen}
        onHide={hideModal}>
        <CloseButton
          onClick={() => hideModal()}
          className="close_btn"
          style={{ background: `url(${modalClose})` }}
        />
        <Modal.Body>
          <Card>
            <h4>End Session Details</h4>
            <p>
              Please make sure you enter the exact start and end times of your session - not what
              was scheduled.
            </p>
            <Form onSubmit={(e) => handleOnSubmit(e, sessionForm)}>
              <Row>
                <Form.Group className="mb-3 styled_input">
                  <Form.Label>Session Date</Form.Label>
                  <Form.Control
                    type="date"
                    disabled
                    name="sessionDate"
                    value={moment(session?.sessionStart)
                      .tz('America/New_York')
                      .format('YYYY-MM-DD')}
                  />
                </Form.Group>
                <Col md="6">
                  <Form.Group className="mb-3 styled_input">
                    <Form.Label>Session Start</Form.Label>
                    <Form.Control
                      type="time"
                      name="startTime"
                      onChange={(e) => handleTimeChange('startTime', e)}
                      value={sessionForm.startTime}
                    />
                    {errors.startTime && (
                      <Form.Text className="error">{errors.startTime}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3 styled_input">
                    <Form.Label>Session End</Form.Label>
                    <Form.Control
                      type="time"
                      name="endTime"
                      value={sessionForm.endTime}
                      onChange={(e) => handleTimeChange('endTime', e)}
                    />
                    {errors.endTime && <Form.Text className="error">{errors.endTime}</Form.Text>}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-3 styled_input">
                    <Form.Label>Sitter Rate</Form.Label>
                    <Select
                      className="cust-select"
                      options={options}
                      formatOptionLabel={(data, meta) =>
                        meta.context === 'menu' ? (
                          <div>
                            {data.label.split('\n').map((lab) => (
                              <div key={lab}>{lab}</div>
                            ))}
                          </div>
                        ) : (
                          data.label
                        )
                      }
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          padding: '0.4rem 0rem !important',
                          backgroundColor: '#F6F6F6',
                          borderRadius: '4px',
                          height: '54.8px',
                          border: '1px solid #cbc8c9 !important',
                          boxShadow: 'none !important',
                          '&:hover': {
                            boxShadow: '0 0 0 0.125rem #c3cbc2 !important',
                            border: '1px solid #cbc8c9'
                          },
                          '&:focus': {
                            boxShadow: '0 0 0 0.125rem #c3cbc2 !important',
                            border: '1px solid #cbc8c9'
                          },
                          '&:active': {
                            boxShadow: '0 0 0 0.125rem #c3cbc2 !important',
                            border: '1px solid #cbc8c9'
                          }
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: 320
                        }),
                        input: (provided) => ({
                          ...provided,
                          '& > input:focus': {
                            boxShadow: 'none !important'
                          },
                          '& > input:active': {
                            boxShadow: 'none !important'
                          }
                        }),
                        option: (provided, state) => ({
                          ...provided,
                          color: '#000',
                          backgroundColor: state.isSelected ? '#C3CBC2' : '#fff',
                          '&:active': {
                            backgroundColor: '#C3CBC2',
                            opacity: '0.5'
                          },
                          '&:hover': {
                            backgroundColor: '#C3CBC2',
                            opacity: '0.5',
                            cursor: 'pointer'
                          }
                        })
                      }}
                      onChange={(e) =>
                        handleChange({ target: { name: 'sitterRate', value: e.value } })
                      }
                      defaultValue={
                        options.find((opt) => opt.value === session.sitterRate) || options[0]
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3 styled_input">
                    <Form.Label>Number of Children</Form.Label>
                    <Form.Control
                      name="numberOfChildren"
                      value={sessionForm.numberOfChildren}
                      onChange={handleChange}
                      className="number-input"
                      type="number"
                      min="1"
                    />
                    {errors.numberOfChildren && (
                      <Form.Text className="error">{errors.numberOfChildren}</Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group
                className="mb-3 d-md-flex justify-content-between reset_pass styled_input cust-check"
                controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  label=" Overnight Session"
                  name="isOverNightSession"
                  checked={sessionForm.isOverNightSession === 'y'}
                  value={sessionForm.isOverNightSession}
                  onChange={handleCheck}
                />
              </Form.Group>
              <Form.Group className="mb-3 styled_input">
                <Form.Label>Session Notes (Optional)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  name="sessionNote"
                  value={sessionForm.sessionNote}
                  onChange={handleChange}
                  placeholder="Please share any additional information about yourself that you think will be important for our admin team to know."
                />
              </Form.Group>
              {(MealExpenseCondition ? shouldShowFoodExpense : true) && (
                <>
                  <h4>Expense details</h4>
                  <p>Please share details on your meal and/or transportation expenses here.</p>
                  <h4>Meals</h4>
                  <p>
                    For sessions that fall within the following meal time boundaries, you can
                    expense up to $25 for each applicable meal. A receipt must be submitted via the
                    portal as you end your session, inputting all details. Reimbursement will be
                    included in your regular paycheck.
                  </p>
                  <p>
                    <b>Dinner Meal:</b> If your session starts before 6 PM, lasts longer than 3
                    hours and extends past 8 PM, families are required to provide you dinner. Please
                    purchase the meal of your choice through your chosen meal delivery app (up to
                    $25).
                  </p>
                  <p>
                    <b>Overnight Sessions:</b> If you are working for a family for 24 or more
                    consecutive hours the family is required to provide you breakfast, lunch and
                    dinner (up to $25/meal).
                  </p>
                  <Form.Group className="mb-3 styled_input">
                    <Form.Label>Expense Amount</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter expense amount"
                      name="eveningMealExpenseAmount"
                      value={sessionForm.eveningMealExpenseAmount}
                      onChange={handleChange}
                      disabled={!shouldShowFoodExpense}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 styled_input">
                    <Form.Label>Expense Notes (Optional)</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="eveningMealExpenseNote"
                      value={sessionForm.eveningMealExpenseNote}
                      onChange={handleChange}
                      placeholder="Please share any relevant notes about your expense here."
                    />
                  </Form.Group>
                  <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label className="w-100">
                      {foodProof?.photo ? (
                        <ImageView>
                          <ImageWrapper
                            src={foodProof?.photo}
                            alt={foodProof?.name}
                            title={foodProof?.name}
                          />
                          <div
                            role="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setFoodProof();
                            }}
                            className="close-view d-flex align-items-center justify-content-center">
                            <CloseIcon />
                          </div>
                        </ImageView>
                      ) : (
                        <InputWrapper>
                          <AttachIcon className="me-2" />
                          Attach proof of expense
                        </InputWrapper>
                      )}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      className="display-none"
                      onChange={handleFoodProof}
                    />
                    <Form.Text className="error">{foodProof.error}</Form.Text>
                    <div>
                      <span className="expense-note">
                        Note: Expense will not be accepted without an attached proof of expense
                      </span>
                    </div>
                  </Form.Group>
                </>
              )}
              {shouldShowTransportExpense && (
                <>
                  <h4>Transportation Expense</h4>
                  <p>
                    Please Note: Transportation can only be expensed if your session starts before 7
                    AM and/or ends after 9 PM.
                  </p>
                  <Form.Group className="mb-3 styled_input">
                    <Form.Label>Expense Amount</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter expense amount"
                      name="transportExpenseAmount"
                      value={sessionForm.transportExpenseAmount}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3 styled_input">
                    <Form.Label>Expense Notes</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="transportExpenseNote"
                      value={sessionForm.transportExpenseNote}
                      onChange={handleChange}
                      placeholder="Please share any relevant notes about your expense here."
                    />
                  </Form.Group>
                  <Form.Group controlId="transport" className="mb-3">
                    <Form.Label className="w-100">
                      {transportProof?.photo ? (
                        <ImageView>
                          <ImageWrapper
                            src={transportProof?.photo}
                            alt={transportProof?.name}
                            title={transportProof?.name}
                          />

                          <div
                            role="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setTransportProof();
                            }}
                            className="close-view d-flex align-items-center justify-content-center">
                            <CloseIcon />
                          </div>
                        </ImageView>
                      ) : (
                        <InputWrapper>
                          <AttachIcon className="me-2" />
                          Attach proof of expense
                        </InputWrapper>
                      )}
                    </Form.Label>
                    <Form.Control
                      type="file"
                      multiple
                      className="display-none"
                      onChange={handleTransportProof}
                    />
                    <Form.Text className="error">{transportProof.error}</Form.Text>
                    <div>
                      <span className="expense-note">
                        Note: Expense will not be accepted without an attached proof of expense
                      </span>
                    </div>
                  </Form.Group>
                </>
              )}
              <div className="float-end">
                <Button
                  variant="primary"
                  type="submit"
                  className="scale-animation"
                  disabled={isLoading}>
                  Submit
                </Button>
              </div>
            </Form>
          </Card>
        </Modal.Body>
      </ModalWrapper>
    </>
  );
}

export default EndSessionModal;
