import client from './client';

export default {
  login: (data) => client.post('/login', data).then((res) => res.data),
  forgotPassword: ({ email }) => client.post(`/forgot-password/${email}`).then((res) => res.data),
  resetPassword: ({ otp, verification, password }) =>
    client
      .post(
        '/confirm-forgot-password',
        { otp: otp, password: password },
        { headers: { verification: verification } }
      )
      .then((res) => res.data)
};
