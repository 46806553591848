const theme = {
  borderRadius: {
    sm: '5px',
    md: '10px',
    lg: '15px',
    xl: '20px'
  },
  fontSize: {
    sm: '0.8rem',
    md: '1rem',
    lg: '1.2rem',
    xl: '1.4rem'
  },

  colors: {
    main: '#343a40',
    secondary: 'magenta',
    primary: '#C3CBC2',
    alternative: '#f3f3f3',
    black: '#000'
  },
  fonts: {
    didact: `'Didact Gothic', sans-serif`,
    josefin: `'Josefin Sans', sans-serif`,
    montserrat: ` 'Montserrat', sans-serif`,
    brandon: `'Brandon Grotesque', sans-serif`
  }
};
export default theme;
