/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, CloseButton, Spinner, Form, Row, Col, Button } from 'react-bootstrap';
import { Availability } from '../profile/SitterProfile.styled';
import { profile } from 'helpers/http';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import styled from 'styled-components';
import Card from 'components/UI/Card';
import modalClose from 'assets/Images/modalClose.svg';
import toast from 'react-hot-toast';

const ModalWrapper = styled(Modal)`
  @media screen and (max-width: 767px) {
    .modal-body {
      padding: 2rem !important;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  .number-input::-webkit-outer-spin-button,
  .number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .number-input[type='number'] {
    -moz-appearance: textfield;
  }

  .react-datepicker__tab-loop {
    display: inline-flex;
  }

  .react-time-picker__clock-button,
  .react-time-picker__clock,
  .react-time-picker__clear-button {
    display: none !important;
  }

  .react-time-picker__inputGroup__leadingZero {
    display: flex;
    align-items: center;
  }

  .react-time-picker__inputGroup {
    display: contents;
  }

  .react-time-picker__inputGroup__divider {
    height: auto !important;
    margin-top: -3px;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .react-time-picker {
    width: 100%;
    input,
    select {
      all: unset;
    }
    select {
      text-align-last: right !important;
      border-radius: 0px;
    }
  }

  .react-time-picker__wrapper {
    width: 100% !important;
    background: #f6f6f6;
    border-radius: 8px !important;
    border: none;
    padding: 1rem 1.125rem;
    height: 50px;
    font-size: 0.875rem;

    input,
    select {
      border-radius: 0px !important;
    }
  }

  .cust-check {
    input {
      &:checked {
        background-color: #c3cbc2;
        border-color: transparent;
      }
    }
  }
  .cust-select {
    > div {
      border: 1px solid #ced4da;
      box-shadow: none;
      &:focus,
      &:hover {
        box-shadow: 0 0 0 0.125rem #c3cbc2 !important;
      }
    }
  }
  select {
    padding: 1rem 1.1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background: #f6f6f6;
    border-radius: 0.5rem !important;
    font-size: 0.9rem;
    font-family: 'Didact Gothic';
    font-size: 0.95rem;
    color: #000;
  }
  .error {
    color: red;
  }
`;

const Wrapper = styled.div`
  .title {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 330;
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    color: #000000;
  }
  .vacation-note {
    font-family: 'Didact Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    opacity: 0.6;
  }
  .delete-icon {
    width: 1.2rem;
    margin-right: 0.25rem;
  }
`;

const EditVacation = ({ isOpen, hideModal, vacation, refetch }) => {
  const [vacationForm, setVacationForm] = useState({});
  const [error, setError] = useState({});
  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setVacationForm((form) => {
      return { ...form, [name]: value };
    });
  };

  const validateVacation = () => {
    let flag = true;
    const errorInfo = {};
    if (!vacationForm.note) {
      errorInfo.note = 'Note is required.';
      flag = false;
    }
    if (!vacationForm.startDate) {
      errorInfo.startDate = 'Start date is required.';
      flag = false;
    }

    if (!vacationForm.endDate) {
      errorInfo.endDate = 'End date is required.';
      flag = false;
    }

    if (!vacationForm.startTime) {
      errorInfo.startTime = 'Start time is required.';
      flag = false;
    }

    if (!vacationForm.endTime) {
      errorInfo.endTime = 'End time is required.';
      flag = false;
    }

    if (vacationForm.startDate && vacationForm.endDate) {
      const start = moment(vacationForm.startDate);
      const end = moment(vacationForm.endDate);
      if (end.isBefore(start, undefined, '()')) {
        errorInfo.endDate = 'End date must be grater than start date';
        flag = false;
      }
    }

    if (
      vacationForm.startDate &&
      vacationForm.endDate &&
      vacationForm.startTime &&
      vacationForm.endTime &&
      flag
    ) {
      const start = moment(vacationForm.startDate).format('DD-MM-YYYY');
      const startDate = moment(`${start} ${vacationForm.startTime}`, 'DD-MM-YYYY HH:mm').tz(
        'America/New_York',
        true
      );

      const end = moment(vacationForm.endDate).format('DD-MM-YYYY');
      const endDate = moment(`${end} ${vacationForm.endTime}`, 'DD-MM-YYYY HH:mm').tz(
        'America/New_York',
        true
      );
      if (endDate.isBefore(startDate.add(1, 'hour'))) {
        errorInfo.endTime = 'End time must be atleast 1 hour grater than start time';
        flag = false;
      }
    }

    setError(errorInfo);
    return flag;
  };

  const handleSave = async () => {
    if (validateVacation()) {
      // save
      setIsLoading(true);
      const start = moment(vacationForm.startDate).format('DD-MM-YYYY');
      const startDate = moment(`${start} ${vacationForm.startTime}`, 'DD-MM-YYYY HH:mm')
        .tz('America/New_York', true)
        .format();
      const end = moment(vacationForm.endDate).format('DD-MM-YYYY');
      const endDate = moment(`${end} ${vacationForm.endTime}`, 'DD-MM-YYYY HH:mm')
        .tz('America/New_York', true)
        .format();
      const object = {
        note: vacationForm.note,
        startDate,
        endDate
      };
      if (!vacationForm.id) {
        profile
          .addVacation(object)
          .then(() => {
            toast.success('Unavailability/vacation submitted successfully.');
            setIsLoading(false);
            hideModal();

            refetch();
          })
          .catch((err) => {
            setIsLoading(false);

            toast.error(err.response.data?.message || 'Problem in saving vacation');
          });
      } else {
        profile
          .editVacation({ ...object, id: vacationForm.id }, vacationForm.id)
          .then(() => {
            toast.success('Vacation updated successfully');
            setIsLoading(false);
            hideModal();
            refetch();
          })
          .catch((err) => {
            setIsLoading(false);

            toast.error(err.response.data?.message || 'Problem in saving vacation');
          });
      }
    }
  };

  // const handleAddVacation = () => {
  //   setVacationForm((list) => {
  //     return [...list, {}];
  //   });
  // };
  // const handleRemoveVacation = (index) => {
  //   // onDelete();
  //   setVacationForm((list) => {
  //     updateDeletedVacation(list[index]?.id);
  //     list.splice(index, 1);
  //     return [...list];
  //   });
  // };
  useEffect(() => {
    if (vacation) {
      setVacationForm({
        ...vacation,
        startTime: moment(vacation?.startDate).tz('America/New_York').format('HH:mm'),
        endTime: moment(vacation?.endDate).tz('America/New_York').format('HH:mm'),
        startDate: moment(vacation?.startDate).tz('America/New_York').format('YYYY-MM-DD'),
        endDate: moment(vacation?.endDate).tz('America/New_York').format('YYYY-MM-DD')
      });
    } else {
      setVacationForm({});
      setError({});
    }
  }, [vacation]);

  // useEffect(() => {
  //   if (vacationData && vacationData.length) {
  //     const list = vacationData.map((vacation) => ({
  //       ...vacation,
  //       startTime: moment(vacation?.startDate).tz('America/New_York').format('HH:mm'),
  //       endTime: moment(vacation?.endDate).tz('America/New_York').format('HH:mm')
  //     }));
  //     setVacationForm([...list]);
  //   }
  // }, [vacationData]);

  return (
    <ModalWrapper
      className={`${isOpen ? 'blurred' : ''} completeProfileModal endSession mt-4`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={isOpen}
      onHide={hideModal}>
      <CloseButton
        onClick={() => {
          setVacationForm({});
          setError({});
          hideModal();
        }}
        className="close_btn"
        style={{ background: `url(${modalClose})` }}
      />
      <Modal.Body>
        <Card>
          <Wrapper>
            <div>
              <span className="title">UNAVAILABILITY/VACATION</span>
            </div>
            <p className="vacation-note">
              Please share your upcoming unavailability (planned vacation, doctor's appointment,
              etc.) below.
            </p>

            <div className="my-2">
              <Row>
                <Col md="12">
                  <Form.Group className="mb-3 styled_input">
                    <div className="d-flex justify-content-between">
                      <Form.Label>Unavailability Type</Form.Label>
                    </div>
                    <Form.Control
                      type="text"
                      name="note"
                      placeholder="Enter unavailability type"
                      value={vacationForm.note}
                      onChange={(e) => handleChange(e)}
                    />
                    {error.note && <Form.Text className="error">{error.note}</Form.Text>}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-3 styled_input">
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="startDate"
                      onChange={(e) => handleChange(e)}
                      defaultValue={
                        vacationForm?.startDate
                          ? moment(vacationForm?.startDate).format('YYYY-MM-DD')
                          : ''
                      }
                      min={moment().tz('America/New_York').format('YYYY-MM-DD')}
                    />
                    {error.startDate && <Form.Text className="error">{error.startDate}</Form.Text>}
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group className="mb-3 styled_input">
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="endDate"
                      onChange={(e) => handleChange(e)}
                      defaultValue={
                        vacationForm?.endDate
                          ? moment(vacationForm?.endDate).format('YYYY-MM-DD')
                          : ''
                      }
                      min={moment().tz('America/New_York').format('YYYY-MM-DD')}
                    />
                    {error.endDate && <Form.Text className="error">{error.endDate}</Form.Text>}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <Form.Group className="mb-3 styled_input">
                    <Form.Label>Start Time</Form.Label>
                    <Form.Control
                      type="time"
                      name="startTime"
                      onChange={(e) => handleChange(e)}
                      value={vacationForm.startTime}
                    />
                    {error.startTime && <Form.Text className="error">{error.startTime}</Form.Text>}
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group className="mb-3 styled_input">
                    <Form.Label>End Time</Form.Label>
                    <Form.Control
                      type="time"
                      name="endTime"
                      value={vacationForm.endTime}
                      onChange={(e) => handleChange(e)}
                    />
                    {error.endTime && <Form.Text className="error">{error.endTime}</Form.Text>}
                  </Form.Group>
                </Col>
              </Row>
              <div>
                <Button
                  onClick={handleSave}
                  variant="primary"
                  className="less-padding btn-primary"
                  disabled={isLoading}>
                  {isLoading ? <Spinner animation="border" size="sm" /> : 'Save'}
                </Button>
              </div>
            </div>
          </Wrapper>
        </Card>
      </Modal.Body>
    </ModalWrapper>
  );
};

export default EditVacation;
