/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useAuth } from 'helpers/contexts/AuthContext';
import UnauthorizedHeader from './UnauthorizedHeader';
import AuthorizedHeader from './AuthorizedHeader';
import { useNavigate } from 'react-router';

export default function Header({ dark }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!user) {
  //     navigate('/login');
  //   } else {
  //     if (location.pathname === '/') {
  //       navigate('/dashboard');
  //     }
  //   }
  // }, [user, location.pathname]);

  if (!user) {
    return null;
  }

  return <AuthorizedHeader dark={dark} />;
}
