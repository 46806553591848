export default function formatPhoneNumber(phoneNumberString) {
  if (!phoneNumberString) {
    return '';
  }
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '+1 (' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return '+1 ' + phoneNumberString;
}
