import { createGlobalStyle } from 'styled-components';
const CssUtils = createGlobalStyle`
    .ff-didact {
    font-family: 'Didact Gothic', sans-serif;
    }
    .ff-josephin {
        font-family: 'Josefin Sans', sans-serif;
    }
    .fw-300 {
        font-weight: 300;
    }
    .fw-400 {
        font-weight: 400;
    }
    .fw-500 {
        font-weight: 500;
    }
    .fw-600 {
        font-weight: 600;
    }
    .fw-700 {
        font-weight: 700;
    }
    .close-button {
        position: absolute;
        right: 0; 
        top: -36px;
        cursor: pointer;
    }
    .w-fit-content {
        width: fit-content !important;
    }
    .place-items-center {
        place-items: center !important;
    }
    .overflow-hidden {
        overflow: hidden !important;
    }
    .no-pointer-events {
        pointer-events: none;
    }
    .form-control:disabled {
        border: 1px solid #CBC8C9;
        border-radius: 8px;
        background: white;
    }
    select:disabled {
        border: 1px solid #CBC8C9;
        border-radius: 8px;
        background: white !important;
    }
    .less-padding {
        padding: 0.75rem 2rem !important; 
    }
    .close_btn{
        position:absolute;
        right: 0;
        top: -2rem;
    }
    .opacity-07 {
        opacity: 0.7 !important;
    }
    .error {
        color: #dc3545;
    }
    .fs-7 {
        font-size: 0.7rem !important;
    }
    .border-radius-100 {
        border-radius: 100% !important;
    }
    .bg-none {
        background: transparent !important;
    }
    .border-top-none {
        border-top: none !important;
    }
    .wv-100 {
        width: 100vw !important;
    }
    .position-absolute {
        position: absolute !important;
    }
    .left-0 {
        left: 0 !important;
    }
    .display-none {
        display: none !important;
    }
    .gray {
        color: #868686;
    }
    .rounded {
        border-radius: 100% !important;
    }
    .min-width-130px {
        min-width: 130px !important;
    }
    .red-color {
        color: #dc3545;
    }
    .flex-1 {
        flex: 1;
    }
`;
export default CssUtils;
