import { Container, Navbar, Nav, DropdownButton, Dropdown, Image } from 'react-bootstrap';
import { ReactComponent as Logo } from 'assets/Images/logo.svg';
import { ReactComponent as LogoDark } from 'assets/Images/logo_dark.svg';
import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';
import React from 'react';
import { useAuth } from 'helpers/contexts/AuthContext';
import { Wrapper } from './header.styled';
import defaultUser from 'assets/icons/default-user.svg';
import { profile } from 'helpers/http';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

export default function AuthorizedHeader({ dark }) {
  const [dropdown, setDropdown] = React.useState(false);
  const { logout } = useAuth();
  const { data } = useQuery('sitterProfile', profile.getProfile, {
    refetchOnWindowFocus: false
  });

  return (
    <Wrapper>
      <Navbar className="navbar mt-3" collapseOnSelect expand="lg">
        <Container>
          <Link to="/">
            <div className="logo">{dark ? <LogoDark /> : <Logo />}</div>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className={dark ? 'dark links' : 'links'}>
            <Nav>
              <DropdownButton
                id="dropdown-basic-button"
                title={
                  <div
                    className="dropdown-profile text-capitalize scale-animation"
                    onClick={() => setDropdown(!dropdown)}>
                    <Image
                      src={data?.data?.photo || defaultUser}
                      style={{
                        width: 24,
                        height: 24,
                        margin: '0.5rem',
                        marginRight: '0.625rem',
                        borderRadius: 100
                      }}
                      className="user-image"
                    />
                    {data?.data?.firstName}{' '}
                    <Chevron className={dropdown ? 'transform-chevron' : ''} />
                  </div>
                }>
                <Dropdown.Item href="/dashboard">Dashboard</Dropdown.Item>
                <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                <Dropdown.Item href="/past-sessions">Past Sessions</Dropdown.Item>
                <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
              </DropdownButton>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Wrapper>
  );
}
