import React from 'react';
import { Modal, CloseButton } from 'react-bootstrap';
// import { StyledPastSessionDetailModal } from './SitterDash.Styled';
import modalClose from 'assets/Images/modalClose.svg';
import NoDataUI from 'components/NoDataUI';
import styled from 'styled-components';

const BodyWrapper = styled(Modal.Body)`
  .proof-image {
    width: 85px;
    height: 85px;
    border-radius: 0.25rem;
  }
`;

function PastSessionDetailModal({ isOpen, hideModal, expenses }) {
  const total = expenses.eveningMealExpenseAmount + expenses.transportExpenseAmount;

  return (
    <>
      <Modal
        className={`${isOpen ? 'blurred' : ''} completeProfileModal sessionDetail`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={isOpen}
        onHide={hideModal}>
        <CloseButton
          onClick={() => hideModal()}
          className="close_btn"
          style={{ background: `url(${modalClose})` }}
        />
        <BodyWrapper>
          <h4>{expenses.lastName} family session expenses</h4>
          {!total ? <NoDataUI notFoundText="No expenses found" boxCount /> : null}
          {expenses.eveningMealExpenseAmount ? (
            <div>
              <h5>Meal Expense</h5>
              <div>
                <div>
                  <span>Proof</span>
                  <img className="proof-image" src={expenses.eveningMealExpenseProofUrl} alt="" />
                </div>
                <div>
                  <span>Expense Notes</span>
                  <p>{expenses.eveningMealExpenseNote}</p>
                  <span>Value</span>
                  <p>${expenses.eveningMealExpenseAmount}</p>
                </div>
              </div>
            </div>
          ) : null}
          {expenses.transportExpenseAmount ? (
            <div>
              <h5>Travel Expense</h5>
              <div>
                <div>
                  <span>Proof</span>
                  <img
                    className="proof-image"
                    src={expenses.transportExpenseProofUrl}
                    alt="transport-bill"
                  />
                </div>
                <div>
                  <span>Expense Notes</span>
                  <p>{expenses.transportExpenseNote}</p>
                  <span>Value</span>
                  <p>${expenses.transportExpenseAmount}</p>
                </div>
              </div>
            </div>
          ) : null}
        </BodyWrapper>
      </Modal>
    </>
  );
}

export default PastSessionDetailModal;
