import client from './client';

export default {
  getProfile: () => client.get('/get-profile').then((res) => res.data),
  getEmergencyContact: () => client.get('/get-emergency-contact').then((res) => res.data),
  getAvailability: () => client.get('/get-availability').then((res) => res.data),
  saveProfile: (data) => client.put('/save-profile', data).then((res) => res.data),
  changePassword: (data) => client.put('/change-password', data).then((res) => res.data),
  listVacations: () => client.post('/list-vacations').then((res) => res.data),
  addVacation: (data) => client.post('/add-vacation', data).then((res) => res.data),
  editVacation: (data, id) => client.put(`/edit-vacation/${id}`, data).then((res) => res.data),
  deleteVacation: (vacationId) =>
    client.delete(`/delete-vacation/${vacationId}`).then((res) => res.data),
  connectGoogleCalendar: () => client.get('/get-google-auth-url').then((res) => res.data),
  authenticateGoogle: (body) => client.post('/authenticate-google', body).then((res) => res.data),
  checkGoogleAuth: () => client.get('/check-google-auth').then((res) => res.data),
  revokeGoogleAuth: () => client.post('/revoke-google-auth').then((res) => res.data),
  signDocument: (body) => client.post('/sign-document', body).then((res) => res.data)
};
