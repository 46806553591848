/* eslint-disable no-unused-vars */
import React from 'react';
import { Button } from 'react-bootstrap';
import { common } from 'helpers/http';
import moment from 'moment';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import { toast } from 'react-hot-toast';

export default function Policy({ data }) {
  /**
   * Download signed document
   */
  const downloadSignedDocument = () => {
    if (data?.data?.signedPolicyFilename) {
      const filename = data?.data?.signedPolicyFilename.split('.com/').reverse()[0];

      const toastId = toast.loading('Downloading...');

      common.getDownloadUrl({ filename }).then((res) => {
        common.gets3BlobData(res.data).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}`);
          document.body.appendChild(link);
          link.click();

          toast.success('Download successful!', {
            id: toastId
          });
        });
      });
    }
  };

  return (
    <>
      {data?.data?.isPolicySigned ? (
        <div className="googleCalendar">
          <h3>ATS Policy</h3>
          <div className="d-flex justify-content-between align-items-baseline my-1">
            <div className="d-grid">
              <span className="gray mt-2">
                ATS Policy signed{' '}
                <b>{moment.utc(data?.data?.policySignedDate).format('MMM DD, YYYY')}</b>
              </span>
            </div>
            <Button
              variant="white"
              className="less-padding scale-animation white-shadow-button mt-1"
              onClick={downloadSignedDocument}>
              <Download
                role="button"
                style={{ width: '22px', height: '22px', marginRight: '3px' }}
              />
              Download
            </Button>
          </div>{' '}
        </div>
      ) : (
        ''
      )}
    </>
  );
}
