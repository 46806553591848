import React from 'react';
import { StyledButton } from './UI.styled';

function ButtonUI(props) {
  return (
    <StyledButton
      onClick={props.onClick}
      type={props.type}
      className={`scale-animation ${props.className}`}>
      {props.children}
    </StyledButton>
  );
}

export default ButtonUI;
