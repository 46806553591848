import { Form, Button } from 'react-bootstrap';
import React, { useState } from 'react';
import { validateNumericKey } from 'helpers/utils/misc';

export default function StepFour({ back, submit }) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      submit({
        isProfileCompleted: 'y',
        emergencyContact: {
          name: e.target?.name?.value,
          phone: e.target?.phone?.value,
          email: e.target?.email?.value,
          relation: e.target?.relation?.value
        }
      });
    }
    setValidated(true);
  };

  return (
    <>
      <h3>Complete your Anytime Sitter profile (4/4)</h3>
      <p className="h2">EMERGENCY CONTACT INFORMATION</p>
      <Form className="registration-form" noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3 styled_input" controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" placeholder="Enter name of emergency contact" required />
          <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 styled_input" controlId="phone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            format="(###) ###-####"
            type="text"
            placeholder="Enter phone number of emergency contact"
            required
            onKeyPress={validateNumericKey}
          />
          <Form.Control.Feedback type="invalid">Phone number is required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 styled_input" controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
            placeholder="Enter email address of emergency contact"
            required
          />
          <Form.Control.Feedback type="invalid">Email address is required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 styled_input" controlId="relation">
          <Form.Label>Relation</Form.Label>
          <Form.Control
            type="text"
            placeholder="What is the relation to your emergency contact?"
            required
          />
          <Form.Control.Feedback type="invalid">Relation is required</Form.Control.Feedback>
        </Form.Group>
        <div className="float-end d-flex">
          <Button variant="alt" onClick={() => back()} className="me-3 scale-animation">
            Back
          </Button>
          <Button className="scale-animation" variant="primary" type="submit">
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
}
