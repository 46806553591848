import styled from 'styled-components';
import { Button } from 'react-bootstrap';

export const StyledButton = styled(Button)`
  background-color: #c3cbc2;
  border: none;
  border-radius: 10rem;
  padding: 0.8rem 1.9rem;
  font-size: 20px;
  color: #000;
  transition: all 0.2s ease;

  &:active,
  &:focus,
  &:hover,
  &:active:focus {
    box-shadow: none;
    background: #c3cbc2;
    color: #000;
    transform: scale(1.05);
  }
`;

export const CardUI = styled.div`
  border-radius: 1rem;
  background-color: #fff;
  border-radius: 1rem;
  padding: 1rem;

  @media screen and (max-width: 767px) {
    padding: 0.5rem;
  }
`;
