import client from './client';

export default {
  sessions: (data) => client.post('/list-session', data).then((res) => res.data),
  editSession: ({ status, id }) =>
    client.put(`/edit-session/${id}`, { status: status }).then((res) => res.data),
  endSession: (data) => client.put(`/end-session/${data?.sessionId}`, data).then((res) => res.data),
  getSession: (id) => client.get(`/get-session/${id}`).then((res) => res.data),
  getFamily: (id) => client.get(`/get-family/${id}`).then((res) => res.data),
  getNotes: (id) =>
    client.get(`/list-session-notes/${id}?createdBy=admin`, {}).then((res) => res.data),
  getFamilyNotes: (id) =>
    client.get(`/list-session-notes/${id}?createdBy=family`, {}).then((res) => res.data)
};
