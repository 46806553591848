import React from 'react';
import { Container } from 'react-bootstrap';
import SmallContainer from '../../components/layout/CustomContainer';
import { StyledSitterProfile } from './SitterProfile.styled';
import SitterPersonalInfo from './SitterPersonalInfo';
import { BiArrowBack } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import {
  SitterAbout,
  ATSrequirements,
  SitterAvailability,
  SitterAllergies,
  SitterEmergencyContact
} from './SitterProfileComponents';
import { useQuery } from 'react-query';
import { profile } from 'helpers/http/index';
import { Helmet } from 'react-helmet';
import GoogleCalendar from './GoogleCalendar';
import Policy from './Policy';

function SitterProfile() {
  const { data, isLoading } = useQuery('sitterProfile', profile.getProfile);

  return (
    <>
      <Helmet>
        <title>Sitter profile</title>
      </Helmet>
      <StyledSitterProfile className="my-5">
        <Container className="bg-white py-4">
          <SmallContainer className="smContainer">
            <Link to="/">
              <a className="my-4 d-inline-block  scale-animation">
                <BiArrowBack className="mx-1 backArrow" /> Back
              </a>
            </Link>
            <SitterPersonalInfo isLoading={isLoading} data={data} />
            <div
              className="sitterOtherInfo mt-4"
              style={{ columnCount: '2', columnFill: 'balance' }}>
              <SitterAbout isLoading={isLoading} data={data} />
              <SitterAllergies isLoading={isLoading} data={data} />
              <SitterEmergencyContact />
              <GoogleCalendar />
              <ATSrequirements />
              <SitterAvailability />
              <Policy data={data} />
            </div>
          </SmallContainer>
        </Container>
      </StyledSitterProfile>
    </>
  );
}

export default SitterProfile;
