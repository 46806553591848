import React from 'react';
import { CardUI } from './UI.styled';

function Card(props) {
  return (
    <>
      <CardUI {...props} className={`${props.Card} ${props.className}`}>
        {props.children}
      </CardUI>
    </>
  );
}

export default Card;
