import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const SignatureContainer = styled(Modal)`
  background: rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(4px);

  .modal-content {
    border-radius: 16px;
    padding: 1rem;
  }

  .modal-h1 {
    font-size: 1.25rem;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
  }

  p {
    font-family: 'Montserrat', sans-serif;
  }

  .float-left {
    float: left;
  }

  .nav-tabs {
    border: 0;
    border-bottom: 1px solid #eee;

    .nav-link {
      color: #9e9e9e;
      border: 0;

      &.active {
        color: #495057;
        border: 0;
        border-bottom: 2px solid rgb(195, 203, 194) !important;
        box-shadow: none;
      }

      &:hover,
      &:focus {
        border: 0;
        isolation: unset;
        box-shadow: none;
      }
    }
  }

  .mde-policy-modal {
    padding: 1rem;

    .sigCanvas {
      border-radius: 12px;
      width: 100%;
      border-width: 1px;
      border-radius: 2px;
      border-color: rgb(238, 238, 238);
      border-style: solid;
      background-color: rgb(250, 250, 250);
      height: 200px;
    }

    .modal-ft-btn {
      padding: 0.5rem 1.5rem;
    }

    h1 {
      margin-bottom: 0.5rem;
    }

    .dropzone {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      border-width: 2px;
      border-radius: 2px;
      border-color: #eeeeee;
      border-style: dashed;
      background-color: #fafafa;
      color: #bdbdbd;
      outline: none;
      transition: border 0.24s ease-in-out;
      margin-bottom: 0.5rem;
      height: 200px;

      .sign-img {
        width: 150px;
        padding: 0.5rem 1rem;
        background: rgb(250, 250, 250);
        border-width: 1px;
        border-radius: 2px;
        border-color: rgb(238, 238, 238);
        border-style: solid;
        margin-left: 12px;
      }
    }
    .btn-alt {
      box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 25px;
      background-color: rgb(195, 203, 194);
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.5rem;

      &:hover {
        background: rgb(92, 92, 92);
        border: 1px solid rgb(92, 92, 92);
        color: white;
      }

      &:disabled {
        color: black;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .modal-content {
      padding: 0.5rem;
    }

    button[type='submit'] {
      padding: 0.5rem 1.5rem;
      font-size: 1rem;
    }
  }
`;

export const TableWrapper = styled.div`
  .table {
    border: 1px solid rgba(0, 0, 0, 0.12);

    thead tr {
      width: 100%;
      min-height: 52px;
      border: none;
      font-family: 'Josefin Sans', sans-serif;
      border-radius: 8px;
      background: rgb(247, 247, 247);
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);

      th {
        box-sizing: border-box;
        line-height: normal;
        font-weight: 500;
        padding: 1rem;
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.87);
      }
    }

    thead th:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    tbody {
      border: none;
      tr {
        width: 100%;
        min-height: 52px;
        border: none;
        border-radius: 8px;
        background: rgb(255, 255, 255);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);

        &:hover {
          background-color: rgb(250, 250, 250);
        }

        td {
          box-sizing: border-box;
          line-height: normal;
          font-weight: 300;
          padding: 1rem;
          font-size: 1rem;
          font-family: 'Didact Gothic', sans-serif;
          color: rgba(0, 0, 0, 0.87);
          background-color: rgb(255, 255, 255) !important;
        }
      }
    }

    tbody td:first-child {
      border-right: 1px solid rgba(0, 0, 0, 0.12);
    }
  }
`;
