import React, { useState } from 'react';
import { LoginForm } from './Auth.styled';
import { Container, Row, Form, Spinner } from 'react-bootstrap';
import Card from '../../components/UI/Card.jsx';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from 'helpers/contexts/AuthContext';
import { FAMILY_PORTAL_URL } from 'helpers/utils/consts';
import { Helmet } from 'react-helmet';
import UnauthorizedHeader from 'components/menu/UnauthorizedHeader';

function Login() {
  const [validated, setValidated] = useState(false);
  const { login, isLoading } = useAuth();
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      login({
        email: e.target.email.value,
        password: e.target.password.value
      });
    }
    setValidated(true);
  };
  return (
    <>
      <Helmet>
        <title>MDE Sitter Login</title>
      </Helmet>
      <LoginForm className="d-flex flex-column">
        <UnauthorizedHeader dark />
        <Container>
          <Row className="p-2 d-block">
            <Card className="login_Card">
              <div className="login_wrapper">
                <h3 className="lead text-uppercase mb-4">Anytime Sitter Login</h3>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form.Group className="mb-3 styled_input" controlId="email">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                      placeholder="Enter Email Address"
                      required
                    />
                    <Form.Control.Feedback type="invalid">Email is required.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3 styled_input" controlId="password">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      maxLength={16}
                      placeholder="Enter Password"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Password is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3 d-flex justify-content-end reset_pass styled_input"
                    controlId="formBasicCheckbox">
                    {/* <Form.Check type="checkbox" label="Remember password" /> */}
                    <Link to="/forgot-password" className="mt-3 d-inline-block">
                      Forgot password?
                    </Link>
                  </Form.Group>
                  <div className="text-end mt-4 mt-md-5 ">
                    <Button
                      type="submit"
                      variant="primary"
                      disabled={isLoading}
                      className=" scale-animation">
                      {isLoading ? <Spinner animation="border" size="sm" /> : 'Log In'}
                    </Button>
                  </div>
                </Form>
              </div>
            </Card>
            <div className="loginAs_family">
              {/* eslint-disable-next-line no-undef */}
              <a href={FAMILY_PORTAL_URL}>Log in as a family</a>
            </div>
          </Row>
        </Container>
      </LoginForm>
    </>
  );
}

export default Login;
