import React from 'react';
import { Container, Row, Spinner } from 'react-bootstrap';
import { StyledDash } from './SitterDash.Styled';
import SitterSession from './SitterSession';
import UpcomingSession from './UpcomingSession';
import RequestSitter from './RequestSitter';
import { useQuery } from 'react-query';
import OverdueSession from './OverdueSession';
import { dashboard } from 'helpers/http';
import { Helmet } from 'react-helmet';
import { useAuth } from 'helpers/contexts/AuthContext';

function SitterDashboard() {
  const { userInfo, userInfoLoading } = useAuth();
  // Upcoming sessions
  const {
    data: upcomingSessionData,
    isLoading: upcomingSessionLoading,
    refetch: upcomingSessionRefetch
  } = useQuery(
    'upcomingSessions',
    () =>
      dashboard.sessions({
        oFilterBy: {
          status: 'upcoming'
        },
        sortBy: 'sessionStart',
        sortOrder: 'ascend'
      }),
    {
      refetchOnWindowFocus: true,
      refetchInterval: 10000
    }
  );

  // Requested sessions
  const {
    data: requestedSessionData,
    isLoading: requestedSessionLoading,
    refetch: requestedSessionRefetch
  } = useQuery(
    'pendingSessions',
    () =>
      dashboard.sessions({
        oFilterBy: {
          status: 'approved'
        }
      }),
    {
      refetchOnWindowFocus: true,
      refetchInterval: 10000
    }
  );

  return (
    <>
      <Helmet>
        <title>Sitter Dashboard</title>
      </Helmet>
      <StyledDash>
        <Container>
          {userInfoLoading ? (
            <div className="d-flex align-items-center">
              <h1 className="my-4 text-capitalize">Hello, </h1>
              <Spinner className="mx-3 mt-2" animation="border" size="sm" />
            </div>
          ) : (
            <h1 className="my-4 text-capitalize">Hello, {userInfo?.firstName}!</h1>
          )}
          <OverdueSession />
          <SitterSession />
          <Row>
            <div className="col-lg-6 same-sess">
              <UpcomingSession
                data={upcomingSessionData}
                isLoading={upcomingSessionLoading}
                refetch={upcomingSessionRefetch}
              />
            </div>
            <div className="col-lg-6 same-sess">
              <RequestSitter
                data={requestedSessionData}
                isLoading={requestedSessionLoading}
                refetch={requestedSessionRefetch}
                refetchUpcoming={upcomingSessionRefetch}
              />
            </div>
          </Row>
        </Container>
      </StyledDash>
    </>
  );
}

export default SitterDashboard;
