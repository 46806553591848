import React from 'react';
import styled from 'styled-components';
import { ReactComponent as EmptyBox } from 'assets/Images/emptyBox.svg';

const NoDataFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 2rem 0;

  div.boxCount {
    position: relative;
    left: 2rem;
    display: block;
    margin: 0.6rem 0;
  }

  svg {
    height: 50px !important;
    width: 232px !important;
  }

  svg.boxCount {
    display: block;
    position: relative;
    left: -1.8rem;
  }

  p {
    font-family: 'Didact Gothic';
    font-weight: 400;
    color: #000000;
    font-size: 1rem !important;
    opacity: 0.7;
  }
`;

function NoDataUI({ boxCount, notFoundText }) {
  return (
    <NoDataFound>
      <EmptyBox />
      <div className={boxCount ? 'boxCount' : 'd-none'}>
        <EmptyBox />
      </div>
      <EmptyBox className={boxCount ? 'boxCount' : 'd-none'} />
      <p className={boxCount ? 'mt-4' : 'mt-3'}>{notFoundText || 'No data found'}</p>
    </NoDataFound>
  );
}

export default NoDataUI;
