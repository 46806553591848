import React from 'react';
import { Container, Image, Spinner } from 'react-bootstrap';
import SmallContainer from '../../components/layout/CustomContainer';
import { SitterFamView, StyledSitterProfile } from './SitterProfile.styled';
import { StyledSitterPersonalInfo } from './SitterProfile.styled';
import { BiArrowBack } from 'react-icons/bi';
import smsicon from 'assets/Images/sms.svg';
import phoneicon from 'assets/Images/call.svg';
import locationicon from 'assets/Images/location.svg';
import { CardUI } from 'components/UI/UI.styled';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import { dashboard } from 'helpers/http';
import { Link } from 'react-router-dom';
import default_family_icon from 'assets/icons/default-family.svg';
import default_child_icon from 'assets/icons/default-child.svg';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import formatPhoneNumber from 'helpers/utils/formatPhoneNumber';

function FamilySitterView() {
  const { id } = useParams();
  const { data, isLoading } = useQuery('family', () => dashboard.getFamily(id), {
    refetchOnWindowFocus: false
  });

  if (isLoading) {
    return (
      <StyledSitterProfile>
        <Container className="bg-white py-4">
          <SmallContainer>
            <SitterFamView>
              <div className="d-flex justify-content-between famViewBack align-items-center">
                <Link to="/dashboard" className="my-4 d-inline-block">
                  <BiArrowBack /> Back
                </Link>
                {/* <a href="#">
                  <span>
                    <img src={FavouriteIcon} alt="" />
                  </span>{' '}
                  Add to favorites
                </a> */}
              </div>
              <div className="text-center my-5">
                <Spinner animation="border" size="md" />
              </div>
            </SitterFamView>
          </SmallContainer>
        </Container>
      </StyledSitterProfile>
    );
  }

  return (
    <>
      <Helmet>
        <title>Sitter family view</title>
      </Helmet>
      <StyledSitterProfile>
        <Container className="bg-white py-4">
          <SmallContainer>
            <SitterFamView>
              <div className="d-flex justify-content-between famViewBack align-items-center">
                <a href="/dashboard" className="my-4 d-inline-block">
                  <BiArrowBack /> Back
                </a>
                {/* <a href="#">
                <span>
                  <img src={FavouriteIcon} alt="" />
                </span>{' '}
                Add to favorites
              </a> */}
              </div>
              <StyledSitterPersonalInfo className="position-relative overflow-hidden">
                <div className="famParent">
                  <h6>Parent 1</h6>
                  <h4 className="word-break-word">
                    {data?.data?.parents?.[0]?.firstName + ' ' + data?.data?.parents?.[0]?.lastName}
                  </h4>
                  <ul className="list-unstyled">
                    <li>
                      <img src={smsicon} alt="" />{' '}
                      <a
                        className="word-break-word"
                        href={`mailto:${data?.data?.parents?.[0]?.email}`}>
                        {data?.data?.parents?.[0]?.email}
                      </a>
                    </li>
                    <li>
                      <img src={phoneicon} alt="" />
                      <a
                        className="word-break-word"
                        href={`tel:+1${data?.data?.parents?.[0]?.phone}`}>
                        {formatPhoneNumber(data?.data?.parents?.[0]?.phone)}
                      </a>
                    </li>
                    <li>
                      <img src={locationicon} alt="" />{' '}
                      <a className="word-break-word" href="#">
                        {data?.data?.parents?.[0]?.address}
                      </a>
                    </li>
                  </ul>
                </div>
                <hr className="centeredLine m-centeredLine" />
                <span className="bodered centeredLine">
                  <Image
                    src={data?.data?.familyPhoto || default_family_icon}
                    alt="family-photo"
                    width={98}
                    height={98}
                    roundedCircle
                    className="family-image"
                  />
                </span>
                {data?.data?.parents?.[1] && (
                  <div className="famParent">
                    <h6>Parent 2</h6>
                    <h4 className="word-break-word">
                      {data?.data?.parents?.[1]?.firstName +
                        ' ' +
                        data?.data?.parents?.[1]?.lastName}
                    </h4>
                    <ul className="list-unstyled">
                      <li>
                        <img src={smsicon} alt="" />{' '}
                        <a
                          className="word-break-word"
                          href={`mailto:${data?.data?.parents?.[1]?.email}`}>
                          {data?.data?.parents?.[1]?.email}
                        </a>
                      </li>
                      <li>
                        <img src={phoneicon} alt="" />
                        <a
                          className="word-break-word"
                          href={`tel:${data?.data?.parents?.[1]?.phone}`}>
                          {formatPhoneNumber(data?.data?.parents?.[1]?.phone)}
                        </a>
                      </li>
                      <li>
                        <img src={locationicon} alt="" />{' '}
                        <a href="#" className="word-break-word">
                          {data?.data?.parents?.[1]?.address}
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </StyledSitterPersonalInfo>
              <div
                className="sitterOtherInfo mt-4"
                style={{ columnCount: '2', columnFill: 'balance' }}>
                <CardUI>
                  <h3>Child information</h3>
                  {data?.data?.children?.map((child, index) => (
                    <div key={index}>
                      <p className="mt-4">Child {index + 1}</p>
                      <div className="d-md-flex">
                        <div>
                          <Image
                            src={child?.photo || default_child_icon}
                            alt="child photo"
                            height={71}
                            width={71}
                            roundedCircle
                            className="child-image"
                          />
                        </div>
                        <div className="ms-3">
                          <table>
                            <tbody>
                              <tr>
                                <td className="cell-label text-nowrap">Name:</td>
                                <td className="word-break-word">
                                  {child?.firstName + ' ' + child?.lastName}
                                </td>
                              </tr>
                              <tr>
                                <td className="cell-label text-nowrap">Date of Birth:</td>
                                <td className="word-break-word">
                                  {moment.utc(child?.dob).format('MMM DD, YYYY')}
                                </td>
                              </tr>
                              <tr>
                                <td className="cell-label text-nowrap">Allergies:</td>
                                <td className="word-break-word">{child?.allergies?.join(', ')} </td>
                              </tr>
                              <tr>
                                <td className="cell-label text-nowrap">Food Preferences:</td>
                                <td className="word-break-word">
                                  {child?.foodPreferences?.join(', ')}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </CardUI>

                <CardUI>
                  <h3 className="text-nowrap">additional family information</h3>
                  <p>{data?.data?.additionalFamilyInfo}</p>
                </CardUI>
                <CardUI>
                  <h3>Emergency Contact Information</h3>
                  <table>
                    <tbody>
                      <tr>
                        <td className="text-nowrap">Name:</td>
                        <td className="word-break-word">{data?.data?.emergencyContact?.name} </td>
                      </tr>
                      <tr>
                        <td className="text-nowrap">Email Address:</td>
                        <td className="word-break-word">{data?.data?.emergencyContact?.email} </td>
                      </tr>
                      <tr>
                        <td className="text-nowrap">Phone Number :</td>
                        <td className="word-break-word">
                          {formatPhoneNumber(data?.data?.emergencyContact?.phone)}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-nowrap">Relation:</td>
                        <td className="word-break-word">
                          {data?.data?.emergencyContact?.relation}{' '}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </CardUI>
                <CardUI>
                  <h3>Family Pets</h3>
                  <p>{data?.data?.familyPets}</p>
                </CardUI>
              </div>
            </SitterFamView>
          </SmallContainer>
        </Container>
      </StyledSitterProfile>
    </>
  );
}

export default FamilySitterView;
