/* eslint-disable no-unused-vars */
import { Form, Button } from 'react-bootstrap';
import React, { useState } from 'react';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import ScheduleSelector from 'components/ScheduleSelector';
import moment from 'moment-timezone';
import MultiSelect from 'components/CreatableSelect';

const FormWrapper = styled(Form)``;

const ReactSelectWrapper = styled.div`
  input {
    height: 42px !important;
  }

  input {
    &:active,
    &:focus {
      box-shadow: none !important;
    }
  }

  .css-1s2u09g-control {
    appearance: none;
    border: none;
    background: #f6f6f6;
    border-radius: 0.5rem;
    font-size: 0.9rem;
    font-family: 'Didact Gothic';
    color: #000;

    &::placeholder {
      font-size: 0.9rem;
      color: #000;
      opacity: 0.4;
    }
  }
`;

// const DateWrapper = styled.div`
//   height: 300px;
//   overflow: auto;
//   p {
//     /* height: 12px; */
//     margin: 0px;
//   }
//   h2 {
//     font-size: 0.8rem;
//     margin-bottom: 0rem;
//   }
//   .nyhan {
//     background: #c3cbc2;
//     border: 1px solid #cbc8c9;
//     border-radius: 0.3rem;
//   }
//   .kWUNsE {
//     background: #f7f7f7;
//     border: 1px solid #cbc8c9;
//     border-radius: 0.3rem;
//   }
//   div {
//     background: #f7f7f7;
//     padding: 0.2rem;
//     border-radius: 16px;
//     div {
//       margin-bottom: 0.25rem;
//       div {
//         margin-top: 2px;
//         height: 14px;
//       }
//     }
//   }
// `;

export default function ThirdStep({ back, updateFormData, next }) {
  const [validated, setValidated] = useState(false);
  const [allergies, setAllergies] = useState([]);
  const [scheduleData, setScheduleData] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  });

  const handleAllergies = (newValue) => {
    let tempAllergies = [];
    newValue.map((allergy) => {
      tempAllergies.push(allergy.value);
    });
    setAllergies(tempAllergies);
  };
  const handleAvailability = (newValue) => {
    setScheduleData(newValue);
    // let tempMonday = [];
    // let tempTuesday = [];
    // let tempWednesday = [];
    // let tempThursday = [];
    // let tempFriday = [];
    // let tempSaturday = [];
    // let tempSunday = [];
    // newValue.map((entry) => {
    //   if (moment(entry).format('dddd') === 'Monday') {
    //     tempMonday.push(moment(entry).format('HH:00'));
    //   } else if (moment(entry).format('dddd') === 'Tuesday') {
    //     tempTuesday.push(moment(entry).format('HH:00'));
    //   } else if (moment(entry).format('dddd') === 'Wednesday') {
    //     tempWednesday.push(moment(entry).format('HH:00'));
    //   } else if (moment(entry).format('dddd') === 'Thursday') {
    //     tempThursday.push(moment(entry).format('HH:00'));
    //   } else if (moment(entry).format('dddd') === 'Friday') {
    //     tempFriday.push(moment(entry).format('HH:00'));
    //   } else if (moment(entry).format('dddd') === 'Saturday') {
    //     tempSaturday.push(moment(entry).format('HH:00'));
    //   } else if (moment(entry).format('dddd') === 'Sunday') {
    //     tempSunday.push(moment(entry).format('HH:00'));
    //   } else {
    //     console.log('error');
    //   }
    // });
    // setScheduleData({
    //   monday: tempMonday,
    //   tuesday: tempTuesday,
    //   wednesday: tempWednesday,
    //   thursday: tempThursday,
    //   friday: tempFriday,
    //   saturday: tempSaturday,
    //   sunday: tempSunday
    // });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      updateFormData({
        dob: e.target.dob.value,
        allergies: allergies,
        additionalNotes: e.target.additionalNotes.value,
        availability: scheduleData
      });
      next();
    }
    setValidated(true);
  };

  const month = moment().format('MMMM').toLowerCase();
  const year = moment().format('YYYY').toLowerCase();

  return (
    <>
      <h3>Complete your Anytime Sitter profile (3/4)</h3>
      <FormWrapper
        className="registration-form"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}>
        <Form.Group className="mb-3 styled_input" controlId="dob">
          <Form.Label>Date of Birth</Form.Label>
          <Form.Control
            type="date"
            placeholder="MM-DD-YYYY"
            name="date_of_birth"
            className="date-input"
            onClick={(e) => e.preventDefault()}
          />
        </Form.Group>
        <Form.Group className="mb-3 styled_input">
          <Form.Label>Allergies (food, medical, pet, etc.)</Form.Label>
          <ReactSelectWrapper>
            <MultiSelect placeholder="Enter allergy information here" onChange={handleAllergies} />
          </ReactSelectWrapper>
        </Form.Group>
        <Form.Group className="mb-3 styled_input" controlId="additionalNotes">
          <Form.Label>Additional Notes</Form.Label>
          <Form.Control
            maxLength={184}
            as="textarea"
            rows={4}
            placeholder="Please share any additional information about yourself that you think will be important for our admin team to know."
          />
        </Form.Group>
        <Form.Group className="mb-3 styled_input">
          <Form.Label>Weekly Availability</Form.Label>
          <p className="mt-0">
            Please share your regular/reoccurring weekly availability below. Changes to your regular
            availability can be updated at any time.
          </p>
          <ScheduleSelector
            data={scheduleData}
            numDays={7}
            minTime={0}
            maxTime={24}
            startDate={new Date(moment(`${year}-${month}-5`).format())}
            hourlyChunks={1}
            dateFormat="ddd"
            onChange={handleAvailability}
            timeFormat="hA"
          />
        </Form.Group>
        <div className="float-end mt-3 d-flex">
          <Button variant="alt" onClick={() => back()} className="d-inline me-3 scale-animation">
            Back
          </Button>
          <Button variant="primary" type="submit" className="d-inline scale-animation">
            Next
          </Button>
        </div>
      </FormWrapper>
    </>
  );
}
