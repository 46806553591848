import styled from 'styled-components';

export const StyledDash = styled.div`
  h1 {
    font-family: 'Didact Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.5rem;
    color: #404040;
    margin-top: 2rem;
  }
  .same-sess {
    margin-bottom: 2rem;

    .card-ui {
      min-height: 375px;
    }
  }
`;

export const StyledSession = styled.div`
  .borderTopNone {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
  .session-details-table {
    flex: 1;
    width: 100%;
  }

  .borderBottomNone {
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom: 1px solid #cbc8c9;

    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-family: 'Brandon Grotesque', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 1.8rem;
      text-transform: uppercase;
    }

    button {
      font-family: 'Didact Gothic', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.3rem;
      color: #000000;
      margin-right: 0.6rem;
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.05);
      padding: 0.6rem 2rem;
    }
  }

  .sessionTop {
    > div img {
      max-width: 71px;
      width: 100%;
      object-fit: cover;
    }
    > div {
      width: 30%;
    }
    p {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.6rem;
      color: #404040;
    }

    td {
      p {
        font-size: 1rem;
        color: #797979;
      }
    }

    th {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 1.2rem;
      padding-bottom: 0px;
    }
    > div {
      p {
        font-family: 'Didact Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 1.2rem;
        color: #404040;
      }
    }
  }

  .sessionInfo {
    table thead {
      display: contents;
    }

    td > div {
      display: flex;
      grid-gap: 0.5rem;
    }
  }

  @media screen and (max-width: 767px) {
    .borderBottomNone {
      p {
        font-size: 0.8rem;
      }
      > div {
        display: flex;

        button {
          font-size: 0.8rem;
          padding: 0.5rem 1rem;
        }
      }
    }

    table.table {
      margin-top: 1rem !important;
      td {
        border: 1px solid #cbc8c9;
        border-radius: 0.5rem;
        margin: 0.2rem 0;
      }
      thead {
        display: none;
      }
    }

    .sessionInfo {
      display: flex;
      flex-direction: column;
    }

    .sessionTop > div:fist-child {
      width: 100%;
    }
    .sessionTop {
      > div {
        width: 100%;
      }
    }
  }
`;

export const StyledSessionList = styled.div`
  .eachSession {
    border: 1px solid #cbc8c9;
  }

  .newSessions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin: 0px !important;
    flex-wrap: nowrap;

    > div {
      width: 100%;
    }
  }
`;

export const StyledUpcomingSession = styled.div`
  margin-top: 1.5rem;

  .link-button {
    svg {
      path {
        stroke: #404040;
      }
    }
    .past-session-link-text {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #404040;
    }
    &:hover,
    &:focus,
    &:active {
      svg {
        path {
          stroke: #c3cbc2;
        }
      }
      .past-session-link-text {
        color: #c3cbc2 !important;
      }
    }
  }

  h4 {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.9rem;
    text-transform: uppercase;
    color: #404040;
  }

  .SingleSession {
    border: 1px solid #cbc8c9;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    p {
      margin: 0;
    }

    > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 1rem;
      > p {
        margin-bottom: 0.6rem;
        font-family: 'Didact Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 1.3rem;
        color: #404040;
      }
      div {
        display: flex;
        grid-gap: 1rem;
      }
      div span:first-child {
        border-right: 1.6px solid #cbc8c9;
        padding-right: 1rem;
      }
      div span {
        display: flex;
        align-items: center;

        p {
          font-family: 'Didact Gothic';
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.25rem;
          color: #404040;
        }

        img {
          margin-right: 0.3rem;
        }
      }
    }
  }

  .date {
    color: #797979 !important;
  }
`;

export const StyledRequestSession = styled.div`
  margin-top: 1.5rem;

  h4 {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 1.9rem;
    text-transform: uppercase;
    color: #404040;
  }
  > div > p {
    font-family: 'Didact Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.1rem;
    color: #000000;
  }

  .SingleSession {
    border: 1px solid #cbc8c9;
    display: flex;
    align-items: center;
    margin-top: 1rem;
    cursor: pointer;

    img {
      max-width: 71px;
      width: 100%;
      object-fit: cover;
    }

    p {
      margin: 0;
    }

    > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-left: 1rem;
      > p {
        margin-bottom: 0.6rem;
        font-family: 'Didact Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 1.1rem;
        line-height: 1.3rem;
        color: #404040;
      }
      div {
        display: flex;
        grid-gap: 1rem;
      }
      div span:first-child {
        border-right: 1.6px solid #cbc8c9;
        padding-right: 1rem;
      }
      div span {
        display: flex;
        align-items: center;

        p {
          font-family: 'Didact Gothic';
          font-style: normal;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.25rem;
          color: #404040;
        }

        img {
          margin-right: 0.3rem;
        }
      }
    }
  }

  .requestBtns {
    flex-direction: row !important;
    margin-left: auto;
    grid-gap: 0.5rem;

    button {
      border-radius: 8px;
      background: rgba(203, 200, 201, 0.28);
      padding: 12px;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover,
      &:focus,
      &:active {
        border: 1px solid #c3cbc2;
      }
    }

    button:first-child svg {
      color: red;
    }
  }

  .date {
    color: #797979 !important;
  }

  @media screen and (max-width: 767px) {
    .SingleSession {
      flex-direction: column;

      > div > p {
        text-align: center;
        margin-top: 0.6rem;
      }

      div.famInfo {
        flex-direction: column;
        justify-content: center;
        padding-left: 0px !important;

        > p {
          text-align: center;
        }
      }
      > div.requestBtns {
        margin: auto;
        margin-top: 0.8rem;
      }
    }
  }
`;

export const StyledSessionDetails = styled.div`
  /* background: #fff; */
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 6rem);
  h2 {
    font-family: 'Brandon Grotesque';
    font-size: 1.4rem;
    line-height: 2rem;
    text-transform: uppercase;
    font-weight: 300;
  }

  > div {
    /* padding: 0 15px; */
    margin: auto;
  }

  .content {
    padding-bottom: 6rem;
  }

  .admin-notes-card {
    flex: 1 1 auto;
    max-height: 570px;
  }

  .admin-notes-list {
    max-height: 520px;
    overflow: auto;
  }

  .family-notes-card {
    // flex: 1 1 auto;
  }

  .family-notes-list {
    max-height: 320px;
    overflow: auto;
  }

  .cardUI {
    border: 1px solid #cbc8c9;
    border-radius: 0.6rem;
    margin-top: 1.4rem;
  }

  .famDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
      grid-gap: 1.5rem;

      h4,
      p,
      address {
        font-family: 'Didact Gothic';
        font-style: normal;
        font-weight: 400;
      }
      h4 {
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin: 0;
      }

      p {
        font-size: 1.2rem;
        line-height: 1.5rem;
        margin: 0.5rem 0;
      }

      address {
        font-size: 1.1rem;
        line-height: 1.3rem;
        width: 70%;
        padding-left: 0.3rem;
      }
    }

    button {
      font-family: 'Didact Gothic';
      font-size: 1.1rem;
      line-height: 1.4rem;
      background: #fff !important;
      color: #000 !important;
      border: none !important;
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.05);
    }
  }

  .chilInfo {
    img {
      margin-top: 0.5rem;
      margin-right: 0.5rem;
      min-width: 44px;
    }

    div.childrens > div {
      display: flex;
      align-items: flex-start;

      td {
        font-family: 'Didact Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
      }
      td:first-child {
        color: #404040;
        opacity: 0.5;
      }
    }
  }

  .backtoDash {
    position: static;
    bottom: 0;
    left: 0;
    padding: 1.5rem 0 1.5rem 0px;
    background: #fcf7f2;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);

    > div {
      text-align: right;
      padding: 0 15px;
    }
  }

  .sessionInfoCards {
    > h5 {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 300;
      font-size: 1rem;
      text-transform: uppercase;
      margin: 0;
    }
  }

  .sessionDate {
    > span {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: #000000;
      display: inline-block;
      margin-bottom: 1.8rem;
    }

    > div {
      display: flex;
      justify-content: flex-start;
      grid-gap: 3rem;
      margin-bottom: 1.8rem;
    }
    h5 {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 300;
      font-size: 1rem;
      text-transform: uppercase;
      margin: 0.5rem 0;
      color: #000000;
    }

    p {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      font-weight: bold;
      margin: 0;
    }
  }

  .chilInfo {
    > div > h6 {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1rem;
      text-transform: uppercase;
      margin-top: 1rem;
    }
  }

  .sessionNotes {
    p {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.24rem;
    }

    p:nth-child(2) {
      margin-bottom: 0;
      display: flex;
      align-items: center;
      margin: 0.5rem 0;

      img {
        margin-right: 0.4rem;
      }
    }
  }

  .backtoDash {
    button {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.3rem;
      background-color: #fff;
      box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.05);
    }
  }

  @media screen and (max-width: 768px) {
    .famDetails {
      flex-direction: column;
      align-items: center;
      grid-gap: 1rem;

      > div {
        align-items: center;
        flex-direction: column;
        justify-content: center;

        > div {
          text-align: center;

          > div {
            display: block !important;
          }
        }
        address {
          width: 100%;
        }
      }

      .famDetails > button {
        margin-top: 1rem;
        padding: 0.5rem 1rem;
        font-size: 1rem;
      }
    }

    .childrens > div {
      flex-direction: column;
    }

    .backtoDash {
      position: sticky;
      bottom: 0;
      padding: 0.6rem 0 0.6rem 0px;
      background: #fcf7f2;
      box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);

      button {
        padding: 0.5rem 1rem;
        font-size: 1rem;
      }
    }
  }
`;

export const StyledPastSessions = styled.div`
  > div {
    h2 {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 300;
      font-size: 2rem;
      line-height: 3rem;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 1rem;
    }
    > a,
    > div > a svg {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
      color: #000000;
      opacity: 0.8;
    }
  }
  .family-image {
    width: 44px;
    height: 44px;
  }
  .PastSessionData {
    thead th {
      background-color: #f7f7f7;

      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 400;
      font-size: 1re;
      line-height: 1.3rem;
      text-transform: uppercase;
      padding: 1rem;

      &:nth-child(6) {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
      &:nth-child(1) {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
      }
    }

    tbody tr:nth-child(1) td {
      padding-top: 2rem;
    }
    tbody tr td:nth-child(1) {
      span {
        padding-left: 0.8rem;
        font-family: 'Didact Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.3rem;
        color: #000000;
        opacity: 0.8;
      }
    }
    tbody tr {
      border-bottom: 1px solid #dddddd;

      td {
        padding: 1rem 0.5rem 1rem 1rem;
        font-family: 'Didact Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.2rem;
        white-space: nowrap;

        button {
          font-family: 'Didact Gothic';
          font-style: normal;
          font-weight: 400;
          font-size: 1.1rem;
          line-height: 1.3rem;
          padding: 0.6rem 1.5rem;
        }
      }
    }
  }
`;
