import React from 'react';
import GlobalStyles from 'styles/Global';
import { ThemeProvider } from 'styled-components';
import theme from 'styles/theme';
import RoutesIndex from './routes';
import CssUtils from 'styles/css-utils';
import { AuthProvider } from 'helpers/contexts/AuthContext';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from 'components/menu/header';
import { CookiesProvider } from 'react-cookie';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <GlobalStyles />
        <CssUtils />
        <Router>
          <CookiesProvider>
            <AuthProvider>
              <Header dark />
              <RoutesIndex />
            </AuthProvider>
          </CookiesProvider>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
