import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { PublicRoutes } from './Public';
import { PrivateRoutes, ProtectedRoute } from './Private';
import { useAuth } from 'helpers/contexts/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';

export default function RoutesIndex() {
  const auth = useAuth();
  const location = useLocation();

  return (
    <Routes>
      {PublicRoutes.map((route, index) => {
        if (route.pathname === '/login' && auth.user) {
          return (
            <Route
              key={index}
              path={route.pathname}
              element={
                <>
                  <Navigate to="/dashboard" state={{ from: location }} />
                  <route.component />
                </>
              }
            />
          );
        }
        return <Route key={index} path={route.pathname} element={<route.component />} />;
      })}

      {PrivateRoutes.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.pathname}
            element={
              <ProtectedRoute>
                <route.component />
              </ProtectedRoute>
            }
          />
        );
      })}
    </Routes>
  );
}
