/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Container, Form, Spinner } from 'react-bootstrap';
import SmallContainer from '../../components/layout/CustomContainer';
import { BiArrowBack } from 'react-icons/bi';
import { EditGeneralInfo } from './EditProfileComponents';
import { EditEmergencyContact } from './EditEmergencyContact';
import { EditBio } from './EditBio';
import { EditAvailability } from './EditAvailability';
import { EditAllergies } from './EditAllergies';
import { SaveEdit, StyledSitterProfile } from '../profile/SitterProfile.styled';
import { profile } from 'helpers/http/index';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import moment from 'moment';

const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  preferredName: '',
  email: '',
  phone: '',
  address: '',
  neighborhood: '',
  dob: '',
  allergies: [],
  additionalNotes: '',
  bio: '',
  availability: {}
};

function EditSitterProfile() {
  const [formData, setFormData] = React.useState(INITIAL_STATE);
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();
  const [validated, setValidated] = React.useState(false);
  const [deletedVacationList, setDeletedVacationList] = React.useState([]);
  const [vacationErrorList, setVacationErrorList] = React.useState([]);

  const updateFormData = (data) => {
    setFormData((prevState) => ({ ...prevState, ...data }));
  };

  const updateDeletedVacation = (vacationId) => {
    setDeletedVacationList((list) => {
      return [...list, vacationId];
    });
  };

  const submit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setIsLoading(true);
      profile
        .saveProfile(formData)
        .then(() => {
          setIsLoading(false);
          toast.success('Profile Updated Successfully');
          navigate('/profile');
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.message || 'Something went wrong');
        });
    }
    setValidated(true);
  };

  const componentProps = { updateFormData, formData, submit };

  return (
    <>
      <Helmet>
        <title>Sitter edit profile</title>
      </Helmet>
      <StyledSitterProfile>
        <Container className="bg-white py-4">
          <SmallContainer className="smContainer">
            <Link to="/profile" className="my-4 d-inline-block  scale-animation">
              <BiArrowBack /> Back
            </Link>
            <Form onSubmit={submit} noValidate validated={validated}>
              <SaveEdit>
                <p>Edit profile</p>
                <Button variant="alt" className="less-padding" type="submit" disabled={isLoading}>
                  {isLoading ? <Spinner animation="border" size="sm" /> : 'Save'}
                </Button>
              </SaveEdit>
              <div
                className="sitterOtherInfo mt-4"
                style={{ columnCount: '2', columnFill: 'balance' }}>
                <EditGeneralInfo {...componentProps} />
                <EditBio {...componentProps} />
                <EditAllergies {...componentProps} />
                <EditEmergencyContact {...componentProps} />
                <EditAvailability
                  {...componentProps}
                  updateDeletedVacation={updateDeletedVacation}
                  vacationErrorList={vacationErrorList}
                />
              </div>
            </Form>
          </SmallContainer>
        </Container>
      </StyledSitterProfile>
    </>
  );
}

export default EditSitterProfile;
