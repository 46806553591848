/* eslint-disable no-undef */
export const PHONE_VALIDATION = new RegExp('^([0-9()/+ -]*)$');
export const FAMILY_PORTAL_URL = process.env
  ? process.env.REACT_APP_FAMILY_PORTAL_URL
  : 'https://master.d3g1vinllzjwpe.amplifyapp.com';
export const MealExpenseCondition = process.env
  ? process.env.REACT_APP_SESSION_MEAL_EXPENSE_CONDITION === 'false'
    ? false
    : true
  : true;
export const TransportExpenseCondition = process.env
  ? process.env.REACT_APP_SESSION_TRANSPORT_EXPENSE_CONDITION === 'false'
    ? false
    : true
  : true;
