import React from 'react';
import { Form, Row, Col, Button, FormGroup } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';

export default function FirstStep({ updateFormData, next, formData }) {
  const schema = yup
    .object({
      // firstName: yup.string().default(window.localStorage.getItem('firstName')),
      // lastName: yup.string().default(window.localStorage.getItem('lastName')),
      preferredName: yup.string().required('Preferred name is required.'),
      // email: yup.string().email().default(window.localStorage.getItem('email')),
      password: yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#_])[A-Za-z\d@$!%*#?&_]{8,}$/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        )
        .required('Password is required.'),
      confirm: yup
        .string()
        .required('Please confirm your password.')
        .oneOf([yup.ref('password'), null], "Passwords don't match.")
    })
    .required();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    updateFormData({
      preferredName: data.preferredName,
      password: data.password
    });
    next();
  };

  React.useEffect(() => {
    updateFormData({
      ...formData,
      preferredName: formData.preferredName
    });
  }, []);

  const { errors } = formState;

  return (
    <>
      <h3>Complete your Anytime Sitter profile (1/4)</h3>
      <p>
        Please Note: None of your personal information will be shared with MDE families unless
        explicitly stated.
      </p>

      <Form className="registration-form" onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="6">
            <FormGroup className="mb-3 styled_input" controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                disabled
                defaultValue={formData.firstName}
                {...register('firstName')}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="mb-3 styled_input" controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                disabled
                defaultValue={formData.lastName}
                {...register('lastName')}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup className="mb-3 styled_input" controlId="preferredName">
          <Form.Label>Preferred Name (this will be the name seen by MDE families)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter preferred name"
            required
            defaultValue={formData.preferredName}
            {...register('preferredName')}
          />
          <small className="error">{errors.preferredName?.message}</small>
        </FormGroup>
        <FormGroup className="mb-3 styled_input" controlId="email">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
            placeholder="Enter Email Address"
            disabled
            defaultValue={formData.email}
            {...register('email')}
          />
          <small className="error">{errors.email?.message}</small>
        </FormGroup>
        <FormGroup className="mb-3 styled_input" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Create your password"
            maxLength={16}
            {...register('password')}
          />
          <small className="error">{errors.password?.message}</small>
        </FormGroup>
        <FormGroup className="mb-3 styled_input" controlId="confirmPassword">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Confirm your password"
            maxLength={16}
            {...register('confirm')}
          />
          <small className="error">{errors.confirm?.message}</small>
        </FormGroup>
        <Button type="submit" variant="primary" className="float-end mt-3 scale-animation">
          Next
        </Button>
      </Form>
    </>
  );
}
