import React from 'react';
import { Button, Image, Modal, Row, Table } from 'react-bootstrap';
import { CardUI } from 'components/UI/UI.styled';
import { StyledSessionDetails } from './SitterDash.Styled';
import default_family_icon from 'assets/icons/default-family.svg';
import locationIcon from 'assets/Images/location.svg';
import DateIcon from 'assets/Images/Cal.svg';
import SmallContainer from '../../components/layout/CustomContainer';
import { ReactComponent as CloseButton } from '../../assets/Images/modalClose.svg';
import { useNavigate } from 'react-router';
import moment from 'moment-timezone';
import default_child_icon from 'assets/icons/default-child.svg';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { dashboard } from 'helpers/http';
import NoDataUI from 'components/NoDataUI';
import userIcon from 'assets/Images/usericon.svg';

const FooterWrapper = styled.div`
  display: flex;
  background: #fcf7f2;
  box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  position: fixed;
  bottom: 0rem;
  width: 100vw;
`;

function SessionDetails({ show, handleClose, data }) {
  const sessionId = data?.data?.id;
  if (!sessionId) {
    return null;
  }
  const { data: adminNote, isLoading: adminNoteLoading } = useQuery(
    [`list-session-notes`, sessionId],
    () => dashboard.getNotes(sessionId),
    {
      refetchOnWindowFocus: false
    }
  );

  const { data: familyNote, isLoading: familyNoteLoading } = useQuery(
    [`list-family-session-notes`, sessionId],
    () => dashboard.getFamilyNotes(sessionId),
    {
      refetchOnWindowFocus: false
    }
  );
  const navigate = useNavigate();
  const handleViewProfile = () => {
    navigate(`/family/${data?.data?.userId}`);
  };
  return (
    <Modal fullscreen show={show} onHide={handleClose} className="p-0 fullscreen-modal">
      <CloseButton onClick={handleClose} className="close_btn" />
      <Modal.Body className="mb-0 p-0" style={{ borderRadius: '30px 30px 0px 0px' }}>
        <StyledSessionDetails className="pt-4">
          <SmallContainer className="mt-3 content">
            <Row>
              <h2 className="m-0">Session details</h2>
              <div className="col-12">
                <CardUI className="famDetails cardUI bg-transparent p-md-4">
                  <div>
                    <Image
                      src={data?.data?.family?.familyPhoto || default_family_icon}
                      alt=""
                      height={98}
                      width={98}
                      rounded
                    />
                    <div>
                      <h4>The {data?.data?.family?.lastName} Family</h4>
                      <p>
                        {data?.data?.family?.parents?.[0]?.firstName +
                          ' ' +
                          data?.data?.family?.parents?.[0]?.lastName}
                        {data?.data?.family?.parents?.[1] &&
                          ' | ' +
                            data?.data?.family?.parents?.[1]?.firstName +
                            ' ' +
                            data?.data?.family?.parents?.[1]?.lastName}
                      </p>
                      <div className="d-flex align-items-start">
                        <img src={locationIcon} alt="" />
                        <address className="m-0">{data?.data?.family?.address}</address>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button className="scale-animation" variant="white" onClick={handleViewProfile}>
                      View Profile
                    </Button>
                  </div>
                </CardUI>
              </div>
            </Row>
            <Row>
              <div className="col-md-6 d-flex flex-column">
                <CardUI className="cardUI bg-transparent p-md-4 sessionDate sessionInfoCards">
                  <div className="d-flex">
                    <div className="d-grid">
                      <h5>Session Date</h5>
                      <span>
                        {moment(data?.data?.sessionStart).tz('America/New_York').format('ll')}
                      </span>
                    </div>
                    <div className="d-grid">
                      <h5>Children</h5>
                      <div>
                        {Array.from(Array(data?.data?.numberOfChildren)).map((v, index) => (
                          <img src={userIcon} alt="" key={index} />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div>
                    <span>
                      <h5>Start time</h5>
                      <p>{moment(data?.data?.sessionStart).tz('America/New_York').format('LT')}</p>
                    </span>
                    <span>
                      <h5>End time</h5>
                      <p>{moment(data?.data?.sessionEnd).tz('America/New_York').format('LT')}</p>
                    </span>
                  </div>
                  {/* <h5>Additional Notes</h5>
                  <p>{data?.data?.additionalNotes || 'No Note(s)'}</p> */}
                </CardUI>
                <CardUI className="cardUI bg-transparent p-md-4 sessionNotes sessionInfoCards admin-notes-card">
                  <h5>MDE ADMIN NOTES</h5>
                  <div className="admin-notes-list">
                    {adminNote?.data?.map((note) => (
                      <CardUI key={note.id} className="cardUI bg-transparent">
                        <p>{note.note}</p>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <img src={DateIcon} alt="" />
                            <span>{moment(note.updatedAt).format('MMM DD, YYYY')}</span>
                          </div>
                        </div>
                      </CardUI>
                    ))}
                    {(!adminNote?.data || adminNote?.data?.length === 0) && !adminNoteLoading && (
                      <NoDataUI notFoundText={'No admin notes found'} />
                    )}
                  </div>
                </CardUI>
              </div>
              <div className="col-md-6  d-flex flex-column">
                <CardUI className="cardUI bg-transparent p-md-4 chilInfo sessionInfoCards">
                  <h5>Child information</h5>
                  {data?.data?.family?.children?.map((child, index) => (
                    <div className="childrens" key={index}>
                      <h6>Child {index + 1}</h6>
                      <div>
                        <Image
                          src={child?.photo || default_child_icon}
                          alt="child photo"
                          height={44}
                          width={44}
                          className="rounded"
                        />
                        <Table borderless style={{ width: '90%' }}>
                          <tbody>
                            <tr>
                              <td className="min-width-130px text-nowrap">Name:</td>
                              <td className="word-break-word">
                                {child?.firstName + ' ' + child?.lastName}
                              </td>
                            </tr>
                            <tr>
                              <td className="min-width-130px  text-nowrap">Date of Birth:</td>
                              <td className="word-break-word">
                                {moment.utc(child?.dob).format('MMM DD, YYYY')}
                              </td>
                            </tr>
                            <tr>
                              <td className="min-width-130px  text-nowrap">Allergies:</td>
                              <td className="word-break-word">{child?.allergies?.join(', ')}</td>
                            </tr>
                            <tr>
                              <td className="min-width-130px  text-nowrap">Food Preferences:</td>
                              <td className="word-break-word">
                                {child?.foodPreferences?.join(', ')}
                              </td>
                            </tr>
                            <tr>
                              <td className="min-width-130px  text-nowrap">Additional Notes:</td>
                              <td className="word-break-word">
                                {child?.specialNotes || 'No Note(s)'}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  ))}
                </CardUI>
                <CardUI className="cardUI bg-transparent p-md-4 sessionNotes sessionInfoCards family-notes-card">
                  <h5>FAMILY NOTES</h5>
                  <div className="family-notes-list">
                    {familyNote?.data?.map((note) => (
                      <CardUI key={note.id} className="cardUI bg-transparent">
                        <p>{note.note}</p>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <img src={DateIcon} alt="" />
                            <span>{moment(note.updatedAt).format('MMM DD, YYYY')}</span>
                          </div>
                        </div>
                      </CardUI>
                    ))}

                    {(!familyNote?.data || familyNote?.data?.length === 0) &&
                      !familyNoteLoading && <NoDataUI notFoundText={'No family notes found'} />}
                  </div>
                </CardUI>
              </div>
            </Row>
          </SmallContainer>
          <FooterWrapper>
            <SmallContainer>
              <Button
                variant="white"
                className="float-end white-shadow-button scale-animation"
                onClick={handleClose}>
                Return to Dashboard
              </Button>
            </SmallContainer>
          </FooterWrapper>
        </StyledSessionDetails>
      </Modal.Body>
    </Modal>
  );
}

export default SessionDetails;
