/* eslint-disable no-unused-vars */
import { Availability } from './SitterProfile.styled';
import React from 'react';
import { toast } from 'react-hot-toast';
import { profile } from 'helpers/http/index';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Button, Spinner } from 'react-bootstrap';
import CalendarLogo from '../../assets/Images/calendar_18_2x.png';

export default function GoogleCalendar() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');

  const { data, isLoading, refetch, remove } = useQuery(
    'check-google-auth',
    profile.checkGoogleAuth,
    {
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  useEffect(() => {
    if (code) {
      _authenticateGoogle();
    }
  }, [code]);

  const _authenticateGoogle = () => {
    const promise = profile.authenticateGoogle({ code });
    toast.promise(promise, {
      loading: 'Authenticating...',
      success: (res) => {
        remove();
        refetch();
        navigate('/profile');
        return res.message || 'Success!';
      },
      error: (err) => err.response?.data?.message || 'Error!'
    });
  };

  const _connectGoogleCalendar = () => {
    const promise = profile.connectGoogleCalendar();
    toast.promise(promise, {
      loading: 'Connecting...',
      success: (res) => {
        if (res.data?.authUrl) {
          window.location.href = res.data?.authUrl;
        }
        return res.message || 'Success!';
      },
      error: (err) => err.response?.data?.message || 'Error!'
    });
  };

  const _revokeGoogleAuth = () => {
    const promise = profile.revokeGoogleAuth();
    toast.promise(promise, {
      loading: 'Disconnecting...',
      success: (res) => {
        remove();
        refetch();
        return res.message || 'Success!';
      },
      error: (err) => err.response?.data?.message || 'Error!'
    });
  };

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" size="sm" />
      </div>
    );
  }

  return (
    <>
      <div className="googleCalendar">
        <h3>Google Calendar</h3>
        {!data?.data?.isGoogleAuthenticated && (
          <div className="text-center my-5">
            <div className="d-grid">
              <span className="gray fw-bold">Calendar Not Connected</span>
              <span className="gray mt-2">
                Connect your Google Calendar to automatically sync your scheduled ATS sessions.
              </span>
            </div>
            <Button
              variant="white"
              className="less-padding scale-animation white-shadow-button mt-4"
              onClick={_connectGoogleCalendar}>
              <img src={CalendarLogo} className="google-calendar-logo" alt="Google Calendar Logo" />
              Connect to Google Calendar
            </Button>
          </div>
        )}
        {data?.data?.isGoogleAuthenticated && (
          <div className="text-center my-5">
            <div className="d-grid">
              <span className="gray">
                Connected to <span className="gray fw-bold">{data?.data?.email}</span>
              </span>
            </div>
            <Button
              variant="white"
              className="less-padding scale-animation white-shadow-button mt-4"
              onClick={_revokeGoogleAuth}>
              <img src={CalendarLogo} className="google-calendar-logo" alt="Google Calendar Logo" />
              Disconnect to Google Calendar
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
