import React from 'react';
import CreatableSelect from 'react-select/creatable';

function MultiSelect(props) {
  return (
    <CreatableSelect
      isMulti
      formatCreateLabel={(value) => <>Add "{value}"</>}
      styles={{
        control: (provided) => ({
          ...provided,
          borderRadius: '8px',
          border: '1px solid #cbc8c9',
          boxShadow: 'none',
          transition: 'all 0s ease-in-out',
          '&:hover': {
            boxShadow: '0 0 0 0.125rem #c3cbc2 !important',
            border: '1px solid #cbc8c9'
          }
        }),
        option: (provided) => ({
          ...provided,
          backgroundColor: '#fff important',
          cursor: 'pointer'
        }),
        menu: (provided, state) => ({
          ...provided,
          display: state.options.length > 0 ? 'initial' : 'none'
        }),
        input: (provided) => ({
          ...provided,
          minWidth: '5px',
          '& > input': {
            minWidth: '5px !important'
          }
        }),
        valueContainer: (provided) => ({
          ...provided,
          borderRadius: 8
        })
      }}
      {...props}
    />
  );
}

export default MultiSelect;
