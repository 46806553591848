import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'helpers/contexts/AuthContext';
import SitterProfile from 'modules/profile/SitterProfile';
import React from 'react';
import EditSitterProfile from 'modules/edit-profile/EditSitterProfile';
import SitterDashboard from 'modules/dashboard/SitterDashboard';
import PastSession from 'modules/dashboard/PastSession';
import FamilySitterView from 'modules/profile/FamilySitterView';
import Agreement from 'modules/policy/Agreement';
import ReSignPolicy from 'modules/policy/ReSignPolicy';
import SignPolicy from 'modules/policy/SignPolicy';

function ProtectedRoute({ children }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (location.pathname === '/dashboard' && !auth?.userInfoLoading) {
    if (auth?.userInfo?.isPolicySigned && auth?.userInfo?.policySignedDate) {
      const policySignedDate = new Date(auth?.userInfo?.policySignedDate);
      const deadlineDate = new Date('2024-09-01');
      if (policySignedDate < deadlineDate) {
        return <ReSignPolicy />;
      }
    }

    if (auth?.userInfo && !auth?.userInfo?.isPolicySigned) {
      return <SignPolicy />;
    }
  }

  return children;
}

function IndexHandler() {
  return <Navigate to="/dashboard" />;
}

const PrivateRoutes = [
  {
    id: 'profile',
    pathname: '/profile',
    exact: true,
    component: SitterProfile,
    private: true
  },
  {
    id: 'dashboard',
    pathname: '/dashboard',
    exact: true,
    component: SitterDashboard,
    private: true
  },
  {
    id: 'edit-profile',
    pathname: '/edit-profile',
    exact: true,
    component: EditSitterProfile,
    private: true
  },
  {
    id: 'past-sessions',
    pathname: '/past-sessions',
    exact: true,
    component: PastSession,
    private: true
  },
  {
    id: 'family',
    pathname: '/family/:id',
    exact: true,
    component: FamilySitterView,
    private: true
  },
  {
    id: 'oauth2callbacks',
    pathname: '/oauth2callbacks/google',
    exact: true,
    component: SitterProfile,
    private: true
  },
  {
    id: 'index',
    pathname: '/',
    exact: true,
    component: IndexHandler,
    private: true
  },
  {
    id: 'ats-policy',
    pathname: '/ats-policy',
    exact: true,
    component: Agreement,
    private: true
  }
];

export { PrivateRoutes, ProtectedRoute };
