/* eslint-disable no-unused-vars */
import React from 'react';
import { CardUI } from 'components/UI/UI.styled';
import Card from 'components/UI/Card';
import { StyledUpcomingSession } from './SitterDash.Styled';
import { ReactComponent as Calendar } from 'assets/Images/Cal.svg';
import { ReactComponent as Clock } from 'assets/Images/clock.svg';
import default_family_icon from '../../assets/icons/default-family.svg';
import { dashboard } from 'helpers/http';
import { useQuery } from 'react-query';
import { Image, Spinner } from 'react-bootstrap';
import moment from 'moment-timezone';
import NoDataUI from 'components/NoDataUI';
import toast from 'react-hot-toast';
import SessionDetails from './SessionDetails';
import { Link } from 'react-router-dom';

function UpcomingSession({ data, isLoading }) {
  const [show, setShow] = React.useState(false);
  const [modalData, setModalData] = React.useState({});

  const hideModal = () => {
    setShow(false);
  };

  const handleShow = (id) => {
    const promise = dashboard.getSession(id);

    toast.promise(promise, {
      loading: 'Loading Session Details',
      success: (res) => {
        setModalData(res);
        setShow(true);
        return 'Session Details Loaded';
      },
      error: (err) => {
        console.log(err);
      }
    });
  };

  if (isLoading) {
    return (
      <StyledUpcomingSession>
        <CardUI className="p-lg-4 text-center">
          <Spinner animation="border" size="sm" />
        </CardUI>
      </StyledUpcomingSession>
    );
  }

  return (
    <StyledUpcomingSession>
      <SessionDetails show={show} handleClose={hideModal} data={modalData} />
      <CardUI className="p-lg-4 ">
        <div className="d-flex justify-content-between">
          <h4>Upcoming sessions</h4>
          <div className="link-button">
            <Link to="/past-sessions">
              <div className="d-flex align-items-center gap-1">
                <Clock className="past-session-clock" />
                <span className="past-session-link-text">View Past Sessions</span>
              </div>
            </Link>
          </div>
        </div>
        {data?.data?.records?.map((session, index) => (
          <Card
            role="button"
            className="SingleSession scale-animation"
            key={index}
            onClick={() => handleShow(session.id)}>
            <Image
              src={session?.familyPhoto || default_family_icon}
              alt=""
              height={71}
              width={71}
              className="rounded"
            />
            <div>
              <p>{`${session?.firstName} ${session?.lastName}`}</p>
              <div>
                <span>
                  <Calendar className="me-2" />
                  <p className="date">
                    {moment(session?.sessionStart).tz('America/New_York').format('ll')}
                  </p>
                </span>
                <span>
                  <Clock className="me-2" />
                  <p className="date">
                    {moment(session?.sessionStart).tz('America/New_York').format('LT') +
                      ` - ` +
                      moment(session?.sessionEnd).tz('America/New_York').format('LT')}
                  </p>
                </span>
              </div>
            </div>
          </Card>
        ))}
        {data?.data?.records?.length === 0 && (
          <NoDataUI boxCount={true} notFoundText={'You have no upcoming sessions'} />
        )}
      </CardUI>
    </StyledUpcomingSession>
  );
}

export default UpcomingSession;
