import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import styled from 'styled-components';
import { ReactComponent as Close } from 'assets/Images/x.svg';
import { StyledButton } from 'components/UI/UI.styled';

export const ModalWrapper = styled(Modal)`
  background: rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(4px);
  .header {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 300;
    font-size: 1.5rem;
    line-height: 34px;
    color: #000000;
    margin-bottom: 1rem;
  }
  .description {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #404040;
    margin-bottom: 2.25rem;
  }
  .modal-body {
    padding: 3rem;
  }
  .close {
    background: #f3f3f3;
    color: #000000;
  }
  .close-icon {
    position: absolute;
    top: -2.5rem;
    right: 0rem;
    path {
      stroke: white;
    }
  }
  .confirm {
    background: #c3cbc2;
    color: #000000;
  }
`;

function ConfirmView({ show, onClose, onConfirm, description, title }) {
  return (
    <ModalWrapper centered show={show} onHide={onClose}>
      <Close role="button" onClick={onClose} className="close-icon" />
      <ModalBody>
        <h1 className="header text-uppercase">{title}</h1>
        <p className="description fw-normal">{description}</p>
        <div className="d-flex justify-content-end gap-2">
          <StyledButton className="close" onClick={onClose}>
            Close
          </StyledButton>
          <StyledButton className="confirm" onClick={onConfirm}>
            Yes, Confirm
          </StyledButton>
        </div>
      </ModalBody>
    </ModalWrapper>
  );
}

export default ConfirmView;
