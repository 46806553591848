/* eslint-disable no-unused-vars */
import React from 'react';
import checkIcon from '../../assets/Images/Check.svg';
import { Spinner } from 'react-bootstrap';
import editIcon from '../../assets/Images/edit-2.svg';
import { Availability } from './SitterProfile.styled';
import { useQuery } from 'react-query';
import { profile } from 'helpers/http/index';
import ScheduleSelector from 'components/ScheduleSelector';
import moment from 'moment-timezone';
import formatPhoneNumber from 'helpers/utils/formatPhoneNumber';
import VacationView from './VacationView';

function SitterAbout({ data, isLoading }) {
  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" size="sm" />
      </div>
    );
  }

  return (
    <div>
      <h3>About me</h3>
      <p>{data?.data?.bio || 'No Bio'}</p>
    </div>
  );
}

function SitterAvailability() {
  const [avail, setAvail] = React.useState([]);
  const { data, isLoading } = useQuery('sitterAvailability', profile.getAvailability, {
    refetchOnWindowFocus: false
  });
  const { data: vacationData, isLoading: vacationLoading } = useQuery(
    'vacationList',
    profile.listVacations
  );
  const [schedule, setSchedule] = React.useState([]);
  const [isUpdating, setIsUpdating] = React.useState(true);

  React.useEffect(() => {
    if (data) {
      const month = moment().format('MMMM').toLowerCase();
      const year = moment().format('YYYY').toLowerCase();
      let tempData = [];
      data?.data?.sunday?.map((item) => {
        tempData.push(new Date(moment(`${year}-${month}-5 ${item}`).format()));
      });

      setIsUpdating(false);
      setSchedule(tempData);
      setAvail(data?.data || {});
    }
  }, [data]);

  if (isLoading || isUpdating) {
    return (
      <div className="text-center">
        <Spinner animation="border" size="sm" />
      </div>
    );
  }

  const month = moment().format('MMMM').toLowerCase();
  const year = moment().format('YYYY').toLowerCase();
  const currentDate = moment();
  const weekStart = currentDate.clone().startOf('week');
  const weekEnd = currentDate.clone().endOf('week');
  return (
    <Availability className="availability">
      <div>
        <h3>availability</h3>
      </div>
      <p className="availabilityText">
        Please share your regular/recurring weekly availability below. This will remain the same
        week over week until you change it. Changes to your regular availability can be updated at
        any time*. Clicking on a time box = green box = you are available.
        <br />
        <br />
        *To edit availability please click on 'Edit Profile.'
      </p>
      <ScheduleSelector
        data={avail}
        selection={schedule}
        numDays={7}
        minTime={0}
        maxTime={24}
        startDate={new Date(moment(`${year}-${month}-5`).format())}
        hourlyChunks={1}
        dateFormat="ddd"
        timeFormat="hA"
        disabled={true}
        blockedList={vacationData?.data}
      />
      <VacationView vacationList={vacationData?.data} isLoading={vacationLoading} />
    </Availability>
  );
}

function ATSrequirements() {
  return (
    <div className="viewAvailability">
      <h3>ATS requirements</h3>
      <ul className="list-unstyled">
        <li className="my-2">
          <img src={checkIcon} alt="checkicon" />
          <span className="mx-2 my-2">Background Checked</span>
        </li>
        <li className="my-2">
          <img src={checkIcon} alt="checkicon" />
          <span className="mx-2">Reference Checked</span>
        </li>
        <li className="my-2">
          <img src={checkIcon} alt="checkicon" />
          <span className="mx-2">Verified Immunizations</span>
        </li>
        <li className="my-2">
          <img src={checkIcon} alt="checkicon" />
          <span className="mx-2">COVID Vaccinated</span>
        </li>
        <li className="my-2">
          <img src={checkIcon} alt="checkicon" />
          <span className="mx-2">CPR + First Aid Certified</span>
        </li>
      </ul>
    </div>
  );
}

function SitterAllergies({ isLoading, data }) {
  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" size="sm" />
      </div>
    );
  }

  return (
    <div>
      <h3>Allergies</h3>
      <p>{data?.data?.allergies?.join(', ')}</p>
    </div>
  );
}

function SitterEmergencyContact() {
  const { data, isLoading } = useQuery('sitterEC', profile.getEmergencyContact);

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" size="sm" />
      </div>
    );
  }

  return (
    <div className="emmergencyContact">
      <h3>EMERGENCY CONTACT INFORMATION</h3>
      <table>
        <tbody>
          <tr>
            <td className="text-nowrap">Name:</td>
            <td className="word-break-word">{data?.data?.name}</td>
          </tr>
          <tr>
            <td className="text-nowrap">Email Address:</td>
            <td className="word-break-word">{data?.data?.email}</td>
          </tr>
          <tr>
            <td className="text-nowrap">Phone Number:</td>
            <td>{formatPhoneNumber(data?.data?.phone)}</td>
          </tr>
          <tr>
            <td className="text-nowrap">Relation:</td>
            <td className="word-break-word">{data?.data?.relation}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export {
  SitterEmergencyContact,
  SitterAbout,
  SitterAllergies,
  ATSrequirements,
  SitterAvailability
};
