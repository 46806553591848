import NoDataUI from 'components/NoDataUI';
import ForgotPassword from 'modules/login/ForgotPassword';
import Login from 'modules/login/Login';
import ResetPassword from 'modules/login/ResetPassword';
import CompleteSitterProfileForm from 'modules/complete-profile/CompleteSitterProfileForm';

export const PublicRoutes = [
  {
    pathname: '/login',
    exact: true,
    component: Login,
    private: false
  },
  {
    id: 'forgot-password',
    pathname: '/forgot-password',
    exact: true,
    component: ForgotPassword
  },
  {
    id: 'reset-password',
    pathname: '/reset-password/:verification',
    exact: true,
    component: ResetPassword
  },
  {
    id: 'no-data',
    pathname: '/nodata',
    exact: true,
    component: NoDataUI
  },
  {
    id: 'incomplete',
    pathname: '/sitter-complete-profile',
    exact: true,
    component: CompleteSitterProfileForm,
    private: false
  }
];
