import axios from 'axios';
import client from './client';

export default {
  getUploadUrl: ({ filename }) =>
    client
      // eslint-disable-next-line no-undef
      .get(`${process.env.REACT_APP_BACKEND_API_DEV_URL}general/get-url-upload/${filename}`)
      .then((res) => res.data),

  getDownloadUrl: ({ filename }) =>
    client
      // eslint-disable-next-line no-undef
      .get(`${process.env.REACT_APP_BACKEND_API_DEV_URL}general/get-url-download/${filename}`)
      .then((res) => res.data),

  gets3BlobData: (url) =>
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob'
    }).then((res) => res.data)
};
