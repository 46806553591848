import styled from 'styled-components';

export const SiteHeader = styled.header`
  .header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
  }

  #sitter-dropdown {
    color: #000;
    border-radius: 3rem;
    border: none;
  }

  #sitter-dropdown:after,
  #sitter-dropdown:before {
    display: none;
  }

  #sitter-dropdown {
    padding: 0.3rem !important;

    svg {
      position: relative;
      left: -8px;
    }

    img {
      object-fit: cover;
      width: 44px;
      height: 44px;
    }
  }

  .sitterName {
    font-family: 'Didact Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.3rem;
    color: #404040;
    margin: 0px 0.6rem;
  }

  @media screen and (max-width: 767px) {
    .header_wrapper {
      padding: 1rem;
    }

    .header_wrapper > div:nth-child(1) img {
      width: 80%;
    }
    .header_wrapper > div:nth-child(2) button {
      font-size: 1rem;
      padding: 0.5rem 1.5rem;
    }
  }
`;

export const CustomContainer = styled.div`
  max-width: 900px;
  width: 100%;
  margin: auto;
  padding: 2rem auto;

  h2 {
    font-family: ${(props) => props.theme.fonts.brandon};
    font-size: 1.5rem;
    font-weight: 300;
  }
  h4 {
    font-family: ${(props) => props.theme.fonts.didact};
    font-size: 1.5rem !important;
  }
`;
