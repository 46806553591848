import { Row, Form, Button, Spinner, Container } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as BackButton } from 'assets/icons/chevron.svg';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { auth } from 'helpers/http/index';
import { LoginForm } from './Auth.styled';
import Card from '../../components/UI/Card.jsx';
import { Helmet } from 'react-helmet';
import UnauthorizedHeader from 'components/menu/UnauthorizedHeader';

const BackButtonWrapper = styled.div`
  margin: auto;
  opacity: 0.7;
  margin-bottom: 1rem;
  svg {
    margin-left: -4px;
    transform: rotate(-90deg);
  }
  &:hover {
    svg {
      path {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }
`;

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  const schema = yup
    .object({
      email: yup.string().email().required('Email is required.')
    })
    .required();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    auth
      .forgotPassword(data)
      .then((res) => {
        navigate(`/reset-password/${res.data.verification}`);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.response.data.message);
      });
  };
  const { errors } = formState;

  return (
    <>
      <Helmet>
        <title>Sitter Forgot password</title>
      </Helmet>
      <LoginForm className="d-flex flex-column">
        <UnauthorizedHeader dark />
        <Container>
          <Card className="login_Card">
            <div className="login_wrapper">
              <Link to="/login">
                <BackButtonWrapper className="d-flex align-items-center">
                  <BackButton />
                  <span className="ms-2">Back</span>
                </BackButtonWrapper>
              </Link>
              <h3 className="lead text-uppercase mb-4">Forgot Password</h3>

              <p>
                Please enter your email address and we will send you an OTP code to reset your
                password.
              </p>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Form.Group className="mb-3 styled_input" controlId="email">
                    <Form.Label className="mb-1">Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                      placeholder="Enter email address"
                      aria-describedby="basic-addon1"
                      {...register('email')}
                    />
                    <small className="error">{errors.email?.message}</small>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Button
                    className="primary-button ms-auto me-3 mt-3 w-fit-content"
                    type="submit"
                    disabled={isLoading}>
                    Submit
                    <Spinner animation="border" size="sm ms-2" hidden={!isLoading} />
                  </Button>
                </Row>
              </Form>
            </div>
          </Card>
        </Container>
      </LoginForm>
    </>
  );
}
