import React from 'react';
import { StyledSitterPersonalInfo } from './SitterProfile.styled';
import smsicon from 'assets/Images/sms.svg';
import phoneicon from 'assets/Images/call.svg';
import homeicon from 'assets/Images/home-2.svg';
import locationicon from 'assets/Images/location.svg';
import { Button, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ChangePassword from './ChangePassword';
import formatPhoneNumber from 'helpers/utils/formatPhoneNumber';
import default_sitter_icon from 'assets/icons/default-sitter.svg';

const Wrapper = styled.div`
  h2 {
    font-family: 'Didact Gothic';
  }

  .gray {
    color: #6c6c6c;
  }
`;

function SitterPersonalInfo({ isLoading, data }) {
  if (isLoading) {
    return (
      <Wrapper>
        <StyledSitterPersonalInfo className="sitter-personal-info justify-content-center">
          <Spinner animation="border" size="sm" />
        </StyledSitterPersonalInfo>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <StyledSitterPersonalInfo className="sitter-personal-info">
        <div className="sitter-profile-img ">
          <img
            src={data?.data?.photo || default_sitter_icon}
            alt="userprofile"
            className="border-radius-100"
          />
        </div>
        <div className="w-100">
          <h2>
            {data?.data?.firstName} {data?.data?.lastName}{' '}
            {data?.data?.offering ? <span className="gray">({data?.data?.offering})</span> : null}
          </h2>
          <ul className="list-unstyled">
            <li>
              <img src={smsicon} alt="" />{' '}
              <a className="word-break-word" href={`mailto:${data?.data?.email}`}>
                {data?.data?.email}
              </a>
            </li>
            <li>
              <img src={phoneicon} alt="" />{' '}
              <a href={`tel:+1${data?.data?.phone}`}>{formatPhoneNumber(data?.data?.phone)}</a>
            </li>
            <li>
              <img src={homeicon} alt="" /> <a href="#">{data?.data?.neighborhood}</a>
            </li>
            <li>
              <img src={locationicon} alt="" /> <a href="#">{data?.data?.address}</a>
            </li>
          </ul>
        </div>
        <div className="profileBtnGroup">
          <Link to="/edit-profile">
            <Button variant="white" className="less-padding scale-animation white-shadow-button">
              Edit Profile
            </Button>
          </Link>
          <ChangePassword />
          {/* <Policy data={data} /> */}
          {data?.data && !data?.data?.isPolicySigned ? (
            <Link to="/ats-policy">
              <Button
                variant="white"
                className="less-padding scale-animation white-shadow-button mt-3 dark-green-btn">
                Sign ATS Policy
              </Button>
            </Link>
          ) : (
            ''
          )}
        </div>
      </StyledSitterPersonalInfo>
    </Wrapper>
  );
}

export default SitterPersonalInfo;
