import styled from 'styled-components';

export const StyledSitterPersonalInfo = styled.div`
  border-radius: 1rem;
  background-color: #f7f7f7;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;

  &.overflow-hidden {
    grid-gap: 12rem;
  }
  .sitter-profile-img img {
    width: 110px;
    height: 110px;
    object-fit: cover;
    margin-right: 1rem;
  }
  .family-image {
    object-fit: cover;
  }

  div:nth-child(2) h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 2rem;
    line-height: 2.2rem;
    color: #404040;
  }
  ul {
    margin-top: 1rem;
    margin-bottom: 0rem;

    li {
      margin: 0.5rem 0;

      a {
        font-family: 'Didact Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.45rem;
        padding-left: 0.4rem;
        color: #404040;
      }
    }
  }
  .profileBtnGroup {
    text-align: end;

    button {
      box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 1.4rem;
      color: #404040;
    }
  }

  .famParent {
    h4 {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 1.8rem;
      line-height: 2.4rem;
      color: #000000;
    }

    h6 {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1.1rem;
      text-transform: uppercase;
      color: #000000;
    }
  }

  .famParent ul li {
    display: flex;
    align-items: flex-start;
    margin: 1rem 0;
    font-family: 'Didact Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }

  .dark-green-btn {
    background: rgb(195, 203, 194) !important;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    .sitter-personal-info {
      text-align: left;
    }

    &.overflow-hidden {
      grid-gap: 12rem;
    }

    .sitter-profile-img img {
      margin-bottom: 1rem;
    }

    .profileBtnGroup {
      display: flex;
      grid-gap: 0.6rem;
      align-items: center;

      button {
        margin-top: 0 !important;
        font-size: 0.8rem;
      }
    }

    .profileBtnGroup button {
      font-size: 0.8rem;
    }

    .m-centeredLine {
      display: none;
    }

    .centeredLine {
      position: relative !important;
      transform: none !important;
      left: 0 !important;
      right: 0 !important;
    }

    &.overflow-hidden {
      grid-gap: 1rem;
    }
  }
`;

export const StyledSitterProfile = styled.div`
  margin-top: 2rem;
  .container {
    border-radius: 1rem;
  }

  .sitterOtherInfo > div {
    display: inline-block;
    margin: 0 0 20px;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    width: 100%;
    border: 1px solid #dddddd;
    border-radius: 0.7rem;
    padding: 1.3rem;

    h3 {
      font-family: 'Brandon Grotesque';
      font-style: normal;
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 1.5rem;
      text-transform: uppercase;
      color: #404040;
      margin-bottom: 0.6rem;
    }

    > p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #404040;
      margin: 0;
    }

    ul {
      margin: 0px;

      li {
        font-family: 'Didact Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 2rem;
        color: #404040;
      }
    }
  }

  .sitterOtherInfo label {
    font-family: 'Didact Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.2rem;
    color: #000000;
  }

  .sitterOtherInfo input.form-control {
    border: 1px solid #ced4da !important;
    background-image: none !important;
  }

  .sitterOtherInfo input {
    font-family: 'Didact Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.2rem;
  }

  .smContainer a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.1rem;
    color: #404040;

    svg {
      width: 1.2rem !important;
      height: 1.2rem !important;
    }
  }

  .emmergencyContact {
    table {
      tr {
        margin: 0.5rem 0;
      }
      tr td:first-child {
        opacity: 0.5;
        padding-left: 0rem;
      }

      tr td {
        font-family: 'Didact Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4rem;
        color: #404040;
        padding-left: 1rem;
        padding-top: 0.5rem;
      }
    }
  }

  .googleCalendar {
    font-family: 'Didact Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
    color: #404040;

    .google-calendar-logo {
      width: 30px;
      height: auto;
      margin-right: 5px;
    }
  }

  .mde-policy {
    font-family: 'Didact Gothic', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: rgb(0, 0, 0);

    h2,
    h3,
    h4 {
      font-family: 'Didact Gothic';
      font-style: normal;
      line-height: 1.5;
      color: rgb(64, 64, 64);
    }
    h2 {
      font-weight: 400;
      font-size: 1.125rem;
    }
    h3 {
      font-weight: 400;
      font-size: 1.125rem;
    }
    h4 {
      font-weight: bold;
      font-size: 1rem !important;
    }
    p {
      b {
        font-family: 'Didact Gothic';
        font-style: normal;
        line-height: 1.5;
        color: rgb(64, 64, 64);
        font-weight: bold;
        font-size: 1rem !important;
      }
    }

    h2 {
      opacity: 1;
      color: #212529;
    }

    h3 {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 1.125rem;
      color: #212529;
    }

    input {
      border: 0;
      background: transparent;
      border-bottom: 2px solid rgb(203, 200, 201);
      height: 35px;
      font-size: 0.875rem;
      resize: none;
      width: 250px;
      padding: 1rem 1.125rem;
    }

    .sign-img {
      width: 150px;
      padding: 0.5rem 1rem;
      background: rgb(250, 250, 250);
      border-width: 1px;
      border-radius: 2px;
      border-color: rgb(238, 238, 238);
      border-style: solid;
      margin-left: 12px;
    }

    .upload-btn {
      padding: 0.25rem 1.75rem;
    }

    .validation-error {
      color: red;
    }
  }

  @media screen and (max-width: 767px) {
    .sitterOtherInfo {
      column-count: 1 !important;
    }
  }
`;

export const Availability = styled.div`
  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.4rem;
  }

  .availabilityText,
  .availabilityText ~ p {
    font-family: 'Didact Gothic' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.1rem;
    color: #404040;
    margin-bottom: 1.5rem !important;
    opacity: 0.6;
  }
  .availabilityText ~ h3 {
    font-family: 'Didact Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 1.3rem;
    color: #000000;
  }
  .unavailabilities {
    border: 1px solid #f2f2f2;
    border-radius: 0.6rem;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span:first-child {
        background-color: #f4f4f4;
        padding: 0.6rem 1rem;
        border-radius: 0.4rem;
      }
    }

    p {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1.2rem;
      color: #000000;
      opacity: 0.6;
    }
  }

  @media screen and (max-width: 768px) {
    .unavailabilities {
      > div > span:nth-child(2) {
        display: flex;
      }
    }
  }
`;

export const SitterFamView = styled.div`
  .centeredLine {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #c3cbc2;
    border-radius: 50%;
    padding: 0.5rem;
    background-color: #f7f7f7;
  }

  .cell-label {
    white-space: pre;
    display: flex;
  }

  .child-image {
    object-fit: cover;
  }

  hr.centeredLine {
    height: 320px;
    width: 1px;
    padding: 0;
    background-color: #c3cbc2;
  }

  .famViewBack {
    margin-bottom: 1rem;
    a:first-child {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 1.1rem;

      svg {
        width: 1.2rem !important;
        height: 1.2rem !important;
      }
    }
    a {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 1.1rem;
      line-height: 1.4rem;

      span {
        padding: 0.6rem 0.7rem 0.7rem 0.7rem;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
        border-radius: 50%;
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
  }

  .sitterOtherInfo {
    > div {
      > p.mt-4 {
        font-family: 'Brandon Grotesque';
        font-style: normal;
        font-weight: 400;
        font-size: 0.6rej;
        line-height: 1.1rem;
        text-transform: uppercase;
        color: #000000;
      }

      > div {
        margin-top: 1rem;

        > div:first-child {
          width: 56px;
          height: 56px;
          border-radius: 8px;
          object-fit: cover;
          margin-right: 0.8rem;

          img {
            width: 100%;
          }
        }
      }

      table td,
      tr {
        font-family: 'Didact Gothic';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.2rem;
        padding: 0.2rem;

        td:nth-child(1) {
          opacity: 0.5;
          padding-right: 1rem;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    hr.centeredLine {
      width: 100%;
      height: 1px;
    }
    .centeredLine {
      transform: translate(-50%, -43%);
    }
  }
`;

export const SaveEdit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: #000000;
    margin: 0;
  }

  button {
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
    background-color: #c3cbc2;
    font-family: 'Didact Gothic';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
`;

export const StyeledEditBio = styled.div`
  > div {
    padding: 16px 18px;
    background: #f6f6f6;
    border-radius: 8px;

    p {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 1.3rem;
      opacity: 0.4;
      color: #000;
    }
  }
`;
