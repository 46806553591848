import axios from 'axios';
import { getToken } from 'helpers/utils/auth-provider';
import toast from 'react-hot-toast';

const onResponse = (response) => response;
const onResponseError = (error) => {
  if (error.response?.status === 401) {
    // handle unauthorized
    toast.error('Session expired. Please log in again.');
    setTimeout(() => {
      window.location.replace('/login');
    }, 1000);
  }
  return Promise.reject(error);
};

function onRequest(config) {
  const token = getToken();
  config.headers['Authorization'] = token || '';
  return config;
}

// create client instance
const client = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BACKEND_API
});
client.interceptors.request.use(onRequest);
client.interceptors.response.use(onResponse, onResponseError);

const AUTH_TOKEN = getToken();
client.defaults.headers.common.Authorization = AUTH_TOKEN;

export default client;
