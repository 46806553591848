import { Form, Button } from 'react-bootstrap';
import { useState } from 'react';
import React from 'react';
import styled from 'styled-components';
import { validateNumericKey } from 'helpers/utils/misc';

const HomeAddressInfo = styled.h6`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  color: #9c9c9c;
`;

export default function SecondStep({ next, updateFormData, back, formData }) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      updateFormData({
        phone: e.target.phone.value,
        address: e.target.address.value,
        neighborhood: e.target.neighborhood.value
      });
      next();
    }
    setValidated(true);
  };

  return (
    <>
      <h3>Complete your Anytime Sitter profile (2/4)</h3>
      <Form className="registration-form" noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group className="mb-3 styled_input" controlId="phone">
          <Form.Label>Phone Number</Form.Label>
          <Form.Control
            format="(###) ###-####"
            type="tel"
            placeholder="Enter phone number"
            required
            defaultValue={formData.phone}
            onKeyPress={validateNumericKey}
          />
          <Form.Control.Feedback type="invalid">Phone number is required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 styled_input" controlId="address">
          <Form.Label>Home Address</Form.Label>
          {/* Grayed out text */}
          <HomeAddressInfo>
            Please add your entire home address, including apartment number, city, state and zip
            code.
          </HomeAddressInfo>
          <Form.Control
            type="text"
            placeholder="Enter home address"
            required
            defaultValue={formData.address}
          />
          <Form.Control.Feedback type="invalid">Home Address is required.</Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-3 styled_input" controlId="neighborhood">
          <Form.Label>Neighborhood (this will be shared with MDE families)</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter neighborhood"
            required
            defaultValue={formData.neighborhood}
          />
          <Form.Control.Feedback type="invalid">Neighborhood is required.</Form.Control.Feedback>
        </Form.Group>
        <div className="float-end d-flex">
          <Button variant="alt" onClick={() => back()} className="d-inline me-3 scale-animation">
            Back
          </Button>
          <Button variant="primary" type="submit" className="d-inline scale-animation">
            Next
          </Button>
        </div>
      </Form>
    </>
  );
}
