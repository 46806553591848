import { Row, Form, Button, Spinner, Container } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as BackButton } from 'assets/icons/chevron.svg';
import { LoginForm } from './Auth.styled';
import Card from '../../components/UI/Card.jsx';
import { auth } from 'helpers/http/index';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import UnauthorizedHeader from 'components/menu/UnauthorizedHeader';

const BackButtonWrapper = styled.div`
  margin: auto;
  opacity: 0.7;
  margin-bottom: 1rem;
  svg {
    margin-left: -4px;
    transform: rotate(-90deg);
  }
`;

export default function ResetPassword() {
  const [isLoading, setIsLoading] = React.useState(false);
  const { verification } = useParams('verification');
  const navigate = useNavigate();

  const schema = yup
    .object({
      verification: yup.string().default(verification),
      otp: yup.string().required('OTP is required.'),
      password: yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&_])[A-Za-z\d@$#!%*?&_]{8,}$/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        )
        .required('Password is required.'),
      confirm: yup
        .string()
        .required('Please confirm your password.')
        .oneOf([yup.ref('password'), null], "Passwords don't match.")
    })
    .required();

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const promise = auth.resetPassword(data);
    toast.promise(promise, {
      loading: 'Resetting password...',
      success: () => {
        navigate('/login');
        setIsLoading(false);
        return 'Password reset successfully.';
      },
      error: (err) => {
        setIsLoading(false);
        return err?.response?.data?.message || 'Error resetting password.';
      }
    });
  };

  const { errors } = formState;

  return (
    <>
      <Helmet>
        <title>Sitter reset password</title>
      </Helmet>
      <LoginForm className="d-flex flex-column">
        <UnauthorizedHeader dark />

        <Container>
          <Card className="login_Card">
            <div className="login_wrapper">
              <Link to="/forgot-password">
                <BackButtonWrapper>
                  <BackButton />
                  <span className="ms-2">Back</span>
                </BackButtonWrapper>
              </Link>
              <h3 className="lead text-uppercase mb-4">Reset Password</h3>

              <p>Please enter the OTP code sent to your email and change your password below.</p>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Form.Group className="mb-3 styled_input" controlId="otp">
                    <Form.Label className="mb-1">OTP</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter OTP"
                      aria-describedby="basic-addon1"
                      maxLength={16}
                      {...register('otp')}
                    />
                    <small className="error">{errors.otp?.message}</small>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="mb-3 styled_input" controlId="newpass">
                    <Form.Label className="mb-1">New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter new password"
                      aria-describedby="basic-addon1"
                      maxLength={16}
                      {...register('password')}
                    />
                    <small className="error">{errors.password?.message}</small>
                  </Form.Group>
                </Row>

                <Row>
                  <Form.Group className="mb-3 styled_input" controlId="confirm">
                    <Form.Label className="mb-1">Confirm New Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm password"
                      aria-describedby="basic-addon1"
                      maxLength={16}
                      {...register('confirm')}
                    />
                    <small className="error">{errors.confirm?.message}</small>
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Button
                    className="primary-button ms-auto me-3 mt-3 w-fit-content"
                    type="submit"
                    disabled={isLoading}>
                    Reset
                    <Spinner animation="border" size="sm ms-2" hidden={!isLoading} />
                  </Button>
                </Row>
              </Form>
            </div>
          </Card>
        </Container>
      </LoginForm>
    </>
  );
}
