import { Button, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
  background-image: url(https://mde-assets.s3.amazonaws.com/web-assets/locked_dashboard.jpeg);
  border-radius: 8px;
  height: 80vh;
  margin-top: 1.5rem;
  text-align: center;
  display: grid;
  align-content: center;
  padding: 20rem;

  h3 {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 300;
    font-size: 2.75rem;
    margin-bottom: 1.125rem;
  }
  h4 {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 180%;
  }
  h5 {
    margin: 1.125rem 0rem;
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 400;
    font-size: 1.625rem;
  }

  @media (max-width: 1200px) {
    padding: 12rem;
  }
  @media (max-width: 769px) {
    padding: 2rem;
  }
  @media (max-width: 450px) {
    h3 {
      font-size: 1.25rem;
    }
    h4 {
      font-size: 0.875rem;
    }
    h5 {
      font-size: 1rem;
    }
  }
`;

const ReSignPolicy = () => {
  return (
    <Container>
      <Wrapper>
        <div>
          <h3>UPDATED ATS RATES + POLICY</h3>
          <h4>
            We have recently updated our ATS rates and policy. You must review and sign our updated
            ATS Policy in order to gain access to your Sitter Portal.
          </h4>
          <div className="text-center">
            <Link to="/ats-policy">
              <Button className="primary-button">Review and Sign Updated ATS Policy</Button>
            </Link>
          </div>
        </div>
      </Wrapper>
    </Container>
  );
};

export default ReSignPolicy;
