import styled from 'styled-components';

export const LoginForm = styled.div`
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-control:valid {
    border-color: transparent !important;
  }
  input,
  textarea {
    border: 2px solid transparent !important;
    background-image: none !important;
  }

  .login_wrapper {
    padding: 3rem;
  }

  .login_wrapper h3 {
    font-family: 'Brandon Grotesque';
    font-weight: 300;
  }

  .lead {
    font-size: 1.5rem;
  }

  .login_Card {
    max-width: 518px;
    width: 100%;
    margin: auto;
    padding: 0px;
  }

  .loginAs_family {
    max-width: 518px;
    width: 100%;
    margin: auto;
    padding: 0px;
    background-color: #fff;
    border-bottom-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    padding: 1.4rem;
    text-align: center;
    border-top: 1px solid #dddddd;
  }

  .loginAs_family a {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    padding: 0.2rem 0;
    border-bottom: 1px solid #c3cbc2;
  }

  .reset_pass a {
    padding: 0.2rem 0;
    border-bottom: 1px solid #c3cbc2;
    font-family: 'Montserrat';
    font-weight: 500;
  }

  .login_submit_button {
    font-size: 1.125rem;
  }

  @media screen and (max-width: 767px) {
    .login_wrapper {
      padding: 1.5rem;
    }
  }
`;
