/* eslint-disable no-unused-vars */
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { Availability } from '../profile/SitterProfile.styled';
import { profile } from 'helpers/http';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';
import ScheduleSelector from 'components/ScheduleSelector';
import EditVacation from './EditVacation';
import VacationView from 'modules/profile/VacationView';
import ConfirmView from 'components/UI/ConfirmView';
import toast from 'react-hot-toast';

export const EditAvailability = ({ updateFormData, updateDeletedVacation, vacationErrorList }) => {
  const [avail, setAvail] = React.useState({});
  const { data, isLoading } = useQuery('sitterAvailability', profile.getAvailability, {
    refetchOnWindowFocus: false
  });
  const {
    data: vacationData,
    isLoading: vacationLoading,
    refetch
  } = useQuery('vacationList', profile.listVacations);
  const [schedule, setSchedule] = React.useState([]);

  const [vacationModal, setVacationModal] = React.useState(false);
  const [confirmVacationDelete, setConfirmVacationDelete] = React.useState(false);
  const [vacation, setVacation] = React.useState();

  const handleAvailability = (newValue) => {
    setSchedule(newValue);
    setAvail({
      ...newValue
    });
    updateFormData({
      availability: newValue
    });
  };

  React.useEffect(() => {
    if (data) {
      let tempData = [];
      data?.data?.monday?.map((item) => {
        tempData.push(Date(item));
      });
      data?.data?.tuesday?.map((item) => {
        tempData.push(Date(item));
      });
      data?.data?.wednesday?.map((item) => {
        tempData.push(Date(item));
      });
      data?.data?.thursday?.map((item) => {
        tempData.push(Date(item));
      });
      data?.data?.friday?.map((item) => {
        tempData.push(Date(item));
      });
      data?.data?.saturday?.map((item) => {
        tempData.push(Date(item));
      });
      data?.data?.sunday?.map((item) => {
        tempData.push(Date(item));
      });
      setSchedule(tempData);
      setAvail(data?.data || {});
    }
  }, [data]);

  // vacation functionality functions starts

  const handleAddVacation = () => {
    setVacationModal(true);
  };

  const closeVacationModal = () => {
    setVacationModal(false);
    setVacation();
  };

  const handleDeleteVacation = (vacationId) => {
    setConfirmVacationDelete(vacationId);
  };

  const deleteVacation = () => {
    //delete vacation
    const vacationId = confirmVacationDelete;
    if (vacationId) {
      setConfirmVacationDelete();
      const promise = profile.deleteVacation(vacationId);
      toast.promise(promise, {
        loading: 'Deleting...',
        success: () => {
          refetch();
          return 'Vacation deleted succesfully!';
        },
        error: (err) => {
          err.response.data?.message || 'Problem in saving vacation!';
        }
      });
    }
  };

  const onCancelDelete = () => {
    setConfirmVacationDelete();
  };

  const handleEditVacation = (vacationInfo) => {
    setVacation(vacationInfo);
    setVacationModal(true);
  };

  // vacation functionality functions ends

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner animation="border" size="sm" />
      </div>
    );
  }

  const month = moment().format('MMMM').toLowerCase();
  const year = moment().format('YYYY').toLowerCase();

  return (
    <Availability className="availability">
      <div>
        <h3>availability</h3>
      </div>
      <p className="availabilityText">
        Please share your regular/recurring weekly availability below. This will remain the same
        week over week until you change it. Changes to your regular availability can be updated at
        any time*. Clicking on a time box = green box = you are available.
      </p>
      <ScheduleSelector
        data={avail}
        numDays={7}
        minTime={0}
        maxTime={24}
        startDate={new Date(moment(`${year}-${month}-5`).format())}
        hourlyChunks={1}
        dateFormat="ddd"
        onChange={handleAvailability}
        timeFormat="hA"
        blockedList={vacationData?.data}
      />
      <VacationView
        vacationList={vacationData?.data}
        isLoading={vacationLoading}
        edit={true}
        onAddVacation={handleAddVacation}
        onDelete={handleDeleteVacation}
        onEdit={handleEditVacation}
      />
      <EditVacation
        isOpen={vacationModal}
        hideModal={closeVacationModal}
        refetch={refetch}
        vacation={vacation}
      />

      <ConfirmView
        show={!!confirmVacationDelete}
        onClose={onCancelDelete}
        onConfirm={deleteVacation}
        title="Delete Vacation"
        description="Are you sure you want to delete vacation?"
      />
    </Availability>
  );
};
