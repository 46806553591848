import styled from 'styled-components';

export const Wrapper = styled.div`
  .navbar {
    background: transparent;
    position: absolute;
    padding: 0rem 6.25rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100vw;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    top: 0;
    display: contents;
  }

  .navbar .logo {
    /* margin-right: auto; */
    float: right;
  }

  .navbar .container {
    margin-top: 0.5rem;
    margin-bottom: 0rem;
  }

  .navbar .navbar-collapse {
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right;
  }

  .navbar .navbar-collapsing {
    margin-left: -12px !important;
    margin-right: -12px !important;
    background-color: #fcf7f2;
  }

  .navbar .show {
    margin-left: -12px !important;
    margin-right: -12px !important;
    background-color: #fcf7f2;
    color: black !important;
  }

  .navbar .links {
    margin-left: auto;
    text-align: right;
  }

  .navbar .links * {
    margin: 1rem;
  }

  .navbar .links button {
    margin: 0rem;
    margin-left: 0.5rem;
  }

  .navbar .dark {
    color: black;
  }

  .navbar-toggler {
    border-color: rgba(255, 255, 255, 0.2) !important;
  }

  .offcanvas-body .primary-button,
  .offcanvas-body .alt-button {
    max-width: 150px;
    text-align: center;
  }

  .offcanvas-body .mobile-button-nav {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 1rem;
  }

  .offcanvas-body .mobile-button-nav .alt-button {
    -webkit-box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  }

  .offcanvas-body .mobile-button-nav * {
    margin: 1rem;
  }

  .offcanvas-body * {
    margin-bottom: 0.5rem;
  }

  .navbar-nav {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right;
    margin-left: auto !important;
  }

  .navbar-nav a {
    -webkit-transition: 0.25s;
    transition: 0.25s;
  }

  .navbar-nav a:hover {
    color: #c3cbc2;
  }

  .navbar-nav .alt-button,
  .navbar-nav .primary-button {
    -webkit-transition: opacity 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out;
    margin-left: 0.375rem;
    margin-right: 0.375rem;
    opacity: 1;
  }

  .navbar-nav .alt-button:hover,
  .navbar-nav .primary-button:hover {
    color: black;
  }

  .dropdown {
    margin: 0rem !important;
  }

  .dropdown button {
    background: white !important;
    border: none;
    color: #404040;
    border-radius: 90px;
  }

  .dropdown button::after {
    display: none;
  }

  .show > .btn-primary.dropdown-toggle {
    border-color: transparent;
  }

  .navbar .show {
    margin: 0rem !important;
  }

  .dropdown-profile {
    color: #404040;
    margin: 0px !important;
    &:hover {
      .user-image {
        animation: shake 0.5s;
      }
    }
  }

  .dropdown-profile svg {
    margin: 0rem !important;
    margin-right: 0.5rem !important;
    transition: all 0.2s ease-in-out;
    padding-bottom: 0.1875rem;
  }

  .navbar-nav .dropdown-menu {
    margin-top: 0.5rem !important;
    background: white !important;
    border-radius: 12px;
    border: none;
  }

  .transform-chevron {
    transform: rotate(180deg);
  }

  .dropdown-item {
    margin: 0rem !important;

    &:hover,
    &:focus,
    &:active {
      background: #fcf7f2;
    }
  }

  .dropdown-toggle {
    padding: 4px !important;
  }

  @media (max-width: 768px) {
    .navbar {
      padding: 0rem;
    }
    .navbar-collapse {
      background-color: #fcf7f2;
      margin-left: -12px !important;
      margin-right: -12px !important;
    }
  }
`;
