import React from 'react';
import { CardUI } from 'components/UI/UI.styled';
import Card from 'components/UI/Card';
import { StyledRequestSession } from './SitterDash.Styled';
import { ReactComponent as Calendar } from 'assets/Images/Cal.svg';
import { ReactComponent as Clock } from 'assets/Images/clock.svg';
import xIcon from 'assets/Images/x.svg';
import default_family_icon from '../../assets/icons/default-family.svg';
import trueIcon from 'assets/Images/ok.svg';
import ButtonUI from 'components/UI/Button';
import { dashboard } from 'helpers/http';
import { Image, Spinner } from 'react-bootstrap';
import moment from 'moment-timezone';
import NoDataUI from 'components/NoDataUI';
import toast from 'react-hot-toast';
import SessionDetails from './SessionDetails';

// eslint-disable-next-line no-unused-vars
function RequestSitter({ isLoading, data, refetch, refetchUpcoming }) {
  const [modalData, setModalData] = React.useState({});
  const [show, setShow] = React.useState(false);

  const hideModal = () => {
    setShow(false);
  };

  const handleShow = (id) => {
    const promise = dashboard.getSession(id);

    toast.promise(promise, {
      loading: 'Loading Session Details',
      success: (res) => {
        setModalData(res);
        setShow(true);
        return 'Session Details Loaded';
      },
      error: (err) => {
        console.log(err);
      }
    });
  };

  if (isLoading) {
    return (
      <StyledRequestSession>
        <CardUI className="p-lg-4 text-center">
          <Spinner animation="border" size="sm" />
        </CardUI>
      </StyledRequestSession>
    );
  }

  if (data?.data?.records?.length === 0) {
    return (
      <StyledRequestSession>
        <CardUI className="p-lg-4 ">
          <h4>pending requests</h4>
          {/* <h6 className="gray text-center m-3">You have no pending requests</h6> */}
          <NoDataUI boxCount={true} notFoundText="You have no pending requests" />
        </CardUI>
      </StyledRequestSession>
    );
  }

  const handleSession = (status, id) => {
    const promise = dashboard.editSession({ status: status, id: id });
    toast.promise(promise, {
      loading: 'Updating session status...',
      success: () => {
        refetch();
        refetchUpcoming();
        if (status === 'denied') {
          return 'Session request denied';
        }
        return 'Session Accepted';
      },
      error: (err) => err?.response?.data?.message || 'There was an error accepting the session'
    });
  };

  return (
    <StyledRequestSession>
      <SessionDetails show={show} handleClose={hideModal} data={modalData} />

      <CardUI className="p-lg-4 card-ui">
        <h4>Requests</h4>
        <p className="opacity-07">
          Note: All requested sessions below will disappear as soon as you or another sitter accepts
          it.
        </p>
        {data?.data?.records?.map((session, index) => (
          <Card className="SingleSession" key={index} onClick={() => handleShow(session.id)}>
            <Image
              src={session?.familyPhoto || default_family_icon}
              alt=""
              height={71}
              width={71}
              className="rounded"
            />
            <div className="famInfo">
              <p>{`${session?.firstName} ${session?.lastName}`}</p>
              <div>
                <span>
                  <Calendar className="me-2" />
                  <p className="date">
                    {moment(session?.sessionStart).tz('America/New_York').format('ll')}
                  </p>
                </span>
                <span>
                  <Clock className="me-2" />
                  <p className="date">
                    {moment(session?.sessionStart).tz('America/New_York').format('LT') +
                      ' - ' +
                      moment(session?.sessionEnd).tz('America/New_York').format('LT')}
                  </p>
                </span>
              </div>
            </div>
            <div className="requestBtns">
              <ButtonUI
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  handleSession('scheduled', session?.id);
                }}>
                <img src={trueIcon} alt="" />
              </ButtonUI>
              <ButtonUI
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSession('denied', session?.id);
                }}>
                <img src={xIcon} alt="" />
              </ButtonUI>
            </div>
          </Card>
        ))}
      </CardUI>
    </StyledRequestSession>
  );
}

export default RequestSitter;
