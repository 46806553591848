import React from 'react';
import { CardUI } from 'components/UI/UI.styled';
import { Form, Spinner } from 'react-bootstrap';
import { profile } from 'helpers/http';
import { useQuery } from 'react-query';

export const EditEmergencyContact = ({ updateFormData, formData }) => {
  const { data, isLoading } = useQuery('emergencyContact', profile.getEmergencyContact);

  React.useEffect(() => {
    if (data) {
      updateFormData({
        emergencyContact: {
          name: data?.data?.name,
          phone: data?.data?.phone,
          email: data?.data?.email,
          relation: data?.data?.relation
        }
      });
    }
  }, [data?.data]);

  if (isLoading) {
    return (
      <CardUI className="text-center">
        <Spinner animation="border" size="sm" />
      </CardUI>
    );
  }

  const handleChange = (e) => {
    if (e.target.name === 'phone' && isNaN(e.target.value)) {
      return;
    }
    updateFormData({
      emergencyContact: { ...formData.emergencyContact, [e.target.name]: e.target.value }
    });
  };

  return (
    <CardUI>
      <h3>EMERGENCY CONTACT INFORMATION</h3>
      <Form.Group className="mb-3 styled_input" controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          defaultValue={formData?.emergencyContact?.name}
          name="name"
          onChange={handleChange}
          type="text"
          placeholder="Enter Name"
          required
        />
        <Form.Control.Feedback type="invalid">Name is required</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3 styled_input" controlId="phone">
        <Form.Label>Phone Number</Form.Label>
        <Form.Control
          value={formData?.emergencyContact?.phone}
          name="phone"
          onChange={handleChange}
          type="text"
          placeholder="Enter Phone Number"
          required
        />
        <Form.Control.Feedback type="invalid">Phone number is required</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3 styled_input" controlId="email">
        <Form.Label>Email Address</Form.Label>
        <Form.Control
          defaultValue={formData?.emergencyContact?.email}
          name="email"
          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
          title="Please enter a valid email address"
          onChange={handleChange}
          type="email"
          placeholder="Enter Email Address"
          required
        />
        <Form.Control.Feedback type="invalid">Email address is required</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3 styled_input" controlId="relation">
        <Form.Label>Relation</Form.Label>
        <Form.Control
          defaultValue={formData?.emergencyContact?.relation}
          name="relation"
          onChange={handleChange}
          type="text"
          placeholder="Enter Relation"
          required
        />
        <Form.Control.Feedback type="invalid">Relation is required</Form.Control.Feedback>
      </Form.Group>
    </CardUI>
  );
};
