import React from 'react';
import { CardUI } from 'components/UI/UI.styled';
import { Spinner, InputGroup } from 'react-bootstrap';
import { profile } from 'helpers/http';
import { useQuery } from 'react-query';
// import CreatableSelect from 'react-select/creatable';
import { ReactSelectWrapper } from './EditProfileComponents';
import MultiSelect from 'components/CreatableSelect';

export const EditAllergies = ({ updateFormData, formData }) => {
  const { data, isLoading } = useQuery('sitterProfile', profile.getProfile, {
    refetchOnWindowFocus: false
  });
  const [localAllergies, setLocalAllergies] = React.useState([]);

  React.useEffect(() => {
    if (data?.data?.allergies) {
      setLocalAllergies(
        data?.data?.allergies.map((allergy) => {
          return { label: allergy, value: allergy, __isNew__: true };
        })
      );
      updateFormData({
        ...formData,
        allergies: data?.data?.allergies?.map((item) => {
          return item;
        })
      });
    }
  }, [data]);

  const handleAllergies = (newValue) => {
    setLocalAllergies(
      newValue.map((item) => {
        return item;
      })
    );
    updateFormData({
      ...formData,
      allergies: newValue.map((item) => {
        return item.value;
      })
    });
  };

  if (isLoading) {
    return (
      <CardUI className="text-center">
        <Spinner animation="border" size="sm" />
      </CardUI>
    );
  }

  return (
    <CardUI>
      <h3>Allergies</h3>
      <InputGroup className="mb-3 d-grid">
        <ReactSelectWrapper>
          <MultiSelect value={localAllergies} onChange={handleAllergies} />
        </ReactSelectWrapper>
      </InputGroup>
    </CardUI>
  );
};
