import FirstStep from './steps/FirstStep';
import SecondStep from './steps/SecondStep';
import ThirdStep from './steps/ThirdStep';
import FourthStep from './steps/FourthStep';

const stepper = {
  1: {
    component: FirstStep,
    next: '2',
    back: null
  },
  2: {
    component: SecondStep,
    next: '3',
    back: '1'
  },
  3: {
    component: ThirdStep,
    next: '4',
    back: '2'
  },
  4: {
    component: FourthStep,
    next: null,
    back: '3'
  }
};

export const defaultStep = '1';

export default stepper;
