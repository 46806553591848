import React from 'react';
import { Button, Container, Spinner, Table, Image } from 'react-bootstrap';
import { BiArrowBack } from 'react-icons/bi';
import Card from 'components/UI/Card';
import { StyledPastSessions } from './SitterDash.Styled';
import famimg from 'assets/icons/default-family.svg';
import PastSessionDetailModal from './PastSessionDetailModal';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { dashboard } from 'helpers/http';
import moment from 'moment-timezone';
import NoDataUI from 'components/NoDataUI';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { toast } from 'react-hot-toast';
import SessionDetails from './SessionDetails';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

function PastSession() {
  const navigate = useNavigate();
  const [sessionDetailsModal, setSessionDetailsModal] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const { data, isLoading } = useQuery(
    'past',
    () =>
      dashboard.sessions({
        oFilterBy: {
          status: 'past'
        }
      }),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 10000
    }
  );

  const showModal = (session) => {
    setIsOpen(session);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const hideSessionDetails = () => {
    setSessionDetailsModal(false);
    navigate('/dashboard');
  };

  const showSessionDetails = (id) => {
    const promise = dashboard.getSession(id);

    toast.promise(promise, {
      loading: 'Loading Session Details',
      success: (res) => {
        setSessionDetailsModal(res);
        return 'Session Details Loaded';
      },
      error: () => {
        return 'Something went wrong! try again later.';
      }
    });
  };

  if (isLoading) {
    return (
      <>
        <Helmet>
          <title>Sitter past session</title>
        </Helmet>
        <StyledPastSessions>
          <Container>
            <Link to="/dashboard" className="my-4 d-inline-block">
              <BiArrowBack /> Back
            </Link>
            <h2>Past sessions</h2>
            <Card className="p-2">
              <Table borderless responsive className="PastSessionData">
                <thead>
                  <tr>
                    <th>Family</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Duration</th>
                    <th>Expenses</th>
                    <th>Details</th>
                  </tr>
                </thead>
              </Table>
              <div className="text-center">
                <Spinner animation="border" size="sm" className="my-4" />
              </div>
            </Card>
          </Container>
        </StyledPastSessions>
      </>
    );
  }

  return (
    <StyledPastSessions>
      <SessionDetails
        show={Boolean(sessionDetailsModal)}
        handleClose={hideSessionDetails}
        data={sessionDetailsModal}
      />
      <PastSessionDetailModal expenses={isOpen} isOpen={Boolean(isOpen)} hideModal={hideModal} />
      <Container>
        <Link to="/dashboard" className="my-4 d-inline-block">
          <BiArrowBack /> Back
        </Link>
        <h2>Past sessions</h2>
        <Card className="p-2 ">
          <Table borderless responsive className="PastSessionData">
            <thead>
              <tr>
                <th>Family</th>
                <th>Date</th>
                <th>Time</th>
                <th>Duration</th>
                <th>Expenses</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              {data?.data?.records?.map((session, index) => (
                <tr valign="middle" key={index}>
                  <td>
                    <Image
                      src={session?.familyPhoto || famimg}
                      alt="family photo"
                      className="family-image"
                      roundedCircle
                    />
                    <span>{`${session?.firstName} ${session?.lastName}`}</span>
                  </td>
                  <td>{moment(session?.sessionStart).tz('America/New_York').format('ll')}</td>
                  <td>
                    {moment(session?.sessionStart).tz('America/New_York').format('LT') +
                      ` - ` +
                      moment(session?.sessionEnd).tz('America/New_York').format('LT')}
                  </td>
                  <td>
                    {moment
                      .utc(
                        moment
                          .duration(
                            moment(session?.sessionEnd)
                              .tz('America/New_York')
                              ?.diff(moment(session?.sessionStart).tz('America/New_York'))
                          )
                          .as('milliseconds')
                      )
                      .format('HH:mm')}
                  </td>
                  <td>
                    <div
                      role="button"
                      className="d-flex align-items-center scale-animation"
                      onClick={() => showModal(session)}>
                      <span>
                        ${session.eveningMealExpenseAmount + session.transportExpenseAmount}
                      </span>
                      <ChevronRight />
                    </div>
                  </td>
                  <td>
                    <Button
                      variant="primary"
                      className="scale-animation"
                      onClick={() => showSessionDetails(session.id)}>
                      View Details
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {data?.data?.records?.length === 0 && !isLoading && (
            <NoDataUI notFoundText="You have no past sessions" boxCount={true} />
          )}
        </Card>
      </Container>
    </StyledPastSessions>
  );
}

export default PastSession;
