/* eslint-disable no-unused-vars */
import React from 'react';
import Selector from 'react-schedule-selector';
import moment from 'moment-timezone';
import styled from 'styled-components';

const DateWrapper = styled.div`
  height: 300px;
  background-color: #f7f7f7;
  border-radius: 1rem;
  overflow: hidden;
  .header {
    padding: 0.625rem 0;
    border-bottom: 1px solid #cbc8c9;
    border-radius: 0;
    margin-bottom: 0.75rem;
  }
  .date-range-header {
    color: #404040;
    font-weight: 200;
  }
  .scheduler-viewer {
    padding-left: 1rem;
    padding-right: 0.72rem;
    overflow: auto;
    height: calc(100% - 90px);
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  p {
    /* height: 12px; */
    margin: 0px;
    display: flex;
    align-items: center;
  }
  h2 {
    font-size: 0.8rem;
    margin-bottom: 0rem;
    display: flex;
    align-items: center;
  }
  .selected {
    background: #c3cbc2;
    border: 1px solid #cbc8c9;
    border-radius: 0.3rem;
  }
  .unselected {
    background: #e5e5e5;
    border: 1px solid #cbc8c9;
    border-radius: 0.3rem;
  }
  .blocked {
    background: #c3cbc2 !important;
    opacity: 0.6 !important;
    border-radius: 0.3rem;
  }
  .rgdp__grid-cell {
    ${({ disabled }) => (disabled ? 'pointer-events: none' : '')};
  }
  .scheduler-header-view {
    height: 1rem;
    display: flex;
    justify-content: space-evenly;
    padding-left: 1rem;
    padding-right: 0.72rem;
    gap: 4px;
    // & > div.align-items-center span {
    //   width: 100%;
    //   text-align: center;
    //   margin-left: 7px;
    //   margin-top: 11px;
    //   position: relative;
    //   z-index: 11;
    //   padding-right: 4px;
    // }
    .empty-header {
      min-width: ${(props) => props.emptyHeaderWidth || 79}px;
    }
    > div.align-items-center {
      > span {
        width: 100%;
        text-align: center;
      }
    }
  }
  div {
    background: #f7f7f7;
    border-radius: 16px;
    div {
      div {
        height: 28px;
      }
    }
  }
  .scheduler-viewer > div > div > div > div:first-child {
    height: 0rem;
  }
`;

const ColumnHeader = styled.div`
  height: 0.5rem !important;
  span {
    font-weight: 300;
    font-size: 11px;
    line-height: 14px;
  }
`;

const RowLabel = styled.div`
  span {
    font-size: 11px;
    line-height: 14px;
    color: #4b4b4b;
    font-weight: 300;
  }
`;

const generateSchedule = (data) => {
  const list = [];
  if (data) {
    // get the current week
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('week');
    const weekEnd = currentDate.clone().endOf('week');
    for (let i = 0; i < 7; i++) {
      if (i !== 0) {
        weekStart.add(1, 'days');
      }
      const date = weekStart.format('MM-DD-YYYY');
      const day = weekStart.format('dddd').toLowerCase();
      const timeList = data[day] || [];
      timeList.forEach((time) => {
        const singleTime = time.split('-')[0];
        const endTime = time.split('-')[1];
        let endDate = date;
        if (endTime === '24:00') {
          endDate = moment(endDate, 'MM-DD-YYYY').add(1, 'day').format('MM-DD-YYYY');
        }
        const endDateTime = moment(
          `${date} ${endTime === '24:00' ? '23:00' : endTime}`,
          'MM-DD-YYYY HH:mm'
        );
        let dateTime = moment(`${date} ${singleTime}`, 'MM-DD-YYYY HH:mm');
        while (dateTime.isBefore(endDateTime)) {
          list.push(new Date(dateTime.format()));
          dateTime.add(1, 'hour');
        }
        if (endTime === '24:00') {
          list.push(new Date(endDateTime.format()));
        }
      });
    }
  }
  return list;
};

function ScheduleSelector({ data, onChange, disabled, blockedList = [] }) {
  const dateCellRef = React.useRef();
  const timeLabelRef = React.useRef();
  const isBlocked = (datetime) => {
    const momentTime = moment(datetime).tz('America/New_York', true);
    const blockedDate = blockedList?.find((item) => {
      return momentTime.isBetween(
        moment(item.startDate).tz('America/New_York'),
        moment(item.endDate).tz('America/New_York'),
        undefined,
        '[]'
      );
    });
    if (blockedDate) {
      return true;
    }
    return false;
  };
  const handleScheduleChange = (newValue) => {
    const newData = {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: []
    };
    newValue.forEach((value) => {
      const dateObj = moment(value);
      const day = dateObj.format('dddd').toLowerCase();
      const hour = dateObj.format('HH');
      const dayLength = newData[day].length;
      const time = `${dateObj.format('HH')}:00`;
      let endTime = `${dateObj.add(1, 'hour').format('HH')}:00`;
      endTime = endTime === '00:00' ? '24:00' : endTime;
      if (dayLength > 0) {
        const hourRange = newData[day][dayLength - 1];
        if (hourRange.split('-')[1] === time) {
          newData[day][dayLength - 1] = `${hourRange.split('-')[0]}-${endTime}`;
        } else {
          newData[day].push(`${time}-${endTime}`);
        }
      } else {
        newData[day].push(`${time}-${endTime}`);
      }
      // const hasTime = newData[day].find((t) => t === time);
      // console.log(`${time}-${endTime}`);
      // if (!hasTime) {
      //   newData[day].push(`${time}-${endTime}`);
      // }
    });
    onChange && onChange(newData);
  };

  const schedule = generateSchedule(data);
  const currentDate = moment();
  const weekStart = currentDate.clone().startOf('week');
  const weekEnd = currentDate.clone().endOf('week');
  return (
    <DateWrapper disabled={disabled} emptyHeaderWidth={timeLabelRef.current?.offsetWidth}>
      <div className="header text-center">
        <span className="date-range-header">
          {weekStart.format('MMM D')} - {weekEnd.format('MMM D')} | {weekEnd.format('YYYY')}
        </span>
      </div>
      <div className="scheduler-header-view">
        <div className="empty-header"></div>
        {moment.weekdaysShort().map((day) => (
          <ColumnHeader
            key={day}
            className="d-flex flex-column align-items-center"
            style={{ width: dateCellRef.current?.offsetWidth || 36 }}>
            <span className={day === moment().format('ddd') && 'fw-bold'}>{day}</span>
          </ColumnHeader>
        ))}
      </div>
      <div className="scheduler-viewer">
        <div>
          <Selector
            selection={schedule}
            numDays={7}
            minTime={0}
            maxTime={24}
            startDate={moment().clone().startOf('week')}
            hourlyChunks={1}
            dateFormat="ddd"
            onChange={handleScheduleChange}
            rowGap="1rem"
            timeFormat="hh A"
            renderDateLabel={(date) => <div style={{ visibility: 'hidden', height: 0 }}></div>}
            renderTimeLabel={(time) => (
              <RowLabel className="">
                <span ref={(e) => (timeLabelRef.current = e)}>
                  {moment(time).format('hh A')}-{moment(time).add(59, 'minutes').format('hh:mm A')}
                </span>
              </RowLabel>
            )}
            renderDateCell={(datetime, selected, refSetter) => {
              const blocked = isBlocked(datetime);
              return (
                <div ref={refSetter} className="date-cell">
                  <div
                    ref={(e) => (dateCellRef.current = e)}
                    className={`${blocked ? 'blocked' : selected ? 'selected' : 'unselected'}`}
                  />
                </div>
              );
            }}
          />
        </div>
      </div>
    </DateWrapper>
  );
}

export default ScheduleSelector;
