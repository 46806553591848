/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as ClockIcon } from 'assets/Images/clock.svg';
import moment from 'moment';
import NoDataUI from 'components/NoDataUI';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TrashIcon } from 'assets/Images/trash.svg';
import { ReactComponent as EditIcon } from 'assets/Images/edit-2.svg';

const Wrapper = styled.div`
  .title {
    font-family: 'Brandon Grotesque';
    font-style: normal;
    font-weight: 330;
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    color: #000000;
  }
  .vacation-item {
    border: 1px solid #cbc8c9;
    border-radius: 11px;
    padding: 1rem;
    gap: 0.75rem;
    .vacation-details {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #404040;
      display: flex;
      align-items: center;
      gap: 0.25rem;
      svg {
        height: 18px;
        width: 18px;
      }
    }
    .vacation-note {
      font-family: 'Didact Gothic';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #404040;
    }
  }
  .addButtonText {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
`;

function VacationView({ vacationList = [], isLoading, edit, onAddVacation, onEdit, onDelete }) {
  //   const [vacationList, setVacationList] = useState([
  //     { startDate: '2022-09-17T10:00:00.000Z', endDate: '2022-09-17T15:09:00.000Z', note: '' },
  //     { startDate: '2022-09-17T10:00:00.000Z', endDate: '2022-09-17T15:09:00.000Z', note: '' }
  //   ]);

  return (
    <Wrapper className="mt-4">
      <div>
        <span className="title">UNAVAILABILITY/VACATION</span>
      </div>
      {!isLoading && vacationList?.length === 0 && (
        <NoDataUI notFoundText="No unavailability/vacation found" />
      )}
      {vacationList?.map((vacation, index) => (
        <div className="vacation-item my-2 d-flex" key={index}>
          <div className="d-flex flex-column flex-1 gap-2">
            <div className="vacation-note">{vacation.note}</div>
            <div className="vacation-details">
              <CalendarIcon />
              <span>
                From {moment(vacation.startDate).tz('America/New_York').format('MM/DD/YYYY')} To{' '}
                {moment(vacation.endDate).tz('America/New_York').format('MM/DD/YYYY')}
              </span>
            </div>
            <div className="vacation-details">
              <ClockIcon />
              <span>
                {moment(vacation.startDate).tz('America/New_York').format('LT')} to{' '}
                {moment(vacation.endDate).tz('America/New_York').format('LT')}
              </span>
            </div>
          </div>
          {edit && (
            <div className="action-view d-flex flex-column gap-3">
              <div>
                <EditIcon role="button" className="edit-icon" onClick={() => onEdit(vacation)} />
              </div>
              <div>
                <TrashIcon
                  role="button"
                  className="delete-icon"
                  onClick={() => onDelete(vacation.id)}
                />
              </div>
            </div>
          )}
        </div>
      ))}
      {edit && (
        <div
          role="button"
          className="addNewVacation d-flex align-items-center gap-2 mt-4"
          onClick={onAddVacation}>
          <PlusIcon />
          <span className="addButtonText">Add unavailability/vacation block</span>
        </div>
      )}
    </Wrapper>
  );
}

export default VacationView;
