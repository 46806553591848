import React from 'react';
import { Button, Modal, Spinner, CloseButton, Form, Row } from 'react-bootstrap';
import modalClose from 'assets/Images/modalClose.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { profile } from 'helpers/http/index';
import toast from 'react-hot-toast';

export default function ChangePassword() {
  const [showChangePass, setShowChangePass] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const handleShowChangePass = () => setShowChangePass(true);
  const handleCloseChangePass = () => setShowChangePass(false);

  const schema = yup
    .object({
      oldPassword: yup.string().required('Old password is required.'),
      newPassword: yup
        .string()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
        )
        .required('Password is required.'),
      newPasswordConfirm: yup
        .string()
        .required('Confirmation is required.')
        .oneOf([yup.ref('newPassword'), null], "Passwords don't match.")
    })
    .required();

  const { register, handleSubmit, formState, reset } = useForm({
    resolver: yupResolver(schema)
  });

  const { errors } = formState;

  const onSubmit = (data) => {
    const promise = profile.changePassword(data);
    setIsLoading(true);
    toast.promise(promise, {
      loading: 'Loading...',
      success: (res) => {
        setIsLoading(false);
        handleCloseChangePass();
        reset();
        return res?.data?.message || 'Password changed successfully.';
      },
      error: (err) => {
        setIsLoading(false);
        return err?.response?.data?.message || 'Something went wrong.';
      }
    });
  };
  return (
    <>
      <Button
        variant="white"
        className="less-padding mt-3 white-shadow-button scale-animation"
        onClick={handleShowChangePass}>
        Change Password
      </Button>
      <Modal
        show={showChangePass}
        onHide={handleCloseChangePass}
        centered
        className="completeProfileModal">
        <CloseButton
          onClick={handleCloseChangePass}
          className="close_btn"
          style={{ background: `url(${modalClose})` }}
        />
        <Modal.Body>
          <h3 className="mb-4">Change password</h3>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Form.Group className="mb-3 styled_input" controlId="old">
                <Form.Label className="mb-1">Old Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter old password"
                  aria-describedby="old-password"
                  maxLength={16}
                  {...register('oldPassword')}
                />
                <small className="error">{errors.oldPassword?.message}</small>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3 styled_input" controlId="new">
                <Form.Label className="mb-1">New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  aria-describedby="new-password"
                  maxLength={16}
                  {...register('newPassword')}
                />
                <small className="error">{errors.newPassword?.message}</small>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3 styled_input" controlId="confirm">
                <Form.Label className="mb-1">Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm password"
                  aria-describedby="confirm-password"
                  maxLength={16}
                  {...register('newPasswordConfirm')}
                />
                <small className="error">{errors.newPasswordConfirm?.message}</small>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3">
                <Button
                  type="submit"
                  variant="primary"
                  className="float-end mt-3 scale-animation"
                  disabled={isLoading}>
                  {isLoading ? <Spinner size="sm" animation="border" /> : 'Submit'}
                </Button>
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
