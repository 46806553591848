import { Container } from 'react-bootstrap';
import { ReactComponent as Logo } from 'assets/Images/logo.svg';
import { ReactComponent as LogoDark } from 'assets/Images/logo_dark.svg';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
`;

export default function UnauthorizedHeader({ dark }) {
  return (
    <Wrapper>
      <Container className="d-flex justify-content-center">
        <div className="logo">
          <a href="https://master.d1e1saq6y13anl.amplifyapp.com/" target="_mde">
            {dark ? <LogoDark /> : <Logo />}
          </a>
        </div>
      </Container>
    </Wrapper>
  );
}
